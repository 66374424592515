(function () {
  angular
    .module('partners-bo.components.directives')
    .directive('tableSort', [
      TableSortDirective,
    ])
  ;

  /**
   * @name TableSortDirective
   * @desc <table-sort></table-sort>
   */
  function TableSortDirective() {
    function TableSortController($state, $stateParams) {
      const vm = this;
      const orderBy = $stateParams.orderBy ? $stateParams.orderBy.split(':') : null;
      vm.currentAttribute = null;
      vm.sort = null;

      if (orderBy) {
        const sort = (2 === orderBy.length) ? orderBy[1] : 'asc';

        vm.currentAttribute = vm.attribute === orderBy[0] ? orderBy[0] : null;
        vm.sort = vm.attribute === vm.currentAttribute ? sort : null;
      }

      vm.onClick = function onClick() {
        let orderByParam = '';

        if (vm.currentAttribute !== vm.attribute) {
          orderByParam = `${vm.attribute}:asc`;
        } else if (vm.currentAttribute === vm.attribute && 'asc' === vm.sort) {
          orderByParam = `${vm.attribute}:desc`;
        }

        $state.go($state.current.name, { orderBy: orderByParam }, { reload: true });
      };
    }

    return {
      scope: {
        attribute: '@',
        label: '@',
        iconClass: '@?',
      },
      restrict: 'E',
      controller: [
        '$state',
        '$stateParams',
        TableSortController,
      ],
      controllerAs: 'vm',
      templateUrl: '/components/directives/table-sort.html',
      bindToController: true,
    };
  }
}());

(function () {
  /**
  * Directives Module
  *
  * Description
  */
  angular
    .module('partners-bo.components.directives', [
    ]);
}());

(function () {
  angular
    .module('partners-bo.invoices', [
    ])
    .config([
      '$stateProvider',
      function ($stateProvider) {
        $stateProvider
          .state('invoices', {
            parent: 'root',
            url: '/invoices?{org:string}&{pdf:string}',
            abstract: true,
            templateUrl: 'invoices/invoices.html',
            controller: 'InvoicesController',
            controllerAs: 'invoicesVm',
            params: {
              pdf: null,
            },
            resolve: {
              organization: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  if (!$stateParams.org) {
                    return null;
                  }
                  return api.call({
                    method: 'GET',
                    url: `/organizations/${$stateParams.org}`,
                    params: {
                      fields: 'gid,href,name',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })
          .state('invoices.list', {
            url: '/list?{index:int}',
            templateUrl: 'invoices/sales_list.html',
            controller: 'ListInvoicesController',
            controllerAs: 'vm',
            params: {
              index: 1,
            },
            resolve: {
              invoices: [
                '$stateParams',
                'paginator',
                'api',
                function ($stateParams, paginator, api) {
                  const query = [];

                  if ($stateParams.pdf && 'no' === $stateParams.pdf) {
                    query.push(['invoice_filepath', '==', null]);
                  } else if ($stateParams.pdf && 'yes' === $stateParams.pdf) {
                    query.push(['invoice_filepath', '!=', null]);
                  }

                  if (!$stateParams.org) {
                    return api.call({
                      method: 'GET',
                      url: '/admin/invoices',
                      params: {
                        query: query.length > 0 ? JSON.stringify(query) : null,
                        limit: paginator.getMaxItemPage(),
                        offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                        orderBy: 'created_at:desc',
                        fields: 'organization,created_at,paid_at,gid,paid,price,status,title,invoice_file,projects',
                      },
                    }).then((res) => res.data);
                  }
                  return api.call({
                    method: 'GET',
                    url: `/orgs/${$stateParams.org}/invoices`,
                    params: {
                      query: query.length > 0 ? JSON.stringify(query) : null,
                      limit: paginator.getMaxItemPage(),
                      offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                      orderBy: 'created_at:desc',
                      fields: 'organization,created_at,paid_at,gid,paid,price,status,title,invoice_file,projects',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })
          .state('invoices.creditNotes', {
            url: '/credit-notes?{index:int}',
            templateUrl: 'invoices/credit_notes_list.html',
            controller: 'ListCreditNotesController',
            controllerAs: 'vm',
            params: {
              index: 1,
            },
            resolve: {
              creditNotes: [
                '$stateParams',
                'paginator',
                'api',
                function ($stateParams, paginator, api) {
                  const query = [];

                  if ($stateParams.pdf && 'no' === $stateParams.pdf) {
                    query.push(['invoice_filepath', '==', null]);
                  } else if ($stateParams.pdf && 'yes' === $stateParams.pdf) {
                    query.push(['invoice_filepath', '!=', null]);
                  }

                  if (!$stateParams.org) {
                    query.push(['terms_accepted_at', '!=', null]);

                    return api.call({
                      method: 'GET',
                      url: '/admin/credit_notes',
                      params: {
                        query: query.length > 0 ? JSON.stringify(query) : null,
                        limit: paginator.getMaxItemPage(),
                        offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                        orderBy: 'created_at:desc',
                        fields: 'organization,created_at,reference,credit_amount,amount,status,invoice_file',
                      },
                    }).then((res) => res.data);
                  }
                  return api.call({
                    method: 'GET',
                    url: `/orgs/${$stateParams.org}/credit_notes`,
                    params: {
                      query: query.length > 0 ? JSON.stringify(query) : null,
                      limit: paginator.getMaxItemPage(),
                      offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                      orderBy: 'created_at:desc',
                      fields: 'organization,created_at,reference,credit_amount,amount,status,invoice_file',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })
          .state('credits', {
            url: '/credits?{index:int}',
            parent: 'root',
            templateUrl: 'invoices/credits.html',
            controller: 'CreditsController',
            controllerAs: 'vm',
            params: {
              index: 1,
            },
            resolve: {
              creditNotes: [
                '$stateParams',
                'paginator',
                'api',
                function ($stateParams, paginator, api) {
                  return api.call({
                    method: 'GET',
                    url: '/admin/credit_notes',
                    params: {
                      limit: paginator.getMaxItemPage(),
                      offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                      orderBy: 'created_at:desc',
                      fields: 'organization,created_at,reference,credit_amount,available_amount,title,terms_accepted',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })
          .state('accountingExport', {
            url: '/export',
            parent: 'root',
            templateUrl: 'invoices/export.html',
            controller: 'AccountingExportController',
            controllerAs: '$accountingExportCtrl',
            resolve: {},
          });
      },
    ])

    .controller('InvoicesController', [
      '$state',
      '$stateParams',
      '$translate',
      'api',
      'organization',
      function InvoicesController($state, $stateParams, $translate, api, organization) {
        const vm = this;

        vm.organization = organization;
        vm.organizationsResults = [];
        vm.pdfFilter = null;
        vm.pdfFilterChoices = [{
          value: '',
          label: '--',
        }, {
          value: 'yes',
          label: $translate.instant('common.yes'),
        }, {
          value: 'no',
          label: $translate.instant('common.no'),
        }];

        vm.pdfFilter = vm.pdfFilterChoices[0];
        if ($stateParams.pdf) {
          vm.pdfFilter = _.findWhere(vm.pdfFilterChoices, { value: $stateParams.pdf });
        }

        vm.changeStateParams = function changeStateParams() {
          $state.go($state.current.name, { org: vm.organization ? vm.organization.gid : null, pdf: vm.pdfFilter && vm.pdfFilter.value ? vm.pdfFilter.value : null });
        };

        vm.getMatchingOrganizations = async function getMatchingOrganizations(search) {
          const searchResults = (await api
            .call({
              method: 'GET',
              url: '/organizations',
              params: {
                search,
                fields: 'name,auxiliary_account',
              },
            })).data.items;
          const accountResults = (await api
            .call({
              method: 'GET',
              url: '/organizations',
              params: {
                aux_account: search,
                fields: 'name',
              },
            })).data.items;
          vm.organizationsResults = [...searchResults, ...accountResults];
        };
      },
    ])

    .controller('ListInvoicesController', [
      'INVOICE_STATUS',
      'invoices',
      function ListInvoicesController(INVOICE_STATUS, invoices) {
        const vm = this;
        vm.invoices = invoices.items;
        vm.totalCount = invoices.total_count;
        vm.INVOICE_STATUS = INVOICE_STATUS;
      },
    ])

    .controller('ListCreditNotesController', [
      'CREDIT_NOTE',
      'creditNotes',
      function ListCreditNotesController(CREDIT_NOTE, creditNotes) {
        const vm = this;
        vm.creditNotes = creditNotes.items;
        vm.totalCount = creditNotes.total_count;
        vm.CREDIT_NOTE = CREDIT_NOTE;
      },
    ])

    .controller('CreditsController', [
      'creditNotes',
      function CreditsController(creditNotes) {
        const vm = this;
        vm.creditNotes = creditNotes.items;
        vm.totalCount = creditNotes.total_count;
      },
    ])

    .controller('AccountingExportController', [
      '$translate',
      'moment',
      'notifications',
      'api',
      function AccountingExportController($translate, moment, notifications, api) {
        const vm = this;

        const dateIterator = moment();
        vm.availableDates = [];

        for (let index = 0; index < 7; index++) {
          vm.availableDates.push({
            displayAs: dateIterator.format('MMMM YYYY'),
            value: dateIterator.format('MM-YYYY'),
          });
          dateIterator.subtract(1, 'months');
        }

        vm.monthAndYear = vm.availableDates[1].value;

        vm.submit = function submit() {
          api
            .call({
              method: 'POST',
              url: '/admin/accounting-export',
              data: {
                month_and_year: vm.monthAndYear,
              },
            })
            .then(() => {
              notifications.showSuccess({
                message: $translate.instant('accounting_export.success'),
              });
            }, () => {
              notifications.showError({
                message: $translate.instant('common.error.action_fail'),
              });
            });
        };
      },
    ]);
}());

(function () {
  angular
    .module('partners-bo.components.directives')
    .directive('loadMore', [
      LoadMoreDirective,
    ])
  ;

  /**
   * @name LoadMoreDirective
   * @desc <load-more></load-more>
   */
  function LoadMoreDirective() {
    function LoadMoreController(api) {
      const vm = this;

      vm.itemsPerPage = vm.itemsPerPage || 5;
      vm.currentPage = 0;
      vm.totalPage = Math.ceil(vm.totalCount / vm.itemsPerPage);
      vm.isLoading = false;

      vm.loadMore = function loadMore() {
        vm.currentPage += 1;
        vm.baseQuery.params = vm.baseQuery.params || {};
        vm.baseQuery.params.limit = vm.itemsPerPage;
        vm.baseQuery.params.offset = vm.currentPage * vm.itemsPerPage;
        vm.isLoading = true;
        api
          .call(vm.baseQuery)
          .success((res) => {
            vm.items = vm.items.concat(res.items);
          })
          .finally(() => {
            vm.isLoading = false;
          });
      };
    }

    return {
      scope: {
        totalCount: '=',
        items: '=',
        baseQuery: '=',
        itemsPerPage: '@?',
      },
      controller: [
        'api',
        LoadMoreController,
      ],
      controllerAs: 'vm',
      restrict: 'E',
      templateUrl: '/components/directives/load-more.html',
      bindToController: true,
    };
  }
}());

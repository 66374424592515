(function () {
  /**
   * Moment config
   */
  angular
    .module('partners-bo')
    .run([
      'amMoment',
      function (amMoment) {
        amMoment.changeLocale('fr');
        moment.locale('fr');
      },
    ]);
}());

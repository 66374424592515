(function () {
  angular
    .module('partners-bo.components.services')
    .service('stepService', [
      'STEPS',
      '$translate',
      'api',
      function stepService(STEPS, $translate, api) {
        const stepServiceVm = this;
        let currentStep = null;
        let originStep = null;
        let select: StepDefinitionOption[];
        let radio: StepDefinitionOption[];
        let checkbox: StepDefinitionOption[];
        let number: StepDefinitionThreshold[];
        let saleThreshold: StepDefinitionThreshold[] = [];
        let purchaseThreshold: StepDefinitionThreshold[] = [];
        let listings: Listing[];
        let products: Product[] = [];
        const productsPagination = {
          offset: 0,
          limit: 10,
          max: 10,
          isLoading: false,
        };

        const operators = [
          { key: STEPS.OPERATOR.ANY, value: $translate.instant('steps.fields.number.operators.any') },
          { key: STEPS.OPERATOR.ADDITION, value: $translate.instant('steps.fields.number.operators.addition') },
          { key: STEPS.OPERATOR.MULTIPLICATION, value: $translate.instant('steps.fields.number.operators.multiplication') },
        ];

        const uploader = {
          constraints: {
            width: { min: 410 },
            height: { min: 160 },
            pattern: '.jpg,.gif,.jpeg,.png',
            accept: 'image/jpg,image/jpeg,image/png,image/gif',
            size: {
              max: '8MB',
            },
          },
          errors: {},
        };
        const disposalAgreement = {
          territories: {
            country: $translate.instant('steps.fields.radio.options.disposal_agreement.territories.country'),
            world: $translate.instant('steps.fields.radio.options.disposal_agreement.territories.world'),
          },
          durations: {
            '3': $translate.instant('steps.fields.radio.options.disposal_agreement.durations.first'),
            '10': $translate.instant('steps.fields.radio.options.disposal_agreement.durations.second'),
            '-1': $translate.instant('steps.fields.radio.options.disposal_agreement.durations.third')
          }
        }

        stepServiceVm.initialize = (step: Step, originStepParam: Step) => {
          stepServiceVm.setCurrentStep(step);
          originStep = originStepParam;

          stepServiceVm.initListings();

          if (step) {
            if (STEPS.TYPE.RADIO === step.type) {
              productsPagination.offset = 0;
              stepServiceVm.loadProducts(null, true);
            }

            if (
              (STEPS.TYPE.RADIO === step.type
                || STEPS.TYPE.CHECKBOX === step.type
                || STEPS.TYPE.NUMBERS === step.type
              )
              && !stepServiceVm.getCurrentStep().definition.operator
            ) {
              stepServiceVm.getCurrentStep().definition.operator = STEPS.OPERATOR.ADDITION;
            }
          }

          if (!stepServiceVm.getCurrentStep() || !stepServiceVm.getCurrentStep().gid) {
            select = [
              { key: 1, value: null },
            ];
            checkbox = [
              { key: 1, value: null },
            ];
            radio = [
              {
                key: 1, value: null, amount: 0, product: null,
              },
              {
                key: 2, value: null, amount: 0, product: null,
              },
            ];
            number = [];
            purchaseThreshold = [];
            saleThreshold = [];
          } else {
            if (STEPS.TYPE.SELECT === currentStep.type || STEPS.TYPE.CHECKBOX === step.type) {
              currentStep.definition.operator = STEPS.OPERATOR.ADDITION;
              stepServiceVm.setDefinitionOptions(currentStep.definition.options);
            }
            if (STEPS.TYPE.RADIO === currentStep.type || STEPS.TYPE.CHECKBOX === currentStep.type) {
              stepServiceVm.setDefinitionOptions(currentStep.definition.options);
            }
            if (STEPS.TYPE.NUMBER === currentStep.type) {
              if (currentStep.definition.thresholds) {
                stepServiceVm.setThresholds(currentStep.definition.thresholds, STEPS.THRESHOLD.SALE);
              }
              if (currentStep.definition.purchase && currentStep.definition.purchase.thresholds) {
                stepServiceVm.setThresholds(currentStep.definition.purchase.thresholds, STEPS.THRESHOLD.PURCHASE);
              }
            }
            if (STEPS.TYPE.RADIO_IMAGE === currentStep.type) {
              currentStep.definition.multipleChoice = (currentStep.definition.multipleChoice) ? currentStep.definition.multipleChoice : false;
            }
          }
        };

        function orderOptions() {
          if (STEPS.TYPE.RADIO === currentStep.type) {
            for (let i = 0; i < radio.length; i++) {
              radio[i].key = i + 1;
            }
          }
          if (STEPS.TYPE.SELECT === currentStep.type) {
            for (let i = 0; i < select.length; i++) {
              select[i].key = i + 1;
            }
          }
          if (STEPS.TYPE.CHECKBOX === currentStep.type) {
            for (let i = 0; i < checkbox.length; i++) {
              checkbox[i].key = i + 1;
            }
          }
        }

        stepServiceVm.getDisposalAgreementTerritories = () => disposalAgreement.territories;
        stepServiceVm.getDisposalAgreementDurations = () => disposalAgreement.durations;

        stepServiceVm.getOperators = () => {
          let operatorsByStep = [];

          operators.forEach((operator) => {
            if (STEPS.TYPE.NUMBERS === currentStep.type && STEPS.OPERATOR.MULTIPLICATION === operator.key) {
              return;
            }
            operatorsByStep.push(operator);
          });

          return operatorsByStep;
        };

        stepServiceVm.hasOriginStep = () => !!originStep;
        stepServiceVm.getUploader = () => uploader;

        stepServiceVm.getCurrentStep = () => currentStep;

        stepServiceVm.setCurrentStep = (step: Step) => {
          currentStep = step;
        };

        stepServiceVm.getDefinitionOptions = () => getCurrentButton();

        stepServiceVm.setDefinitionOptions = (options: StepDefinitionOption[]) => {
          if (STEPS.TYPE.RADIO === currentStep.type) {
            radio = options;
          } else if (STEPS.TYPE.SELECT === currentStep.type) {
            select = options;
          } else if (STEPS.TYPE.CHECKBOX === currentStep.type) {
            checkbox = options;
          }
          return [];
        };

        stepServiceVm.addDefinitionOption = () => {
          let button = getCurrentButton();

          if (
            (STEPS.TYPE.RADIO === currentStep.type && button.length >= 20)
            || (STEPS.TYPE.SELECT === currentStep.type && button.length >= 100)
            || (STEPS.TYPE.CHECKBOX === currentStep.type && button.length >= 20)
          ) {
            return;
          }
          orderOptions();
          const optionValue = {
            key: button.length + 1,
            value: null,
            amount: 0,
          };
          button.push(optionValue);
        };

        stepServiceVm.removeDefinitionOption = (option) => {
          let button = getCurrentButton();
          const index = _.findLastIndex(button, option);
          button.splice(index, 1);
          orderOptions();
        };

        stepServiceVm.onAddFiles = function onAddFiles() {
          return function (file) {};
        };

        stepServiceVm.onRemoveFiles = function onRemoveFiles() {
          return function (file, callback) {
            callback();
          };
        };

        stepServiceVm.getThresholds = (type) => {
          if (!type) {
            type = STEPS.THRESHOLD.SALE;
          }
          return (STEPS.THRESHOLD.PURCHASE === type) ? purchaseThreshold : saleThreshold;
        };

        stepServiceVm.setThresholds = (thresholds: StepDefinitionThreshold[], type) => {
          if (STEPS.THRESHOLD.PURCHASE === type) {
            purchaseThreshold = thresholds;
          } else {
            saleThreshold = thresholds;
          }
          return thresholds;
        };

        stepServiceVm.addThreshold = (type) => {
          if (!type) {
            type = STEPS.THRESHOLD.SALE;
          }
          const defaultThreshold = {
            quantity: 1,
            percent: 1,
            discount: 0,
          };

          if (STEPS.THRESHOLD.PURCHASE === type) {
            if (!purchaseThreshold) {
              purchaseThreshold = [];
            }
            purchaseThreshold.push(defaultThreshold);
          } else {
            if (!saleThreshold) {
              saleThreshold = [];
            }
            saleThreshold.push(defaultThreshold);
          }
        };

        stepServiceVm.removeThreshold = (stepDefinitionThreshold: StepDefinitionThreshold, type) => {
          if (STEPS.THRESHOLD.PURCHASE === type) {
            const index = purchaseThreshold.indexOf(stepDefinitionThreshold);
            if (index !== -1) {
              purchaseThreshold.splice(index, 1);
            }
          } else {
            const index = saleThreshold.indexOf(stepDefinitionThreshold);
            if (index !== -1) {
              saleThreshold.splice(index, 1);
            }
          }
        };

        stepServiceVm.isCustom = () => !currentStep
          || 'undefined' === typeof currentStep.custom
          || currentStep.custom
        ;

        stepServiceVm.displayQuoteLine = () => currentStep
          && (!stepServiceVm.isCustom()
            || STEPS.TYPE.SELECT === currentStep.type
            || STEPS.TYPE.RADIO === currentStep.type
            || STEPS.TYPE.CHECKBOX === currentStep.type
            || STEPS.TYPE.DATE === currentStep.type
            || STEPS.TYPE.RANGE === currentStep.type
            || STEPS.TYPE.RADIO_IMAGE === currentStep.type
            || STEPS.TYPE.NUMBER === currentStep.type
            || STEPS.TYPE.NUMBERS === currentStep.type
          );

        stepServiceVm.initListings = () => {
          api
            .call({
              method: 'GET',
              url: '/listings',
              params: {
                fields: 'gid,name,created_at,modified_at',
                limit: -1,
              }
            }).success((response) => {
              listings = response.items;
            });
        };

        stepServiceVm.getListings = () => listings;

        stepServiceVm.getProducts = () => products;

        stepServiceVm.loadProducts = (query: string, triggeredBySearch: Boolean) => {
          if (triggeredBySearch) {
            productsPagination.offset = 0;
            productsPagination.max = 10;
          }

          if (productsPagination.isLoading
            || productsPagination.max <= productsPagination.offset
          ) {
            return;
          }

          productsPagination.isLoading = true;

          const params: ParamsQuery = {
            fields: 'title,icon',
            locale: 'fr_FR',
            offset: productsPagination.offset,
            limit: productsPagination.limit,
          };

          if (query) {
            params.search = query;
          }

          return api
            .call({
              method: 'GET',
              url: '/products',
              params,
            })
            .then((response) => {
              productsPagination.isLoading = false;
              products = triggeredBySearch
                ? response.data.items
                : products.concat(response.data.items)
              ;
              productsPagination.offset += productsPagination.limit;
              productsPagination.max = response.data.total_count;
            })
          ;
        };

        stepServiceVm.onPurchaseOperatorChange = (index) => {
          if (STEPS.TYPE.SELECT === currentStep.type) {
            select[index].purchase.value = null;
          }
          if (STEPS.TYPE.RADIO === currentStep.type) {
            radio[index].purchase.value = null;
          }
          if (STEPS.TYPE.NUMBER === currentStep.type && !index) {
            currentStep.definition.purchase.value = null;
          }
        };

        stepServiceVm.importDefinitionOption = () => {
          if (!originStep) {
            return;
          }

          currentStep.definition = originStep.definition;

          stepServiceVm.initialize(currentStep, originStep);
        };

        function getCurrentButton() {
          if (STEPS.TYPE.RADIO === currentStep.type) {
            return radio;
          } else if (STEPS.TYPE.SELECT === currentStep.type) {
            return select;
          } else if (STEPS.TYPE.CHECKBOX === currentStep.type) {
            return checkbox;
          }

          return [];
        }

      },
    ]);
}());

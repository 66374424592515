(function () {
  /**
  * apps Module
  */
  angular
    .module('partners-bo.apps', [
    ])
    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider

          .state('apps', {
            parent: 'root',
            url: '/apps/{index:int}?{search:string}',
            templateUrl: 'apps/apps.html',
            controller: 'ListAppsController',
            controllerAs: 'vm',
            reloadOnSearch: true,
            resolve: {
              apps: [
                '$stateParams',
                'api',
                'paginator',
                function ($stateParams, api, paginator) {
                  let query = [];
                  if ($stateParams.search) {
                    const { search } = $stateParams;
                    query = [['name', '~=', search]];
                  }

                  return api.call({
                    method: 'GET',
                    url: '/apps',
                    params: {
                      query: query.length > 0 ? JSON.stringify(query) : null,
                      limit: paginator.getMaxItemPage(),
                      offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                      fields: 'name,allowed_grant_types,app_id',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('apps-add', {
            parent: 'root',
            url: '/apps/add',
            templateUrl: 'apps/edit.form.html',
            controller: 'ManageAppsController',
            controllerAs: 'vm',
            resolve: {
              app: [
                function () {
                  return {
                    allowed_grant_types: [],
                    external_refresh: true,
                    redirect_on_expiration: false,
                  };
                },
              ],
            },
          })

          .state('apps-edit', {
            parent: 'root',
            url: '/apps/{id}/edit',
            templateUrl: 'apps/edit.form.html',
            controller: 'ManageAppsController',
            controllerAs: 'vm',
            resolve: {
              app: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/apps/${$stateParams.id}`,
                    params: {
                      fields: 'name,owner,callback_url,role,template,allowed_grant_types,external_refresh,redirect_on_expiration',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('apps-confirm', {
            parent: 'root',
            url: '/apps/confirm?secret&client_id',
            templateUrl: 'apps/confirm.html',
            controller: 'AppConfirmController',
            controllerAs: 'vm',
          });

        $urlRouterProvider
          .when('/apps', '/apps/1')
        ;
      },
    ])

    .controller('ListAppsController', [
      '$translate',
      'api',
      'notifications',
      'apps',
      function ListAppsController($translate, api, notifications, apps) {
        const vm = this;

        vm.apps = apps.items;
        vm.totalCount = apps.total_count;

        /**
         * [remove]
         * @param  {object} app
         * @return {void}
         */
        vm.remove = function remove(app) {
          api
            .call({
              method: 'DELETE',
              url: `/apps/${app.gid}`,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('L\'app a bien été supprimée.'),
              });
              vm.apps.splice(vm.apps.indexOf(app), 1);
            })
            .error((data) => {
              throw new Error(`Error ${data}`);
            });
        };
      },
    ])

    .controller('AppConfirmController', [
      '$stateParams',
      function AppConfirmController($stateParams) {
        const vm = this;

        vm.secret = $stateParams.secret;
        vm.client_id = $stateParams.client_id;
      },
    ])

    .controller('ManageAppsController', [
      'ROLES',
      '$translate',
      '$state',
      'notifications',
      'api',
      'app',
      'currentUser',
      function ManageAppsController(ROLES, $translate, $state, notifications, api, app, currentUser) {
        const vm = this;

        vm.app = app;
        vm.users = [];
        vm.currentUser = currentUser;
        vm.ROLES = ROLES;
        vm.USERS_ROLES = [{
          name: $translate.instant('apps.edit.role.label_role_customer'),
          value: vm.ROLES.CUSTOMER,
        }, {
          name: $translate.instant('apps.edit.role.label_role_designer'),
          value: 'designer',
        }];

        if (vm.app.owner) {
          vm.users.push({
            term: vm.app.owner.display_full_name,
            user: vm.app.owner,
          });
        }

        vm.allowed_grant_types = [
          {
            name: 'password',
            state: (vm.app.allowed_grant_types.indexOf('password') != -1),
          },
          {
            name: 'refresh_token',
            state: (vm.app.allowed_grant_types.indexOf('refresh_token') != -1),
          },
          {
            name: 'authorization_code',
            state: (vm.app.allowed_grant_types.indexOf('authorization_code') != -1),
          },
          {
            name: 'client_credentials',
            state: (vm.app.allowed_grant_types.indexOf('client_credentials') != -1),
          },
          {
            name: 'jwt_token',
            state: (vm.app.allowed_grant_types.indexOf('jwt_token') != -1),
          },
          {
            name: 'signed_auth',
            state: (vm.app.allowed_grant_types.indexOf('signed_auth') != -1),
          },
        ];

        vm.signedAuth = _.findWhere(vm.allowed_grant_types, { name: 'signed_auth' });

        vm.getMatchingUsers = function getMatchingUsers(searchQuery) {
          if ('' === searchQuery) {
            return;
          }
          api.call({
            method: 'GET',
            url: '/users',
            params: {
              search: searchQuery,
              fields: 'display_full_name,email',
            },
          }).then((response) => {
            vm.users = response.data.items;
          });
        };

        vm.getMatchingTemplateOrgs = function getMatchingTemplateOrgs(search) {
          if ('' === search) {
            return;
          }
          return api.call({
            method: 'GET',
            url: '/organizations',
            params: {
              query: JSON.stringify([['template', '==', null]]),
              search,
              fields: 'name',
            },
          }).then((response) => {
            vm.templateOrgs = response.data.items;
          });
        };

        /**
         * [submit]
         * @return {void}
         */
        vm.submit = function submit() {
          if (vm.form.$submitted && vm.form.$valid && vm.app.owner) {
            vm.app.allowed_grant_types = [];
            vm.allowed_grant_types.forEach((val) => {
              if (val.state) {
                vm.app.allowed_grant_types.push(val.name);
              }
            });
            api
              .call({
                method: (vm.app.gid) ? 'PUT' : 'POST',
                url: (vm.app.gid) ? `/apps/${vm.app.gid}` : '/apps',
                data: _.pick(vm.app, ['callback_url', 'name', 'owner', 'allowed_grant_types', 'template', 'role', 'external_refresh', 'redirect_on_expiration']),
                params: {
                  fields: 'app_secret,app_id',
                },
              })
              .success((data) => {
                notifications.showSuccess({
                  message: $translate.instant('L\'app a bien été modifiée.'),
                });
                if (vm.app.gid) {
                  $state.go('apps', { index: 1 });
                } else {
                  $state.go('apps-confirm', { secret: data.app_secret, client_id: data.app_id });
                }
              })
              .error((data) => {
                throw new Error(`Error ${data}`);
              });
          }
        };
      },
    ]);
}());

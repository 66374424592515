(function () {
  angular
    .module('partners-bo.components.services')
    .service('template', [
      'SERVER',
      '$translate',
      'notifications',
      'api',
      function template(SERVER, $translate, notifications, api) {
        const vm = this;

        /**
         * Tells Partners API to clone the template price
         * @param  {object} org An organization
         * @return {void}
         */
        vm.cloneTemplateForOrg = function cloneTemplateForOrg(org) {
          return api
            .call({
              method: 'PUT',
              url: `/organizations/${org.gid}`,
              data: {
                inherits_from_template: false,
              },
            })
            .success(() => {
              api
                .call({
                  method: 'POST',
                  url: `/organizations/${org.gid}/products/import`,
                  data: {
                    source: org.template
                  }
                })
              ;
            })
          ;
        };

        /**
         * Tells Partners API to unclone the template price
         * @param  {object} org An organization
         * @return {void}
         */
        vm.uncloneTemplateForOrg = function uncloneTemplateForOrg(org) {
          return api
            .call({
              method: 'PUT',
              url: `/organizations/${org.gid}`,
              data: {
                inherits_from_template: true,
              },
            });
        };

        /**
         * Returns whether a specific organization is a Pricing template Organization
         * @param  {object}  org An organization
         * @return {Boolean}
         */
        vm.isTemplateOrg = function isTemplateOrg(org) {
          return !org.template;
        };
      },
    ]);
}());

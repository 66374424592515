
const PleaseWait = require('please-wait');

(function () {
  let pleaseWaitScreen = null;

  // pleaseWait screen is disabled on Internet Explorer/Edge ≤ 10
  // using the following JS hack http://browserhacks.com/#hack-51eca7694aed2dcc3266bc9c8de72872
  const isIE = /* @cc_on!@ */false;

  if (envConfig.pleaseWaitScreen.enabled && !isIE) {
    pleaseWaitScreen = PleaseWait.pleaseWait({
      logo: 'assets/img/creads-logo-white-animated.svg',
      backgroundColor: '#1fb5ad',
      loadingHtml: '<div class="sk-folding-cube"><div class="sk-cube1 sk-cube"></div><div class="sk-cube2 sk-cube"></div><div class="sk-cube4 sk-cube"></div><div class="sk-cube3 sk-cube"></div></div>',
    });

    angular
      .module('partners-bo')
      .run([
        '$rootScope',
        function ($rootScope) {
          // tell pleaseWait.ts that loading is finished
          $rootScope.firstLoad = $rootScope.firstLoad || true;
          $rootScope.$on('ready', () => {
            if ($rootScope.firstLoad) {
              $rootScope.firstLoad = false;
              pleaseWaitScreen.finish();
            }
          });
        },
      ])
    ;
  }
}());

(function () {
  /**
   *
   * http://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript
   *
   * This filter allows you to fix JS Float bug that happens once in a while
   * (e.g: 127.2 is printed 127.1999999999999)
   * to use this filter in a template, simply do {{ yourVariable|toFixed:2}} (2 is the number of decimals)
   * If you want to allow ',00' a the end of the float, {{ yourVariable|toFixed:2:true}}
   */
  angular
    .module('partners-bo.components.filters')
    .filter('toFixed', [
      function () {
        return function (msg, decimals, allowZeros) {
          decimals = decimals || 2;
          allowZeros = allowZeros || false;
          msg = parseFloat(msg);
          if (msg % 1 !== 0 || allowZeros) {
            msg = msg.toFixed(decimals);
          } else {
            msg = msg.toFixed(0);
          }

          return msg;
        };
      }]);
}());

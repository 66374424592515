(function () {
  /**
  * Menu directive
  */
  angular
    .module('partners-bo.components.directives')
    .directive('menuAccordion', [
      menuAccordionDirective,
    ])
  ;

  function menuAccordionDirective() {
    function menuAccordionController(ROLES) {
      const vm = this;

      vm.ROLES = ROLES;
    }

    return {
      scope: {
        defaultOrgaGid: '@',
        currentUser: '=',
        toggler: '=',
      },
      restrict: 'E',
      controller: [
        'ROLES',
        menuAccordionController,
      ],
      bindToController: true,
      controllerAs: 'vm',
      templateUrl: 'components/directives/menu-accordion.template.html',
      link($scope: any, $el) {
        $('#nav-accordion').dcAccordion({
          eventType: 'click',
          autoClose: true,
          saveState: true,
          disableLink: true,
          speed: 'slow',
          showCount: false,
          autoExpand: true,
          classExpand: 'dcjq-current-parent',
        });
        $scope.$watch('toggler', (newValue) => {
          $scope.toggler = newValue;
        });
      },
    }
  }
}());

(function () {
  /**
  * Filters Module
  *
  * Description
  */
  angular
    .module('partners-bo.components.filters', [
    ]);
}());

(function () {
  /**
   * Authentication config
   */
  angular
    .module('partners-bo')
    .run([
      'ROLES',
      '$http',
      '$state',
      '$rootScope',
      '$transitions',
      '$translate',
      'notifications',
      'api',
      function (ROLES, $http, $state, $rootScope, $transitions, $translate, notifications, api) {
        // we need to store the next step to use on when auth-login-requiredevent is fired
        $rootScope.toState = false;
        $rootScope.toParams = false;
        $transitions.onStart({}, (trans) => {
          $rootScope.toState = trans.to();
          $rootScope.toParams = trans.from();
          if (!trans.to().data || !trans.to().data.public) {
            api.call({
              method: 'GET',
              url: '/me',
              params: {
                fields: 'roles',
              },
            }).success((response) => {
              if (response.roles.indexOf(ROLES.ADMIN) < 0) {
                $rootScope.$broadcast('auth-forbidden', response);
              }
            });
          }
        });

        $rootScope.$on('auth-login-required', () => {
          if ($rootScope.toState) {
            $state.go('auth.login', { state: $rootScope.toState.name, params: JSON.stringify($rootScope.toParams) });
          } else {
            $state.go('auth.login');
          }
        });

        $rootScope.$on('auth-forbidden', () => {
          notifications.showError({
            message: $translate.instant('L\'action que vous avez déclenchée n\'est pas autorisée.'),
            hide: false,
          });
          $state.go('auth.login');
        });

        $rootScope.$on('auth-error', () => {
          notifications.showError({
            message: $translate.instant('Une erreur critique empêche le bon fonctionnement de l\'application.'),
            hide: false,
          });
        });
      },
    ]);
}());

(function () {
  /**
  * This class is used to generate external link (to FrontApp) given a specific resource
  */
  angular
    .module('partners-bo.components.services')
    .service('partnersLink', [
      'SERVER',
      function partnersLink(SERVER) {
        const vm = this;

        /**
         * get app link to an organization
         * @param  {object} organization
         * @return {string}              the url
         */
        vm.getOrganizationUrl = function getOrganizationUrl(organization) {
          return `${SERVER.BUSINESS_URL}/` + `#/${organization.gid}/projects/`;
        };

        /**
         * get app link to a work
         * @param  {object} work
         * @param  {object|undefined} comment
         * @return {string}         the url
         */
        vm.getWorkUrl = function getWorkUrl(work, comment) {
          if (comment && comment.gid) {
            return `${SERVER.BUSINESS_URL}/#/${work.project.organization.gid}/project/${work.project.gid}/gallery/${work.gid}/v/${work.gid}?comment=${comment.gid}`;
          }
          return `${SERVER.BUSINESS_URL}/#/${work.project.organization.gid}/project/${work.project.gid}/gallery/${work.gid}/v/${work.gid}`;
        };

        /**
         * get app link to a project
         * @param  {object} project
         * @param  {object|undefined} comment
         * @return {string}         the url
         */
        vm.getProjectUrl = function getProjectUrl(project, comment) {
          if (!project || !project.organization) {
            return null;
          }
          if (comment && comment.gid) {
            return `${SERVER.BUSINESS_URL}/#/${project.organization.gid}/project/${project.gid}/gallery?comment=${comment.gid}`;
          }

          if ('multi' === project.options.mode) {
            return `${SERVER.BUSINESS_URL}/#/${project.organization.gid}/project/${project.gid}/gallery`;
          }
          return `${SERVER.BUSINESS_URL}/#/${project.organization.gid}/project/${project.gid}`;
        };

        vm.getProjectFeedUrl = function getProjectFeedUrl(project, msg) {
          if (msg && msg.gid) {
            return `${SERVER.BUSINESS_URL}/#/${project.organization.gid}/project/${project.gid}?m=${msg.gid}`;
          }
          return `${SERVER.BUSINESS_URL}/#/${project.organization.gid}/project/${project.gid}`;
        };

        vm.getProjectSetupUrl = function getProjectSetupUrl(project) {
          return `${SERVER.BUSINESS_URL}/#/${project.organization.gid}/order/${project.gid}?step=0`;
        };

        vm.getProjectUrlByShortCode = function getProjectUrlByShortCode(shortCode) {
          return `${SERVER.BUSINESS_URL}/#/project/${shortCode}`;
        };
      },
    ]);
}());

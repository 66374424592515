(function () {
  /**
   * Main app module
   */
  angular
    .module('partners-bo', [
      // Third party
      'ngSanitize',
      'ngRaven',
      'ngCookies',
      'ngAnimate',
      'ngMessages',
      'ui.router',
      'ngBootbox',
      'pascalprecht.translate',
      'angularMoment',
      'ui.bootstrap',
      'ui.bootstrap.tooltip',
      'ui.select',
      'angular-loading-bar',
      'partners.api',
      'partners.sdk',
      'ngTagsInput',
      'ngNotificationsBar',
      'ipCookie',
      'ngAutodisable',
      'monospaced.elastic',
      'ui.bootstrap',
      'ui-select-infinity',

      // Components
      'partners-bo.components.directives',
      'partners-bo.components.services',
      'partners-bo.components.filters',

      // Feature dependency
      'partners-bo.layout',
      'partners-bo.auth',
      'partners-bo.organizations',
      'partners-bo.apps',
      'partners-bo.products',
      'partners-bo.users',
      'partners-bo.categories',
      'partners-bo.dashboard',
      'partners-bo.projects',
      'partners-bo.workers',
      'partners-bo.reviews',
      'partners-bo.invoices',
      'partners-bo.payments',
      'partners-bo.settings',
      'partners-bo.scenarios',
    ]);
}());

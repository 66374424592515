(function () {
  /**
   * Scenarios module
   */
  angular
    .module('partners-bo.scenarios', [
    ])

    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('scenarios', {
            parent: 'root',
            url: '/scenarios/list/{index:int}?{limit:int}&{search:string}',
            controller: 'ListScenarioController',
            controllerAs: 'listVm',
            templateUrl: 'scenarios/list.html',
            resolve: {
              scenarios: [
                '$stateParams',
                'api',
                'paginator',
                function ($stateParams, api, paginator) {
                  paginator.setMaxItemPage($stateParams.limit);
                  const params: ParamsQuery = {
                    fields: 'gid,name,editable,default,products_titles,modified_at,specific_request',
                    orderBy: 'default:desc,modified_at:desc',
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    locale: 'fr_FR',
                  };
                  let query = [];

                  if ($stateParams.search) {
                    const { search } = $stateParams;
                    query = ['name', '~=', search];
                  }
                  params.query = JSON.stringify(query);

                  return api.call({
                    method: 'GET',
                    url: '/scenarios',
                    params,
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('scenarios-view', {
            parent: 'root',
            url: '/scenarios/view/{gid:string}',
            controller: 'ViewScenarioController',
            controllerAs: 'viewVm',
            templateUrl: 'scenarios/view.html',
            resolve: {
              scenario: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/scenarios/${$stateParams.gid}`,
                    params: {
                      fields: 'gid,name,editable,steps,default,modified_at',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('scenarios-edit', {
            parent: 'root',
            url: '/scenarios/edit/{gid}',
            controller: 'EditScenarioController',
            controllerAs: 'editVm',
            templateUrl: 'scenarios/edit.html',
            resolve: {
              scenario: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/scenarios/${$stateParams.gid}`,
                    params: {
                      fields: 'gid,name,editable,steps,default,modified_at',
                      locale: 'fr_FR',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('steps-translate', {
            parent: 'root',
            url: `/scenarios/{scenarioGid}/steps/{stepGid}/translate`,
            controller: 'TranslateScenarioStepController',
            controllerAs: 'stepVm',
            templateUrl: 'scenarios/translate_step.html',
            resolve: {
              localizedStep: [
                '$stateParams',
                'api',
                ($stateParams, api) => {
                  return api.call({
                    method: 'GET',
                    url: `/steps/${$stateParams.stepGid}`,
                    params: {
                      fields: 'type,locale,title,headline,description,quote_line,definition,files',
                      locale: 'en_US',
                    }
                  }).then((response) => response.data);
                }
              ],
              scenario: [
                '$stateParams',
                'api',
                ($stateParams, api) => {
                  return api.call({
                    method: 'GET',
                    url: `/scenarios/${$stateParams.scenarioGid}`,
                    params: {
                      fields: 'gid,name'
                    }
                  }).then((response) => response.data);
                }
              ],
              step: [
                '$stateParams',
                'api',
                ($stateParams, api) => {
                  return api.call({
                    method: 'GET',
                    url: `/steps/${$stateParams.stepGid}`,
                    params: {
                      fields: 'type,locale,title,headline,description,quote_line,definition,files',
                      locale: 'fr_FR',
                    }
                  }).then((response) => response.data);
                }
              ],
            },
          })
        ;

        $urlRouterProvider
          .when('/scenarios', '/scenarios/list/1')
        ;
      },
    ])

    .controller('ListScenarioController', [
      '$state',
      '$uibModal',
      '$translate',
      'scenarios',
      'api',
      'notifications',
      function ListScenarioController($state, $uibModal, $translate, scenarios, api, notifications) {
        const listVm = this;

        listVm.scenarios = scenarios.items;
        listVm.totalCount = scenarios.total_count;

        listVm.addScenario = () => {
          api
            .call({
              method: 'POST',
              url: '/scenarios',
              data: {
                name: null,
              },
            })
            .success(() => {
              $state.go($state.current.name, {}, { reload: true });
            })
            .error((data) => {
              throw new Error(data);
            })
          ;
        };

        listVm.cloneScenario = (scenario) => {
          if (scenario.gid) {
            api
              .call({
                method: 'POST',
                url: `/scenarios/${scenario.gid}/clone`,
              })
              .success(() => {
                notifications.showSuccess({
                  message: $translate.instant('scenario.notifications.success.clone'),
                });
                $state.go($state.current.name, {}, { reload: true });
              })
              .error(() => {
                notifications.showError({
                  message: $translate.instant('scenario.notifications.error.clone'),
                });
              })
            ;
          }
        };

        listVm.deleteScenarioModal = (scenario) => {
          $uibModal.open({
            templateUrl: '/scenarios/delete_modal.html',
            controller: 'DeleteScenarioController',
            controllerAs: 'deleteVm',
            bindToController: true,
            resolve: {
              scenario() {
                return scenario;
              },
            },
          });
        };
      },
    ])

    .controller('ViewScenarioController', [
      'scenario',
      function ViewScenarioController(scenario) {
        const viewVm = this;

        viewVm.scenario = scenario;
      },
    ])

    .controller('EditScenarioController', [
      'STEPS',
      'ROLES',
      '$state',
      '$translate',
      '$uibModal',
      'scenario',
      'api',
      'currentUser',
      'notifications',
      function EditScenarioController(STEPS, ROLES, $state, $translate, $uibModal, scenario, api, currentUser, notifications) {
        const editVm = this;

        editVm.scenario = scenario;
        editVm.STEPS = STEPS;
        editVm.editAllowed = currentUser.roles.indexOf(ROLES.PRICING_ADMIN) >= 0;

        editVm.addScenarioStepModal = (scenarioParam) => {
          const stepModal = $uibModal.open({
            templateUrl: '/scenarios/step_modal.html',
            controller: 'AddScenarioStepController',
            controllerAs: 'stepVm',
            size: 'lg',
            bindToController: true,
            resolve: {
              scenario() {
                return scenarioParam;
              },
              editAllowed() {
                return editVm.editAllowed;
              },
              stepsTypes: [
                function () {
                  return api.call({
                    method: 'GET',
                    url: '/steps-types',
                  }).then((res) => res.data);
                },
              ],
            },
          });
          stepModal.result.then(async () => {
            editVm.scenario.steps = (await api
              .call({
                method: 'GET',
                url: `/scenarios/${editVm.scenario.gid}`,
                params: {
                  fields: 'steps',
                  locale: 'fr_FR',
                },
              })).data.steps
            ;
          });
        };

        editVm.editScenarioStepModal = (scenarioParam, stepParam) => {
          $uibModal.open({
            templateUrl: '/scenarios/step_modal.html',
            controller: 'EditScenarioStepController',
            controllerAs: 'stepVm',
            size: 'lg',
            bindToController: true,
            resolve: {
              scenario() {
                return scenarioParam;
              },
              step() {
                return stepParam;
              },
              editAllowed() {
                return editVm.editAllowed;
              },
              stepsTypes: [
                function () {
                  return api.call({
                    method: 'GET',
                    url: '/steps-types',
                  }).then((res) => res.data);
                },
              ],
            },
          });
        };

        editVm.deleteStepModal = (scenarioParam, stepParam) => {
          $uibModal.open({
            templateUrl: '/scenarios/delete_step_modal.html',
            controller: 'DeleteScenarioStepController',
            controllerAs: 'stepVm',
            bindToController: true,
            resolve: {
              scenario() {
                return scenarioParam;
              },
              step() {
                return stepParam;
              },
            },
          });
        };

        editVm.toggleEnabledStep = (stepParam) => {
          const stepEdited = {
            gid: stepParam.gid,
            enabled: !stepParam.enabled,
            locale: 'fr_FR',
          };
          api
            .call({
              method: 'PUT',
              url: `/steps/${stepParam.gid}`,
              params: {
                locale: 'fr_FR',
              },
              data: stepEdited,
            })
            .success(() => {
              const currentStepsIndex = _.findLastIndex(
                editVm.scenario.steps, { gid: stepParam.gid },
              );
              notifications.showSuccess({
                message: $translate.instant('steps.notifications.success.edit'),
              });
              editVm.scenario.steps[currentStepsIndex].enabled = stepEdited.enabled;
            })
            .error((data) => {
              throw new Error(data);
            })
          ;
        };

        editVm.toggleRequiredStep = (stepParam) => {
          const stepEdited = {
            definition: {
              required: stepParam.definition.required,
            },
          };
          api
            .call({
              method: 'PUT',
              url: `/steps/${stepParam.gid}`,
              params: {
                allLocales: true,
                locale: 'fr_FR',
              },
              data: stepEdited,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('steps.notifications.success.edit'),
              });
            })
            .error((data) => {
              throw new Error(data);
            })
          ;
        };

        editVm.canStepMoveUp = (step, stepIndex) => stepIndex > 1 && STEPS.TYPE.PRODUCT !== step.type;

        editVm.canStepMoveDown = (step, stepIndex) => (stepIndex + 1) < editVm.scenario.steps.length && STEPS.TYPE.PRODUCT !== step.type;

        editVm.moveStepUp = async (step, stepIndex) => {
          if (!editVm.canStepMoveUp(step, stepIndex)) { // todo or previous step is unmmutable
            return;
          }

          await api
            .call({
              method: 'PUT',
              url: `/scenarios/${editVm.scenario.gid}/steps/${step.gid}/priority`,
              params: {
                'locale': 'fr_FR',
              },
              data: {
                priority: step.priority - 1,
              },
            })
          ;

          editVm.scenario.steps = (await api
            .call({
              method: 'GET',
              url: `/scenarios/${editVm.scenario.gid}`,
              params: {
                fields: 'steps',
              },
            })).data.steps
          ;
        };

        editVm.moveStepDown = async (step, stepIndex) => {
          if (!editVm.canStepMoveDown(step, stepIndex)) { // todo or previous step is unmmutable
            return;
          }

          await api
            .call({
              method: 'PUT',
              url: `/scenarios/${editVm.scenario.gid}/steps/${step.gid}/priority`,
              params: {
                'locale': 'fr_FR',
              },
              data: {
                priority: step.priority + 1,
              },
            })
          ;

          editVm.scenario.steps = (await api
            .call({
              method: 'GET',
              url: `/scenarios/${editVm.scenario.gid}`,
              params: {
                fields: 'steps',
              },
            })).data.steps
          ;
        };

        editVm.submit = () => {
          if (editVm.form.$submitted && editVm.form.$valid) {
            api
              .call({
                method: 'PUT',
                url: `/scenarios/${editVm.scenario.gid}`,
                data: {
                  name: editVm.scenario.name,
                },
              })
              .success(() => {
                notifications.showSuccess({
                  message: $translate.instant('scenario.notifications.success.edit'),
                });
                $state.go('scenarios', { index: 1 });
              })
              .error((data) => {
                throw new Error(data);
              })
            ;
          }
        };
      },
    ])

    .controller('AddScenarioStepController', [
      'STEPS',
      '$translate',
      'api',
      'scenario',
      'notifications',
      'stepsTypes',
      'stepService',
      'editAllowed',
      function AddScenarioStepController(
        STEPS, $translate, api, scenario, notifications, stepsTypes, stepService, editAllowed
      ) {
        const stepVm = this;
        stepVm.STEPS = STEPS;
        stepVm.stepTypes = [];
        stepVm.step = <Step> { custom: true, locale: 'fr_FR' };
        stepVm.stepService = stepService;
        stepVm.stepService.initialize(stepVm.step);
        stepVm.uploader = stepService.getUploader();
        stepVm.editAllowed = editAllowed;

        stepVm.descriptionImageValidate = {
          width: { min: 410 },
          height: { min: 160 },
          pattern: '.jpg,.gif,.jpeg,.png,.svg',
          accept: 'image/jpg,image/jpeg,image/png,image/gif,image/svg',
          size: { max: '2MB' },
        };
        stepVm.descriptionUploadersErrors = {};

        stepVm.radioVideoValidate = {
          pattern: '.avi,.mp4,.mov,.webm',
          accept: 'video/avi,video/mp4,video/mov,video/webm',
          size: { max: '4GB' },
        };
        stepVm.radioVideoUploadersErrors = {};

        stepsTypes.forEach((type) => {
          stepVm.stepTypes.push({
            value: type,
            name: $translate.instant(`steps.type.${type}`),
          });
        });

        stepVm.onChangeStepType = () => {
          stepVm.step.definition = {};
          stepVm.stepService.initialize(stepVm.step);
        };

        stepVm.enableSpecificRequest = (index: number) => {
          stepVm.stepService.getDefinitionOptions()[index].product = null;
        };

        stepVm.submit = async () => {
          if (stepVm.form.$valid) {
            if (
              STEPS.TYPE.SELECT === stepVm.step.type ||
              STEPS.TYPE.RADIO === stepVm.step.type ||
              STEPS.TYPE.CHECKBOX === stepVm.step.type
            ) {
              stepVm.step.definition.options = stepVm.stepService.getDefinitionOptions();
            }

            if (STEPS.TYPE.NUMBER === stepVm.step.type) {
              if (stepVm.stepService.getThresholds(STEPS.THRESHOLD.SALE).length) {
                stepVm.step.definition.thresholds = stepVm.stepService.getThresholds();
              }
              if (stepVm.stepService.getThresholds(STEPS.THRESHOLD.PURCHASE).length) {
                stepVm.step.definition.purchase.thresholds = stepVm.stepService.getThresholds(STEPS.THRESHOLD.PURCHASE);
              }

              if (STEPS.OPERATOR.MULTIPLICATION === stepVm.step.definition.operator) {
                delete stepVm.step.definition.amount;
              }
              if (STEPS.OPERATOR.ADDITION === stepVm.step.definition.operator) {
                delete stepVm.step.definition.multiplier;
              }
            }
            const stepEdited = _.pick(stepVm.step, 'title', 'headline', 'gid', 'type', 'definition', 'files', 'quote_line', 'description', 'description_image', 'locale');

            await api
              .call({
                method: 'POST',
                url: `/scenarios/${scenario.gid}/steps`,
                data: stepEdited,
                params: {
                  fields: 'gid,title,headline,definition,type,custom,files,quote_line,description,description_image',
                  locale: 'fr_FR',
                  allLocales: true,
                },
              })
            ;
            stepVm.$close();
            notifications.showSuccess({
              message: $translate.instant('steps.notifications.success.add'),
            });
          }
        };
      },
    ])

    .controller('EditScenarioStepController', [
      'STEPS',
      '$translate',
      'api',
      'scenario',
      'step',
      'notifications',
      'stepsTypes',
      'stepService',
      'editAllowed',
      function EditScenarioStepController(
        STEPS, $translate, api, scenario, step, notifications, stepsTypes, stepService, editAllowed,
      ) {
        const stepVm = this;
        stepVm.STEPS = STEPS;
        stepVm.stepTypes = [];
        stepVm.step = <Step> step;
        stepVm.stepService = stepService;
        stepVm.stepService.initialize(stepVm.step);
        stepVm.uploader = stepService.getUploader();
        stepVm.editAllowed = editAllowed;

        stepVm.radioImageValidate = {
          width: { min: 430 },
          height: { min: 160 },
          pattern: '.jpg,.gif,.jpeg,.png',
          accept: 'image/jpg,image/jpeg,image/png,image/gif',
          size: { max: '2MB' },
        };
        stepVm.radioUploadersErrors = {};

        stepVm.radioVideoValidate = {
          pattern: '.avi,.mp4,.mov,.webm',
          accept: 'video/avi,video/mp4,video/mov,video/webm',
          size: { max: '4GB' },
        };
        stepVm.radioVideoUploadersErrors = {};

        stepVm.enableSpecificRequest = (index) => {
          stepVm.stepService.getDefinitionOptions()[index].product = null;
        };

        stepVm.descriptionImageValidate = {
          width: { min: 410 },
          height: { min: 160 },
          pattern: '.jpg,.gif,.jpeg,.png,.svg',
          accept: 'image/jpg,image/jpeg,image/png,image/gif,image/svg',
          size: { max: '2MB' },
        };

        stepVm.descriptionUploadersErrors = {};

        stepVm.onChangeStepType = () => {
          stepVm.stepService.initialize(stepVm.step);
        };

        stepsTypes.forEach((type) => {
          stepVm.stepTypes.push({
            value: type,
            name: $translate.instant(`steps.type.${type}`),
          });
        });

        if ((!stepVm.step.description || !stepVm.step.description.length) && Array.isArray(stepVm.step.description)) {
          stepVm.step.description = {};
        }

        if (stepVm.step.description_image) {
          stepVm.step.description_image.variations = {};
        }

        stepVm.submit = () => {
          if (stepVm.form.$valid) {
            const stepEdited = _.pick(stepVm.step, 'title', 'headline', 'gid', 'type', 'definition', 'files', 'quote_line', 'description', 'description_image', 'locale');
            const currentStepsIndex = _.findLastIndex(scenario.steps, { gid: step.gid });

            if (STEPS.TYPE.NUMBER === stepVm.step.type) {
              if (stepVm.stepService.getThresholds(STEPS.THRESHOLD.SALE).length) {
                stepVm.step.definition.thresholds = stepVm.stepService.getThresholds();
              }
              if (stepVm.stepService.getThresholds(STEPS.THRESHOLD.PURCHASE).length) {
                stepVm.step.definition.purchase.thresholds = stepVm.stepService.getThresholds(STEPS.THRESHOLD.PURCHASE);
              }
              if (STEPS.OPERATOR.MULTIPLICATION === stepVm.step.definition.operator) {
                delete stepVm.step.definition.amount;
              }
              if (STEPS.OPERATOR.ADDITION === stepVm.step.definition.operator) {
                delete stepVm.step.definition.multiplier;
              }
            }

            api
              .call({
                method: 'PUT',
                url: `/steps/${stepEdited.gid}`,
                data: stepEdited,
              })
              .success(() => {
                stepVm.$close();
                api
                  .call({
                    method: 'GET',
                    url: `/steps/${step.gid}`,
                    params: {
                      fields: 'gid,title,headline,type,custom,definition,enabled,quote_line,description,description_image,files',
                      locale: 'fr_FR',
                    },
                  })
                  .success((data) => {
                    scenario.steps[currentStepsIndex] = data;
                    notifications.showSuccess({
                      message: $translate.instant('steps.notifications.success.edit'),
                    });
                  })
                  .error((data) => {
                    throw new Error(data);
                  })
                ;
              })
              .error((data) => {
                throw new Error(data);
              })
            ;
          }
        };
      },
    ])

    .controller('TranslateScenarioStepController', [
      'ROLES',
      'STEPS',
      '$translate',
      'api',
      'currentUser',
      'localizedStep',
      'notifications',
      'scenario',
      'step',
      'stepService',
      'translationAvailableLocales',
      function TranslateScenarioStepController(
        ROLES,
        STEPS,
        $translate,
        api,
        currentUser,
        localizedStep,
        notifications,
        scenario,
        step,
        stepService,
        translationAvailableLocales,
      ) {
        const stepVm = this;
        stepVm.editAllowed = currentUser.roles.indexOf(ROLES.PRICING_ADMIN) >= 0;
        stepVm.localizedStep = <Step>localizedStep;
        stepVm.scenario = scenario;
        stepVm.step = <Step>step;
        stepVm.stepService = stepService;
        stepVm.translationAvailableLocales = translationAvailableLocales;

        stepVm.stepService.initialize(stepVm.localizedStep, stepVm.step);
        stepVm.uploader = stepService.getUploader();

        stepVm.radioImageValidate = {
          width: { min: 430 },
          height: { min: 160 },
          pattern: '.jpg,.gif,.jpeg,.png',
          accept: 'image/jpg,image/jpeg,image/png,image/gif',
          size: { max: '2MB' },
        };
        stepVm.radioUploadersErrors = {};

        stepVm.radioVideoValidate = {
          pattern: '.avi,.mp4,.mov,.webm',
          accept: 'video/avi,video/mp4,video/mov,video/webm',
          size: { max: '4GB' },
        };
        stepVm.radioVideoUploadersErrors = {};

        stepVm.enableSpecificRequest = (index) => {
          stepVm.stepService.getDefinitionOptions()[index].product = null;
        };

        stepVm.descriptionImageValidate = {
          width: { min: 410 },
          height: { min: 160 },
          pattern: '.jpg,.gif,.jpeg,.png,.svg',
          accept: 'image/jpg,image/jpeg,image/png,image/gif,image/svg',
          size: { max: '2MB' },
        };

        stepVm.descriptionUploadersErrors = {};

        if ((!stepVm.step.description || !stepVm.step.description.length) && Array.isArray(stepVm.step.description)) {
          stepVm.step.description = {};
        }

        stepVm.changeLocale = async () => {
          stepVm.localizedStep = (await api
            .call({
              method: 'GET',
              url: `/steps/${stepVm.localizedStep.gid}`,
              params: {
                fields: 'type,locale,title,headline,description,quote_line,definition,files',
                locale: stepVm.localizedStep.locale,
              },
            })
            .then((response) => response.data)
          );
          stepVm.stepService.initialize(stepVm.localizedStep, stepVm.step);
        };

        stepVm.submit = () => {
          if (stepVm.form.$valid) {
            const localizedStepEdited = _.pick(stepVm.localizedStep, 'gid', 'title', 'headline', 'definition', 'quote_line', 'description', 'locale');

            if (STEPS.TYPE.NUMBER === stepVm.step.type) {
              if (stepVm.stepService.getThresholds(STEPS.THRESHOLD.SALE).length) {
                stepVm.step.definition.thresholds = stepVm.stepService.getThresholds();
              }

              if (stepVm.stepService.getThresholds(STEPS.THRESHOLD.PURCHASE).length) {
                stepVm.step.definition.purchase.thresholds = stepVm.stepService.getThresholds(STEPS.THRESHOLD.PURCHASE);
              }

              if (STEPS.OPERATOR.MULTIPLICATION === stepVm.step.definition.operator) {
                delete stepVm.step.definition.amount;
              }

              if (STEPS.OPERATOR.ADDITION === stepVm.step.definition.operator) {
                delete stepVm.step.definition.multiplier;
              }
            }

            api
              .call({
                method: 'PUT',
                url: `/steps/${localizedStepEdited.gid}`,
                data: localizedStepEdited,
              })
              .success(() => {
                notifications.showSuccess({
                  message: $translate.instant('steps.notifications.success.translate'),
                });
              })
              .error((data) => {
                throw new Error(data);
              })
              ;
          }
        };
      },
    ])

    .controller('DeleteScenarioStepController', [
      '$translate',
      '$state',
      'api',
      'scenario',
      'step',
      'notifications',
      function DeleteScenarioStepController(
        $translate, $state, api, scenario, step, notifications,
      ) {
        const stepVm = this;
        const currentStepsIndex = _.findLastIndex(scenario.steps, { gid: step.gid });

        stepVm.submit = () => {
          api.call({
            method: 'DELETE',
            url: `/scenarios/${scenario.gid}/steps/${step.gid}`,
          }).success(() => {
            stepVm.$close();
            scenario.steps.splice(currentStepsIndex, 1);
            notifications.showSuccess({
              message: $translate.instant('steps.notifications.success.delete'),
            });
          }).error((data) => {
            throw new Error(data);
          });
        };
      },
    ])

    .controller('DeleteScenarioController', [
      '$state',
      '$translate',
      'api',
      'scenario',
      'notifications',
      function DeleteScenarioController($state, $translate, api, scenario, notifications) {
        const deleteVm = this;

        deleteVm.scenario = scenario;

        deleteVm.deleteScenario = () => {
          api
            .call({
              method: 'DELETE',
              url: `/scenarios/${deleteVm.scenario.gid}`,
            })
            .success(() => {
              deleteVm.$close();
              notifications.showSuccess({
                message: $translate.instant('scenario.notifications.success.delete'),
              });
              $state.go('scenarios', { index: 1 }, { reload: true });
            })
            .error(() => {
              notifications.showError({
                message: $translate.instant('scenario.notifications.error.delete'),
              });
            })
          ;
        };
      },
    ])
  ;
}());

(function () {
  angular
    .module('partners-bo.projects', [
    ])
    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider

          // project list
          .state('projects', {
            parent: 'root',
            url: '/projects/{index:int}?{limit:int}&{search:string}&{o:string}&{st:string}',
            templateUrl: 'projects/projects.html',
            controller: 'ListProjectsController',
            controllerAs: 'vm',
            reloadOnSearch: true,
            params: {
              index: 1,
              search: null,
              st: null,
            },
            resolve: [
              {
                token: 'orgsArray',
                deps: ['$state', '$stateParams', 'ipCookie'],
                resolveFn($state, $stateParams, ipCookie) {
                  let orgs = null;

                  // load parameters saved in cookie if possible
                  const orgsFilter = ipCookie('projectsOrganizationFilter');
                  let orgsArray = [];

                  if ('string' === typeof $stateParams.o) {
                    // if parameters is set, store it in a cookie
                    ipCookie('projectsOrganizationFilter', $stateParams.o, { expires: 365, expirationUnit: 'days' });
                    orgs = String($stateParams.o);
                  } else if (orgsFilter) {
                    orgs = String(orgsFilter);
                    $state.transitionTo('projects', Object.assign($state.params, { o: orgs }), { reload: false });
                  }

                  if (orgs) {
                    orgsArray = orgs.split(',');
                  }

                  return orgsArray;
                },
              },
              {
                token: 'organizations',
                deps: ['api', 'orgsArray'],
                resolveFn(api, orgsArray) {
                  if (orgsArray.length) {
                    return api.call({
                      method: 'GET',
                      url: '/organizations',
                      params: {
                        query: JSON.stringify(['gid', 'in', orgsArray]),
                        fields: 'name,gid',
                      },
                    }).then((res) => res.data);
                  }

                  return { total_count: 0, items: [] };
                },
              },
              {
                token: 'projects',
                deps: ['PROJECT', '$stateParams', 'api', 'paginator', 'orgsArray'],
                resolveFn(PROJECT, $stateParams, api, paginator, orgsArray) {
                  const query: ConditionQuery = [['@instanceof', '==', 'project']];

                  if (orgsArray.length) {
                    query.push(['organization.gid', 'in', orgsArray]);
                  }

                  if ('string' === typeof $stateParams.search) {
                    query.push(['OR', [['title', '~=', $stateParams.search], ['short_code', '~=', $stateParams.search]]]);
                  }

                  const allowedStates = [
                    PROJECT.STATE.DRAFT,
                    PROJECT.STATE.CANCELED,
                    PROJECT.STATE.PUBLISHED,
                    PROJECT.STATE.TO_PUBLISH,
                    PROJECT.STATE.REQUIRE_APPROVAL,
                    PROJECT.STATE.WAITING_FOR_PROPOSAL,
                    PROJECT.STATE.PROPOSAL,
                    PROJECT.STATE.ARCHIVED,
                  ];

                  if ($stateParams.st && allowedStates.indexOf($stateParams.st) !== -1) {
                    if (PROJECT.STATE.TO_PUBLISH === $stateParams.st) {
                      query.push(['AND', [['started_at', '==', null], ['state', '==', PROJECT.STATE.PUBLISHED]]]);
                    } else if (PROJECT.STATE.PUBLISHED === $stateParams.st) {
                      query.push(['AND', [['started_at', '!=', null], ['state', '==', PROJECT.STATE.PUBLISHED]]]);
                    } else {
                      query.push(['state', '==', $stateParams.st]);
                    }
                  }

                  paginator.setMaxItemPage($stateParams.limit);
                  return api.call({
                    method: 'GET',
                    url: '/projects',
                    params: {
                      query: query.length ? JSON.stringify(query) : null,
                      limit: paginator.getMaxItemPage(),
                      offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                      orderBy: 'created_at:desc',
                      fields: 'canceled_at,custom_short_code,deadline_at,finished_at,owner,organization,price,product,title,status,state,href,gid,is_cancelable,short_code,published_at,started_at,options,specific_request,sageone_invoice_url,states,estimation_requested,project_manager,assigned_workers',
                    },
                  }).then((res) => res.data);
                },
              },
              {
                token: 'statsBatch',
                policy: { when: 'LAZY', async: 'NOWAIT' },
                deps: ['api', 'projects'],
                resolveFn(api, projects) {
                  if (!projects.items.length) {
                    return;
                  }
                  const batchRequest = [];

                  angular.forEach(projects.items, (p) => {
                    batchRequest.push({
                      method: 'GET',
                      relative_url: `/v1/projects/${p.gid}/stat`,
                    });
                  });

                  return api.call({
                    method: 'POST',
                    url: '/batch',
                    data: batchRequest,
                  }).then((result) => {
                    const data = _.pluck(result.data, 'body');

                    angular.forEach(data, (element, key) => {
                      element = JSON.parse(element);
                      projects.items[key].stats = element;
                    });
                  });
                },
              },
            ],
          })

          .state('project-edit', {
            parent: 'root',
            url: '/projects/{gid:[0-9a-fA-F]+}',
            templateUrl: 'projects/edit.html',
            controller: 'EditProjectController',
            controllerAs: 'vm',
            resolve: {
              project: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/projects/${$stateParams.gid}`,
                    params: {
                      fields: 'gid,title,state,status,metadata',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('project-customize', {
            parent: 'root',
            url: '/projects/{gid:[0-9a-fA-F]+}/customize',
            templateUrl: 'projects/customize.html',
            controller: 'CustomizeProjectController',
            controllerAs: 'vm',
            resolve: [
              {
                token: 'project',
                deps: ['$stateParams', 'api'],
                resolveFn($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/projects/${$stateParams.gid}`,
                    params: {
                      fields: 'brief_files,organization,owner,title,product,quantity,options,price,gid,href,state,proposal_description,modified_at,description,expires_at,estimation_requested,scenario,steps,specific_request',
                    },
                  }).then((res) => res.data);
                },
              },
              {
                token: 'specificRequestScenario',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/products',
                    params: {
                      limit: 1,
                      fields: 'specific_request_scenario',
                    },
                  }).then((res) => res.data.items[0].specific_request_scenario);
                },
              },
              {
                token: 'product',
                deps: ['api', 'project'],
                resolveFn(api, project) {
                  if (!project.product) {
                    return null;
                  }
                  return api.call({
                    method: 'GET',
                    url: `/orgs/${project.organization.gid}/products`,
                    params: {
                      fields: 'title,is_priced,additional_meta,service_details,available_options,icon,scenario,is_cumulative,custom_data',
                      query: JSON.stringify(['gid', '==', project.product.gid]),
                      limit: 1,
                      locale: 'fr_FR',
                    }
                  }).then((res) => res.data.items[0]);
                },
              },
              {
                token: 'defaultBriefDescription',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/admin/settings/project.specific_request.default_description',
                  }).then((res) => res.data);
                },
              },
            ],
            onEnter: [
              '$state',
              '$translate',
              'project',
              'notifications',
              'PROJECT',
              function ($state, $translate, project, notifications, PROJECT) {
                if (project.state !== PROJECT.STATE.WAITING_FOR_PROPOSAL && project.state !== PROJECT.STATE.PROPOSAL && (project.state !== PROJECT.STATE.DRAFT || !project.specific_request)) {
                  notifications.showError({
                    message: $translate.instant('project.customize.edit_proposal'),
                  });
                  $state.go('projects', { index: 1 });
                }
              },
            ],
          })

          .state('project-new', {
            parent: 'root',
            url: '/projects/new',
            templateUrl: 'projects/new.html',
            controller: 'NewProjectController',
            controllerAs: 'vm',
            resolve: [
              {
                token: 'defaultBriefDescription',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/admin/settings/project.specific_request.default_description',
                  }).then((res) => res.data);
                },
              },
            ],
          })
        ;

        $urlRouterProvider
          .when('/projects', '/projects/1')
        ;
      },
    ])

    .controller('ListProjectsController', [
      'PROJECT',
      '$uibModal',
      '$translate',
      '$state',
      '$stateParams',
      '$q',
      'ipCookie',
      'notifications',
      'api',
      'partnersLink',
      'creadsLink',
      'projects',
      'organizations',
      function ListProjectsController(PROJECT, $uibModal, $translate, $state, $stateParams, $q, ipCookie, notifications, api, partnersLink, creadsLink, projects, organizations) {
        const vm = this;

        vm.PROJECT = PROJECT;
        vm.projects = projects.items;
        vm.totalCount = projects.total_count;
        vm.showShortCode = true;
        vm.organizations = organizations.items;

        vm.links = {
          partners: partnersLink,
          creads: creadsLink,
        };

        vm.states = [
          { id: 'all', label: $translate.instant('project.status.all') },
          { id: PROJECT.STATE.DRAFT, label: $translate.instant(`project.state.${PROJECT.STATE.DRAFT}`) },
          { id: PROJECT.STATE.CANCELED, label: $translate.instant(`project.state.${PROJECT.STATE.CANCELED}`) },
          { id: PROJECT.STATE.PUBLISHED, label: $translate.instant(`project.state.${PROJECT.STATE.PUBLISHED}`) },
          { id: PROJECT.STATE.TO_PUBLISH, label: $translate.instant(`project.state.${PROJECT.STATE.TO_PUBLISH}`) },
          { id: PROJECT.STATE.REQUIRE_APPROVAL, label: $translate.instant(`project.state.${PROJECT.STATE.REQUIRE_APPROVAL}`) },
          { id: PROJECT.STATE.WAITING_FOR_PROPOSAL, label: $translate.instant(`project.state.${PROJECT.STATE.WAITING_FOR_PROPOSAL}`) },
          { id: PROJECT.STATE.PROPOSAL, label: $translate.instant(`project.state.${PROJECT.STATE.PROPOSAL}`) },
          { id: PROJECT.STATE.ARCHIVED, label: $translate.instant(`project.state.${PROJECT.STATE.ARCHIVED}`) },
        ];

        vm.currentState = ($stateParams.st) ? _.find(vm.states, { id: $stateParams.st }) : vm.states[0];

        vm.waitingForProposalOnly = false;

        if ($stateParams.st && PROJECT.STATE.WAITING_FOR_PROPOSAL === $stateParams.st) {
          vm.waitingForProposalOnly = true;
        }

        vm.refreshStatusFilter = function refreshStatusFilter() {
          $state.go($state.current.name, { st: vm.waitingForProposalOnly ? PROJECT.STATE.WAITING_FOR_PROPOSAL : null });
        };

        const projectOwnerGids = [];
        vm.ownersMap = {};

        // compute timeleft
        angular.forEach(projects.items, (project) => {
          if (projectOwnerGids.indexOf(project.owner.gid) < 0) {
            projectOwnerGids.push(project.owner.gid);
          }

          if (project.deadline_at) {
            const now: Date = new Date();
            project.timeleft = <any>new Date(project.deadline_at) - <any>now;
          } else {
            project.timeleft = null;
          }
        });

        vm.updateOrganizationFilter = function updateOrganizationFilter() {
          const orgs = _.pluck(vm.organizations, 'gid');
          ipCookie('projectsOrganizationFilter', orgs.join(','), { expires: 365, expirationUnit: 'days' });
          $state.go($state.current.name, { o: orgs.join(',') });
        };

        vm.onChangeState = () => {
          const newStateParams = angular.copy($stateParams);
          newStateParams.st = vm.currentState.id;
          $state.go($state.current.name, newStateParams);
        };

        vm.loadOrganizationTags = function loadOrganizationTags(query) {
          const deferred = $q.defer();
          api
            .call({
              url: '/organizations',
              method: 'GET',
              ignoreLoadingBar: true,
              params: {
                search: query,
                fields: 'name',
              },
            })
            .success((res) => {
              deferred.resolve(res.items);
            })
            .error(() => {
              deferred.reject();
            });
          return deferred.promise;
        };

        vm.popEditOwnerModal = function popEditOwnerModal(project) {
          $uibModal.open({
            templateUrl: '/projects/project_owner_modal.html',
            controller: 'EditProjectOwnerController',
            controllerAs: '$projectOwnerVm',
            bindToController: true,
            resolve: {
              project() {
                return project;
              },
              currentOwner() {
                return api
                  .call({
                    url: `/users/${project.owner.gid}`,
                    method: 'GET',
                    params: {
                      fields: 'gid,email,display_full_name',
                    },
                  })
                  .then((res) => res.data);
              },
            },
          });
        };

        vm.getLabelClassFromProject = function getLabelClassFromProject(project) {
          let label = 'label-disabled';
          if (project.state === PROJECT.STATE.CANCELED || (project.state === PROJECT.STATE.PUBLISHED && !project.started_at)) {
            label = 'label-danger';
          } else if (project.state === PROJECT.STATE.DRAFT || project.state === PROJECT.STATE.WAITING_FOR_PROPOSAL || project.state === PROJECT.STATE.PROPOSAL) {
            label = 'label-default';
          } else if (PROJECT.STATE.REQUIRE_APPROVAL === project.state) {
            label = 'label-info';
          } else if (project.state === PROJECT.STATE.PUBLISHED) {
            if (project.status === PROJECT.STATUS.WAITING_FOR_DECISION) {
              label = 'label-warning';
            } else if (project.status === PROJECT.STATUS.FINISHED || project.status === PROJECT.STATUS.WAITING_FOR_SOURCES) {
              label = 'label-info';
            } else if (project.status === PROJECT.STATUS.IN_PROGRESS) {
              label = 'label-primary';
            }
          }

          return label;
        };

        vm.getLabelTextFromProject = function getLabelTextFromProject(project) {
          if (project.state === vm.PROJECT.STATE.PUBLISHED) {
            if (!project.started_at) {
              return $translate.instant('À publier');
            }
            return $translate.instant(`project.status.${project.status}`);
          }
          return $translate.instant(`project.state.${project.state}`);
        };

        api
          .call({
            url: '/users',
            method: 'GET',
            params: {
              fields: 'email,phone,href,gid',
              query: JSON.stringify(['gid', 'in', projectOwnerGids]),
            },
          })
          .success((res) => {
            vm.ownersMap = _.indexBy(res.items, 'gid');
          });

        vm.now = moment();

        vm.projectNeedsWorks = function projectNeedsWorks(project, threshold) {
          if (!project.published_at) {
            return false;
          }
          threshold = threshold || 0.8;
          const start = moment(project.published_at);
          const deadlineAt = moment(project.deadline_at);
          let proposalCount = 0;
          if (project.stats) {
            if (!project.stats.works_count.total && project.stats.messages_count.total) {
              proposalCount = project.stats.messages_count.deliveries - project.stats.messages_count.deliveries_with_attachments + project.stats.messages_count.with_attachments;
            } else if (project.stats.works_count.total && !project.stats.messages_count.total) {
              proposalCount = project.stats.works_count.total;
            }
          }

          project.proposalCount = proposalCount;


          // Returns true if project approaches deadline (above threshold % of project length) but still no works have been posted
          if (!proposalCount && ((vm.now - start) / (deadlineAt - start)) >= threshold) {
            return true;
          }
          return false;
        };

        vm.cancelProject = function cancelProject(project) {
          api
            .call({
              method: 'DELETE',
              url: `/projects/${project.gid}`,
            }).success(() => {
              notifications.showSuccess({
                message: $translate.instant('Le projet a été annulé.'),
              });
              project.status = PROJECT.STATUS.CANCELED;
              project.state = PROJECT.STATE.CANCELED;
            }).error(() => {
              notifications.showError({
                message: $translate.instant('Le projet n\'a pas pu être annulé.'),
              });
            });
        };
      },
    ])

    .controller('EditProjectOwnerController', [
      '$scope',
      '$translate',
      'api',
      'notifications',
      'project',
      'currentOwner',
      function EditProjectOwnerController($scope, $translate, api, notifications, project, currentOwner) {
        const vm = this;

        vm.project = project;
        vm.currentUser = angular.copy(currentOwner);
        vm.newOwner = currentOwner;
        vm.users = [];

        vm.getMatchingUsers = function getMatchingUsers(searchQuery) {
          if ('' === searchQuery) {
            return;
          }
          return api.call({
            method: 'GET',
            url: `/orgs/${project.organization.gid}/users`,
            params: {
              search: searchQuery,
              fields: 'email,display_full_name',
            },
          }).then((response) => {
            vm.users = _.filter(response.data.items, (u) => u.gid !== vm.currentUser.gid);
            return vm.users;
          });
        };

        vm.submit = function submit() {
          if (vm.newOwner.gid === currentOwner.gid) {
            $scope.$close();
            return;
          }
          return api.call({
            method: 'PUT',
            url: `/projects/${project.gid}`,
            data: {
              owner: {
                gid: vm.newOwner.gid,
              },
            },
          }).then(() => {
            notifications.showSuccess({
              message: $translate.instant('projects.change_owner.success'),
            });
            project.owner = vm.newOwner;
            $scope.$close();
          }, () => {
            notifications.showError({
              message: $translate.instant('projects.change_owner.fail'),
            });
          });
        };
      },
    ])

    .controller('EditProjectController', [
      'PROJECT',
      '$translate',
      '$state',
      'api',
      'notifications',
      'project',
      function EditProjectController(PROJECT, $translate, $state, api, notifications, project) {
        const vm = this;

        vm.project = project;
        vm.customId = vm.project.metadata.custom_id || null;
        vm.PROJECT = PROJECT;

        vm.submit = function submit() {
          if (vm.form.$valid) {
            vm.project.metadata.custom_id = vm.customId;
            return api.call({
              method: 'PUT',
              url: `/projects/${project.gid}`,
              data: {
                title: vm.project.title,
                metadata: vm.project.metadata,
              },
            }).then(() => {
              notifications.showSuccess({
                message: $translate.instant('project.edit.success'),
              });
              $state.go('projects');
            }, () => {
              notifications.showError({
                message: $translate.instant('project.edit.fail'),
              });
            });
          }
        };

        vm.archive = function archive() {
          return api.call({
            method: 'PUT',
            url: `/projects/${project.gid}`,
            data: {
              state: PROJECT.STATE.ARCHIVED,
            },
          }).then(() => {
            notifications.showSuccess({
              message: $translate.instant('project.edit.success'),
            });
            $state.go('projects');
          }, () => {
            notifications.showError({
              message: $translate.instant('project.edit.fail'),
            });
          });
        };
      },
    ])

    .controller('CustomizeProjectController', [
      'PROJECT',
      '$filter',
      '$translate',
      '$state',
      'moment',
      'notifications',
      'api',
      'partnersLink',
      'productHelper',
      'project',
      'product',
      'defaultBriefDescription',
      'specificRequestScenario',
      function CustomizeProjectController(
        PROJECT,
        $filter,
        $translate,
        $state,
        moment,
        notifications,
        api,
        partnersLink,
        productHelper,
        project,
        product,
        defaultBriefDescription,
        specificRequestScenario,
      ) {
        const vm = this;

        vm.project = project;
        vm.partnersLink = partnersLink;
        vm.defaultBriefDescription = defaultBriefDescription.value.description;
        vm.projectDescription = vm.project.description || null;
        vm.PROJECT = PROJECT;

        vm.product = product || null;
        if (vm.project.scenario) {
          vm.scenario = vm.project.scenario;
        } else {
          vm.scenario = vm.product?.scenario || specificRequestScenario;
        }

        vm.oldProductScenario = null;

        vm.organization = vm.project.organization;
        vm.expirationDate = moment(vm.project.expires_at).toDate() || null;
        vm.ownersPagination = {
          offset: 10,
          limit: 10,
          max: 20,
          isLoading: false,
        };
        vm.productsPagination = {
          offset: 10,
          limit: 10,
          max: 20,
          isLoading: false,
        };

        const DEFAULT_AVAILABLE_OPTIONS = {
          mode: {
            enum: ['solo', 'multi'],
          },
          skill: {
            enum: ['conception', 'execution'],
          },
        };

        initializeSettings();

        vm.uploaderValidate = {
          pattern: '.jpg,.gif,.jpeg,.png,.svg',
          accept: 'image/jpg,image/jpeg,image/png,image/gif,image/svg',
          size: { max: '8MB' },
        };

        vm.uploadError = {};

        vm.getMatchingUsers = (search: String, triggeredBySearch: Boolean) => {
          if (triggeredBySearch) {
            vm.ownersPagination.offset = 0;
            vm.ownersPagination.limit = 30;
            vm.ownersPagination.max = 60;
          }

          if (!vm.organization
            || vm.ownersPagination.isLoading
            || vm.ownersPagination.max <= vm.ownersPagination.offset
          ) {
            return;
          }

          const params: ParamsQuery = {
            fields: 'gid,email,firstname,lastname',
            orderBy: search ? null : 'firstname',
            offset: vm.ownersPagination.offset,
            limit: vm.ownersPagination.limit,
            search: search || null,
          };

          vm.ownersPagination.isLoading = true;

          api.call({
            method: 'GET',
            url: `/orgs/${vm.organization.gid}/users`,
            params,
          }).then((res) => {
            vm.ownersPagination.isLoading = false;
            vm.users = triggeredBySearch ? res.data.items : vm.users.concat(res.data.items);
            vm.ownersPagination.offset += vm.ownersPagination.limit;
            vm.ownersPagination.max = res.data.total_count;
          });
        };

        vm.getMatchingProducts = (search: String, triggeredBySearch: Boolean) => {
          if (triggeredBySearch) {
            vm.productsPagination.offset = 0;
            vm.productsPagination.limit = 30;
            vm.productsPagination.max = 60;
          }

          if (!vm.organization
            || vm.productsPagination.isLoading
            || vm.productsPagination.max <= vm.productsPagination.offset
          ) {
            return;
          }

          const params: ParamsQuery = {
            fields: 'title,is_priced,additional_meta,service_details,available_options,icon,scenario,is_cumulative,custom_data',
            // orderBy: search ? null : 'title', // Todo with https://creads.atlassian.net/browse/CREADS-3140
            show_unpriced: 'true',
            offset: vm.productsPagination.offset,
            limit: vm.productsPagination.limit,
            locale: 'fr_FR',
            search: search || null,
          };

          vm.productsPagination.isLoading = true;

          api.call({
            method: 'GET',
            url: `/orgs/${vm.organization.gid}/products`,
            params,
          }).then((res) => {
            vm.productsPagination.isLoading = false;
            vm.products = triggeredBySearch ? res.data.items : vm.products.concat(res.data.items);
            vm.productsPagination.offset += vm.productsPagination.limit;
            vm.productsPagination.max = res.data.total_count;
          });
        };

        vm.applyServiceDetails = () => {
          vm.project.proposal_description = vm.product.service_details;
        };

        function initializeSettings() {
          vm.availableOptions = vm.product ? vm.product.available_options : DEFAULT_AVAILABLE_OPTIONS;
          vm.skillScenarioStep = _.first(vm.scenario.steps.filter((el) => ('skill' === el.type)));
          vm.modeScenarioStep = _.first(vm.scenario.steps.filter((el) => ('mode' === el.type)));
          vm.quantityScenarioStep = _.first(vm.scenario.steps.filter((el) => ('quantity' === el.type)));
          vm.descriptionScenarioStep = _.first(vm.scenario.steps.filter((el) => ('description' === el.type)));

          vm.skill = vm.project.options.skill || null;
          vm.mode = vm.project.options.mode || null;
          vm.price = vm.project.price || null;
          vm.quantity = vm.project.quantity || null;
          vm.description = vm.project.description || null;
        }

        function computePrice() {
          if (!vm.product || !vm.product.is_priced) {
            return;
          }

          vm.unitPrice = vm.priceMap[vm.skill][vm.mode]?.amount;

          if (!vm.unitPrice) {
            return;
          }

          let decreasingPrice = [];
          vm.decreasingPrice = 0;

          if (vm.product.custom_data && vm.product.custom_data.decreasing_prices) {
            const filteredPrices = vm.product.custom_data.decreasing_prices.filter(
              (array) => array.quantity <= vm.quantity,
            );

            decreasingPrice = filteredPrices.sort((a, b) => a.quantity - b.quantity);
          }

          if (decreasingPrice.length && (_.last(decreasingPrice)).discount) {
            vm.decreasingPrice = decreasingPrice.pop().discount;
          }

          let amount = vm.unitPrice * vm.quantity;

          if (vm.decreasingPrice) {
            amount -= ((amount * vm.decreasingPrice) / 100);
          }

          vm.price = { amount };
        }

        vm.productChanged = async (firstLoad = false) => {
          vm.project.steps = [];

          const scenarioStep = _.first(vm.scenario.steps.filter((el) => ('product' === el.type)));
          vm.project.steps.push({
            gid: scenarioStep.gid,
            data: vm.product?.gid || null
          });

          const res = (await api
            .call({
              url: `/projects/${vm.project.gid}`,
              method: firstLoad ? 'GET' : 'PUT',
              data: firstLoad ? null : {
                steps: vm.project.steps,
                price: null,
                state: PROJECT.STATE.WAITING_FOR_PROPOSAL
              },
              params: {
                fields: 'scenario,steps,additional_meta',
              }
            })
          ).data;

          vm.scenario = res.scenario;
          vm.project.steps = res.steps;

          if (vm.product && vm.product.is_priced) {
            vm.expirationDate = null;
            vm.applyServiceDetails();
            if ((vm.projectDescription === vm.defaultBriefDescription) && res.additional_meta) {
              vm.description = res.additional_meta.brief_template;
            }

            productHelper.getPricesMapFromProduct(vm.product, vm.project.organization).then((res) => {
              vm.priceMap = res;
              vm.skills = Object.keys(vm.priceMap);
              vm.skill = vm.project.options.skill || vm.skills[0];
              vm.skillChanged();
            });

            if (vm.project.product && (vm.product.gid !== vm.project.product.gid)) {
              vm.quantity = 1;
            }

          } else {
            if (vm.projectDescription === vm.defaultBriefDescription) {
              vm.description = vm.defaultBriefDescription;
            }
            vm.expirationDate = vm.project.expires_at ? moment(vm.project.expires_at).toDate() : moment().add(1, 'months').toDate();
            vm.skills = DEFAULT_AVAILABLE_OPTIONS.skill.enum;
            vm.modes = DEFAULT_AVAILABLE_OPTIONS.mode.enum;
            initializeSettings();
            vm.skillChanged();
            vm.quantity = 1;
          }
        }

        vm.skillChanged = () => {
          if (vm.product && vm.product.is_priced) {
            vm.modes = Object.keys(vm.priceMap[vm.skill]);
            vm.mode = vm.project.options.mode || vm.modes[0];
            vm.modeChanged();
          }
        };

        vm.modeChanged = () => {
          computePrice();
        };

        vm.quantityChanged = () => {
          if (vm.product && vm.product.is_priced) {
            if (vm.quantity > 0 && vm.quantity < 9999) {
              computePrice();
            } else {
              vm.price = null;
            }
          }
        };

        const tomorrow = moment().add(1, 'days').toDate();

        vm.expirationDatePickerOptions = {
          minDate: tomorrow,
        };

        vm.productChanged(true);

        function buildDataToSend() {
          if (!vm.form.$valid) {
            return;
          }

          const currentScenario = vm.scenario;

          vm.project.steps = [];

          const scenarioStep = _.first(currentScenario.steps.filter((el) => ('product' === el.type)));
          if (scenarioStep) {
            vm.project.steps.push({
              gid: scenarioStep.gid,
              data: vm.product?.gid || null
            });
          }

          vm.skillScenarioStep = _.first(currentScenario.steps.filter((el) => ('skill' === el.type)));
          if (vm.skillScenarioStep) {
            vm.project.steps.push({
              gid: vm.skillScenarioStep.gid,
              data: vm.skill || null
            });
          }

          vm.modeScenarioStep = _.first(currentScenario.steps.filter((el) => ('mode' === el.type)));
          if (vm.modeScenarioStep) {
            vm.project.steps.push({
              gid: vm.modeScenarioStep.gid,
              data: vm.mode || null
            });
          }

          if (vm.product && vm.product.is_cumulative) {
            vm.quantityScenarioStep = _.first(currentScenario.steps.filter((el) => ('quantity' === el.type)));
            if (vm.quantityScenarioStep) {
              vm.project.steps.push({
                gid: vm.quantityScenarioStep.gid,
                data: vm.quantity || null
              });
            }
          }

          vm.descriptionScenarioStep = _.first(currentScenario.steps.filter((el) => ('description' === el.type)));
          if (vm.descriptionScenarioStep) {
            vm.project.steps.push({
              gid: vm.descriptionScenarioStep.gid,
              data: vm.description || null
            });
          }

          const reqBody:{ expires_at?: any; owner: any; proposal_description: any; state?: any; steps: any; price?: any; estimation_requested?: any} = {
            owner: vm.project.owner,
            proposal_description: vm.project.proposal_description || null,
            steps: vm.project.steps
          };

          return reqBody;
        }

        vm.submit = () => {

          const reqBody = buildDataToSend();

          if (vm.product && vm.product.is_priced) {
            reqBody.state = PROJECT.STATE.DRAFT;
            reqBody.expires_at = null;
          } else {
            const expirationMoment = moment(vm.expirationDate).hour(0).minute(0).second(0);
            reqBody.state = PROJECT.STATE.PROPOSAL;
            reqBody.expires_at = expirationMoment.toISOString();
          }

          if (vm.product && vm.product.is_priced) {
            vm.project.proposal_description = null;
            reqBody.proposal_description = null;
          } else if (vm.price && vm.price.amount !== null) {
            reqBody.price = _.pick(vm.price, 'amount', 'currency');
          }

          api
            .call({
              method: 'PUT',
              url: `/projects/${vm.project.gid}`,
              data: reqBody,
            })
            .then((response) => {
              notifications.showSuccess({
                message: $translate.instant('project.customize.success', { email: vm.project.owner.email }),
              });
              const projectGid = response.data.gid;
              if (vm.project.brief_files) {
                api
                  .call({
                    method: 'POST',
                    url: `/projects/${projectGid}/brief_files`,
                    data: vm.project.brief_files,
                  });
              }
              $state.go('projects', { index: 1 });
            }, () => {
              notifications.showError({
                message: $translate.instant('common.error.action_fail'),
              });
            });
        };

        vm.requestEstimation = function requestEstimation() {

          const reqBody = buildDataToSend();

          reqBody.estimation_requested = true;

          return api.call({
            method: 'PUT',
            url: `/projects/${project.gid}`,
            data: reqBody,
          }).then((response) => {
            notifications.showSuccess({
              message: $translate.instant('project.customize.estimate.success'),
            });
            const projectGid = response.data.gid;
              if (vm.project.brief_files) {
                api
                  .call({
                    method: 'POST',
                    url: `/projects/${projectGid}/brief_files`,
                    data: vm.project.brief_files,
                  });
              }
            $state.go('projects');
          }, () => {
            notifications.showError({
              message: $translate.instant('project.edit.fail'),
            });
          });
        };
      },
    ])

    .controller('NewProjectController', [
      '$state',
      '$translate',
      'api',
      'notifications',
      'defaultBriefDescription',
      'partnersLink',
      function NewProjectController(
        $state,
        $translate,
        api,
        notifications,
        defaultBriefDescription,
        partnersLink,
      ) {
        const vm = this;

        vm.project = {
          description: $translate.instant('project.new.default_description'),
        };
        vm.defaultBriefDescription = defaultBriefDescription.value.description;
        vm.organization = null;
        vm.folder = null;
        vm.users = [];
        vm.folders = [];
        vm.project.brief_files = [];
        vm.projectFiles = [];

        vm.ownersPagination = {
          offset: 10,
          limit: 10,
          max: 20,
          isLoading: false,
        };

        vm.foldersPagination = {
          offset: 0,
          limit: 30,
          max: 60,
          isLoading: false,
        };
        vm.partnersLink = partnersLink;

        vm.fetchOrgsFromSearch = function fetchOrgsFromSearch(searchQuery: String) {
          if ('' === searchQuery) {
            return;
          }
          api.call({
            method: 'GET',
            url: '/organizations',
            params: {
              search: searchQuery,
              fields: 'gid,name',
            },
          }).then((response) => {
            vm.organizationsResults = response.data.items;
          });
        };

        vm.changeOrganization = () => {
          vm.getMatchingUsers(null, true);
          vm.getMatchingFolders(null, true);
        };

        vm.getMatchingUsers = (search: String, triggeredBySearch: Boolean) => {
          if (triggeredBySearch) {
            vm.ownersPagination.offset = 0;
            vm.ownersPagination.limit = 30;
            vm.ownersPagination.max = 60;
          }

          if (!vm.organization
            || vm.ownersPagination.isLoading
            || vm.ownersPagination.max <= vm.ownersPagination.offset
          ) {
            return;
          }

          const params:ParamsQuery = {
            fields: 'gid,email,firstname,lastname',
            orderBy: search ? null : 'firstname',
            offset: vm.ownersPagination.offset,
            limit: vm.ownersPagination.limit,
            search: search || null,
          };

          vm.ownersPagination.isLoading = true;

          api.call({
            method: 'GET',
            url: `/orgs/${vm.organization.gid}/users`,
            params,
          }).then((res) => {
            vm.ownersPagination.isLoading = false;
            vm.users = triggeredBySearch ? res.data.items : vm.users.concat(res.data.items);
            vm.ownersPagination.offset += vm.ownersPagination.limit;
            vm.ownersPagination.max = res.data.total_count;
          });
        };

        vm.getMatchingFolders = (search: String, triggeredBySearch: Boolean) => {
          if (triggeredBySearch) {
            vm.foldersPagination.offset = 0;
            vm.foldersPagination.max = 60;
          }

          if (!vm.organization
            || vm.foldersPagination.isLoading
            || vm.foldersPagination.max <= vm.foldersPagination.offset
          ) {
            return;
          }

          vm.foldersPagination.isLoading = true;

          const query = [['@instanceof', '==', 'folder'], ['organization.gid', '==', vm.organization.gid]];

          if (search) {
            query.push(['title', '~=', search]);
          }

          api.call({
            method: 'GET',
            url: '/folders',
            params: {
              fields: 'gid,title',
              orderBy: search ? null : 'title',
              offset: vm.foldersPagination.offset,
              limit: vm.foldersPagination.limit,
              query: JSON.stringify(query),
            },
          }).then((response) => {
            vm.foldersPagination.isLoading = false;
            vm.folders = triggeredBySearch
              ? response.data.items
              : vm.folders.concat(response.data.items)
            ;
            vm.foldersPagination.offset += vm.foldersPagination.limit;
            vm.foldersPagination.max = response.data.total_count;
          });
        };

        vm.onAddFiles = () => (file) => vm.project.brief_files.push(file);

        vm.onRemoveFiles = () => (file, callback) => {
          const indexToDelete = vm.project.brief_files.indexOf(file);
          vm.project.brief_files.splice(indexToDelete, 1);
          callback();
        };

        vm.submit = async function submit() {
          vm.steps = {};
          vm.steps.gid = (await api
            .call({
              method: 'GET',
              url: '/products',
              ignoreLoadingBar: true,
              params: {
                fields: 'specific_request_scenario',
                limit: 1,
              },
            }))
            .data
            .items[0]
            .specific_request_scenario
            .steps[0] // product step in scenario
            .gid;

          vm.steps.data = '';
          vm.project.steps = [];
          vm.project.steps.push(vm.steps);

          const projectToSend = {
            organization: {
              gid: vm.organization.gid,
            },
            owner: {
              gid: vm.user.gid,
            },
            title: vm.project.title,
            state: 'waiting_for_proposal',
            specific_request: true,
            description: vm.defaultBriefDescription,
            folder: vm.folder || null
          };

          if (vm.form.$valid) {
            api
              .call({
                method: 'POST',
                url: '/projects',
                data: projectToSend,
              })
              .success((response) => {
                notifications.showSuccess({
                  message: $translate.instant('projects.notifications.success'),
                });
                const projectGid = response.gid;

                if (vm.project.brief_files) {
                  api
                    .call({
                      method: 'POST',
                      url: `/projects/${projectGid}/brief_files`,
                      data: vm.project.brief_files,
                    });
                }
                $state.go('project-customize', { gid: projectGid });
              })
              .error((error) => {
                throw new Error(`Error${error}`);
              });
          }
        };
      },
    ])

    .controller('ViewProjectController', [
      'ROLES',
      'currentUser',
      'project',
      function ViewProjectController(ROLES, currentUser, project) {
        const projectVm = this;

        projectVm.project = project;
        projectVm.currentUser = currentUser;
        projectVm.ROLES = ROLES;

        if (currentUser.roles.indexOf(projectVm.ROLES.SALES_ADMIN) >= 0) {
          projectVm.salesStats = {
            billedAmount: project.sales_stats.billed_amount,
            purchasedAmount: project.sales_stats.purchased_amount,
            profit: project.sales_stats.profit,
            profitPercent: (project.sales_stats.billed_amount)
              ? (project.sales_stats.profit / project.sales_stats.billed_amount * 100).toFixed(2)
              : 0.0,
            billedAmountExclDiscount: project.sales_stats.billed_amount_excl_discount,
            profitExclDiscount: project.sales_stats.profit_excl_discount,
            profitExclDiscountPercent: (project.sales_stats.billed_amount_excl_discount)
              ? (project.sales_stats.profit_excl_discount / project.sales_stats.billed_amount_excl_discount * 100).toFixed(2)
              : 0.0,
          };
        } else {
          projectVm.salesStats = {
            billedAmount: null,
            purchasedAmount: null,
            profit: null,
            profitPercent: 0.0,
            billedAmountExclDiscount: null,
            profitExclDiscount: null,
            profitExclDiscountPercent: 0.0,
          };
        }
      },
    ]);
}());

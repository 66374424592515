(function () {
  /**
  * Menu parent active directive
  *
  * Add class active on link if
  * the next ul has the current
  * state in list
  */
  angular
    .module('partners-bo.components.directives')
    .directive('menuParentActive', [
      '$state',
      function ($state) {
        return {
          restrict: 'A',
          link($scopeIgnored, $el) {
            const isCurrentState = [].some.call($el.next().find('a'), (link) => {
              let stateName = link.getAttribute('ui-sref');
              if (stateName.indexOf('({') !== -1) {
                stateName = stateName.substring(0, stateName.indexOf('({'));
              }
              return (stateName === $state.current.name);
            });

            if (isCurrentState) {
              $el.addClass('active');
            }
          },
        };
      },
    ]);
}());

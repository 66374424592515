export const raven = require('raven-js');
const ngRaven = require('raven-js/plugins/angular');

raven
  .config(envConfig.sentry.dsn, {
    shouldSendCallback: (() => envConfig.sentry.enabled),
    autoBreadcrumbs: { console: envConfig.sentry.enabled },
    release: __RELEASE__,
    // will add build URL (travis or buddy) in sentry
    tags: { build: __BUILD_URL__ },
  })
  .addPlugin(ngRaven, angular)
  .setEnvironment(envConfig.sentry.env)
  .install();

(function () {
  angular
    .module('partners-bo.components.services')
    .service('partnersVat', [
      function partnersVat() {
        const vm = this;
        // This list should be the same as PHP config.yml plz
        vm.UE = [
          { name: 'Austria', code: 'AT' },
          { name: 'Belgium', code: 'BE' },
          { name: 'Bulgaria', code: 'BG' },
          { name: 'Croatia', code: 'HR' },
          { name: 'Cyprus', code: 'CY' },
          { name: 'Czech Republic', code: 'CZ' },
          { name: 'Denmark', code: 'DK' },
          { name: 'Estonia', code: 'EE' },
          { name: 'Finland', code: 'FI' },
          { name: 'France', code: 'FR' },
          { name: 'French Guiana', code: 'GF' },
          { name: 'French Polynesia', code: 'PF' },
          { name: 'French Southern Territories', code: 'TF' },
          { name: 'La réunion', code: 'RE' },
          { name: 'Wallis et Futuna', code: 'WF' },
          { name: 'Saint-Pierre-et-Miquelon', code: 'PM' },
          { name: 'Saint-Martin (partie française)', code: 'MF' },
          { name: 'Saint-Barthélemy', code: 'BL' },
          { name: 'Mayotte', code: 'YT' },
          { name: 'Nouvelle-Calédonie', code: 'NC' },
          { name: 'Germany', code: 'DE' },
          { name: 'Greece', code: 'GR' },
          { name: 'Guadeloupe', code: 'GP' },
          { name: 'Hungary', code: 'HU' },
          { name: 'Ireland', code: 'IE' },
          { name: 'Italy', code: 'IT' },
          { name: 'Latvia', code: 'LV' },
          { name: 'Lithuania', code: 'LT' },
          { name: 'Luxembourg', code: 'LU' },
          { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
          { name: 'Malta', code: 'MT' },
          { name: 'Martinique', code: 'MQ' },
          { name: 'Netherlands', code: 'NL' },
          { name: 'Poland', code: 'PL' },
          { name: 'Portugal', code: 'PT' },
          { name: 'Romania', code: 'RO' },
          { name: 'Slovakia', code: 'SK' },
          { name: 'Slovenia', code: 'SI' },
          { name: 'Spain', code: 'ES' },
          { name: 'Sweden', code: 'SE' },
          { name: 'United Kingdom', code: 'GB' },
        ];

        vm.countryIsOutOfEU = function countryIsOutOfEU(country) {
          if (!_.findWhere(vm.UE, { code: country })) {
            return true;
          }
          return false;
        };

        vm.countryIsFrench = function countryIsFrench(country) {
          // VAT cant be handled for now with :['GF','PF','TF','MC']
          return (['FR', 'MQ', 'GP', 'MC', 'RE', 'WF', 'PM', 'MF', 'BL', 'YT', 'NC', 'GF', 'PF', 'TF', 'MC'].indexOf(country) >= 0);
        };
      },
    ])
  ;
}());

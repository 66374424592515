(function () {
  /**
   * Translate config
   */
  angular
    .module('partners-bo')
    .config([
      'tagsInputConfigProvider',
      function (tagsInputConfigProvider) {
        tagsInputConfigProvider.setDefaults('tagsInput', { placeholder: '' });
        tagsInputConfigProvider.setActiveInterpolation('tagsInput', { placeholder: true });
      },
    ]);
}());

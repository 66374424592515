import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'expose-loader?$!expose-loader?jQuery!jquery';
import 'expose-loader?angular!angular';
import 'bootstrap';
import 'expose-loader?_!underscore';
import 'angular-sanitize';
import 'ng-notifications-bar';
import 'angular-cookies';
import 'angular-animate';
import 'angular-autodisable/angular-autodisable.js';
import 'angular-cookie';
import 'angular-elastic';
import 'angular-loading-bar';
import 'angular-local-storage';
import 'angular-messages';
import 'angular-moment';
import 'angular-translate';
import 'angular-translate-interpolation-messageformat';
import 'angular-translate-loader-static-files';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';
import 'angular-ui-router';
import 'angular-ui-bootstrap';
import 'icheck';
import 'dcjqaccordion';
import 'ng-file-upload';
import 'ng-tags-input';
import 'ngbootbox';
import 'ui-select';
import 'ui-select-infinity';

import 'sass/main.scss';

require('script-loader!dcjqaccordion/js/jquery.dcjqaccordion.2.7.js');
require('script-loader!dcjqaccordion/js/jquery.hoverIntent.minified.js');
require('script-loader!dcjqaccordion/js/jquery.cookie.js');

require('raven');

require('script-loader!moment');
require('ng-notifications-bar');
require('script-loader!moment/locale/fr.js');

require('script-loader!wysihtml/dist/wysihtml.js');
require('script-loader!wysihtml/dist/wysihtml.toolbar.js');
require('script-loader!@creads/partners-angular-core');
require('script-loader!@creads/partners-sdk-angular');

// main app
require('app');

// require translation configuration;
require('translations/angular_fr-fr.js');

// require all directives
require('components/directives/directives');

let requires = require.context('./components/directives', false, /\.directive\.ts$/);
requires.keys().forEach(requires);

// require all filters
require('components/filters/filters');

requires = require.context('./components/filters', false, /\.filter\.ts$/);
requires.keys().forEach(requires);

// require all services
require('components/services/services');

requires = require.context('./components/services', false, /\.service\.ts$/);
requires.keys().forEach(requires);


// configurations
require('console.constant');

// require all config files
requires = require.context('./', false, /\.config\.ts$/);
requires.keys().forEach(requires);

// controllers
require('apps/apps');
require('auth/auth');
require('dashboard/dashboard');
require('categories/categories');
require('invoices/invoices');
require('layout/layout');
require('organizations/organizations');
require('payments/payments');
require('products/products');
require('projects/projects');
require('reviews/reviews');
require('settings/settings');
require('users/users');
require('workers/workers');
require('scenarios/scenarios');

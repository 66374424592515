(function () {
  /**
  * x-editable directive
  */
  angular
    .module('partners-bo.components.directives')
    .directive('tooltip', [
      function tooltip() {
        return {
          scope: {
            text: '@',
            position: '@',
          },
          transclude: true,
          restrict: 'E',
          link($scope: any, $el) {
            $el.find('[data-toggle="tooltip"]').tooltip({
              placement: $scope.position,
              title: $scope.text,
            });
          },
          compile(tElement, attrsIgnored, transclude) {
            return function ($scope) {
              transclude($scope, (clone) => {
                tElement.append(clone);
              });
            };
          },
        };
      },
    ]);
}());

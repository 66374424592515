(function () {
  /**
  * Categories module
  */
  angular
    .module('partners-bo.categories', [
    ])

    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider

          .state('categories', {
            parent: 'root',
            url: '/categories/list/{index:int}?{search:string}&{orderBy:string}',
            controller: 'ListCategoryController',
            controllerAs: 'vm',
            templateUrl: 'categories/categories.html',
            resolve: {
              request: [
                '$stateParams',
                'paginator',
                function ($stateParams, paginator) {
                  const params: ParamsQuery = {
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    fields: 'description,gid,href,title,weight,is_removable',
                    locale: 'fr_FR',
                  };

                  if ($stateParams.orderBy) {
                    const orderBy = $stateParams.orderBy ? $stateParams.orderBy.split(':') : null;
                    if ('title' === orderBy[0]) {
                      params.sort_index_by = $stateParams.orderBy;
                    }

                    if ('weight' === orderBy[1]) {
                      params.orderBy = $stateParams.orderBy;
                    }
                  }

                  if ($stateParams.search) {
                    const { search } = $stateParams;
                    params.search = search;
                  }

                  return {
                    method: 'GET',
                    url: '/categories',
                    params,
                  };
                },
              ],
              categories: [
                'api',
                'request',
                function (api, request) {
                  return api.call(request).then((res) => res.data);
                },
              ],
            },
          })

          .state('categories-add', {
            parent: 'root',
            url: '/categories/add',
            controller: 'ManageCategoriesController',
            controllerAs: 'vm',
            templateUrl: 'categories/edit.html',
            resolve: {
              category() {
                return {};
              },
            },
          })

          .state('categories-edit', {
            parent: 'root',
            url: '/categories/{id}',
            controller: 'ManageCategoriesController',
            controllerAs: 'vm',
            templateUrl: 'categories/edit.html',
            resolve: {
              category: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/categories/${$stateParams.id}`,
                    params: {
                      fields: 'title,description,locale,illustration',
                      locale: 'fr_FR',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('categories-translate', {
            parent: 'root',
            url: '/categories/{id}/translate',
            controller: 'TranslateCategoriesController',
            controllerAs: 'vm',
            templateUrl: 'categories/translate.html',
            resolve: {
              category: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/categories/${$stateParams.id}`,
                    params: {
                      fields: 'title,description',
                      locale: 'fr_FR',
                    },
                  }).then((res) => res.data);
                },
              ],
              localizedCategory: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/categories/${$stateParams.id}`,
                    params: {
                      fields: 'title,description,locale',
                      locale: 'en_US',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })
        ;

        $urlRouterProvider
          .when('/categories', '/categories/1')
        ;
      },
    ])

    .controller('ListCategoryController', [
      'ROLES',
      '$translate',
      'notifications',
      'api',
      'categories',
      'currentUser',
      function ListCategoryController(ROLES, $translate, notifications, api, categories, currentUser) {
        const vm = this;

        vm.categories = categories.items;
        vm.totalCount = categories.total_count;
        vm.editAllowed = currentUser.roles.indexOf(ROLES.PRICING_ADMIN) >= 0;

        /**
         * [remove]
         * @param  {object} organization
         * @return {void}
         */
        vm.remove = function remove(category) {
          api
            .call({
              method: 'DELETE',
              url: `/categories/${category.gid}`,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('La catégorie a bien été supprimé.'),
              });
              vm.categories.splice(vm.categories.indexOf(category), 1);
            })
            .error((data) => {
              throw new Error(`Error${data}`);
            });
        };

        vm.saveCategoryWeight = function saveCategoryWeight(category) {
          api
            .call({
              method: 'PUT',
              url: `/categories/${category.gid}`,
              data: {
                weight: category.weight,
              },
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('categories.weight.success'),
              });
              category.editingWeight = false;
            });
        };
      },
    ])

    .controller('ManageCategoriesController', [
      'ROLES',
      '$translate',
      '$state',
      'currentUser',
      'notifications',
      'api',
      'category',
      function ManageCategoriesController(ROLES, $translate, $state, currentUser, notifications, api, category) {
        const vm = this;

        vm.category = category;
        vm.currentUser = currentUser;

        if (!vm.category.locale) {
          vm.category.locale = 'fr_FR';
        }

        vm.editAllowed = currentUser.roles.indexOf(ROLES.PRICING_ADMIN) >= 0;

        vm.uploaderValidate = {
          pattern: '.jpg,.gif,.jpeg,.png,.svg',
          accept: 'image/jpg,image/jpeg,image/png,image/gif,image/svg',
          size: { max: '8MB' },
        };
        vm.uploadError = {};

        vm.onAddIllustration = function onAddIllustration() {
          return function (file) {
            vm.category.illustration = file;
          };
        };

        vm.onRemoveIllustration = function onRemoveIllustration() {
          return function (fileIgnored, callback) {
            vm.category.illustration = null;
            callback();
          };
        };

        /**
         * [submit]
         * @return {void}
         */
        vm.submit = function submit() {
          if (vm.form.$submitted && vm.form.$valid) {
            api
              .call({
                method: (vm.category.gid) ? 'PUT' : 'POST',
                url: (vm.category.gid) ? `/categories/${vm.category.gid}` : '/categories',
                data: {
                  title: vm.category.title,
                  description: vm.category.description,
                  locale: vm.category.locale,
                  illustration: vm.category.illustration,
                },
              })
              .success(() => {
                notifications.showSuccess({
                  message: $translate.instant('La categorie a bien été modifié.'),
                });
                $state.go('categories', { index: 1 });
              })
              .error((data) => {
                throw new Error(`Error${data}`);
              });
          }
        };
      },
    ])

    .controller('TranslateCategoriesController', [
      '$translate',
      'currentUser',
      'notifications',
      'api',
      'category',
      'localizedCategory',
      'translationAvailableLocales',
      function TranslateCategoriesController($translate, currentUser, notifications, api, category, localizedCategory, translationAvailableLocales) {
        const vm = this;

        vm.category = category;
        vm.currentUser = currentUser;
        vm.localizedCategory = localizedCategory;
        vm.translationAvailableLocales = translationAvailableLocales;

        vm.changeLocale = async () => {
          vm.localizedCategory = (await api
            .call({
              method: 'GET',
              url: `/categories/${vm.localizedCategory.gid}`,
              params: {
                fields: 'title,description,locale',
                locale: vm.localizedCategory.locale,
              },
            })
            .then((response) => response.data)
          );
        };

        /**
         * [submit]
         * @return {void}
         */
        vm.submit = function submit() {
          if (vm.form.$submitted && vm.form.$valid) {
            api
              .call({
                method: 'PUT',
                url: `/categories/${vm.localizedCategory.gid}`,
                data: {
                  title: vm.localizedCategory.title,
                  description: vm.localizedCategory.description,
                  locale: vm.localizedCategory.locale,
                },
              })
              .success(() => {
                notifications.showSuccess({
                  message: $translate.instant('categories.translate.success'),
                });
              })
              .error((data) => {
                throw new Error(`Error${data}`);
              });
          }
        };
      },
    ]);
}());

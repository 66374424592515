(function () {
  /**
  * Users Module
  */
  angular
    .module('partners-bo.users', [
    ])
    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider

          .state('users-invite', {
            parent: 'root',
            url: '/users/invite',
            templateUrl: 'users/invite.html',
            controller: 'InviteUsersController',
            controllerAs: 'vm',
          })

          .state('users', {
            parent: 'root',
            url: '/users/list/{index:int}?{limit:int}&{search:string}',
            templateUrl: 'users/users.html',
            controller: 'ListUsersController',
            controllerAs: 'vm',
            reloadOnSearch: true,
            resolve: [
              {
                token: 'userStats',
                policy: { when: 'LAZY', async: 'NOWAIT' },
                deps: ['api', 'users'],
                resolveFn(api, users) {
                  const statsBatch = [];
                  angular.forEach(users.items, (elem) => {
                    statsBatch.push({
                      method: 'GET',
                      relative_url: `/v1/users/${elem.gid}/stat`,
                    });
                  });
                  return api.call({
                    method: 'POST',
                    url: '/batch',
                    data: statsBatch,
                    ignoreLoadingBar: true,
                  }).then((result) => {
                    const data = _.pluck(result.data, 'body');
                    angular.forEach(data, (element, key) => {
                      element = JSON.parse(element);
                      users.items[key].stats = element;
                    });
                  });
                },
              },
              {
                token: 'membership',
                policy: { when: 'LAZY', async: 'NOWAIT' },
                deps: ['api', 'users', '$httpParamSerializer'],
                resolveFn(api, users, $httpParamSerializer) {
                  const membershipBatch = [];
                  angular.forEach(users.items, (element) => {
                    membershipBatch.push({
                      method: 'GET',
                      relative_url: `/v1/users/${element.gid}/member_of?${$httpParamSerializer({ fields: 'organization,is_owner,team', limit: 5 })}`,
                    });
                  });

                  return api.call({
                    method: 'POST',
                    url: '/batch',
                    data: membershipBatch,
                    ignoreLoadingBar: true,
                  }).then((result) => {
                    const data = _.pluck(result.data, 'body');
                    angular.forEach(users.items, (user, key) => {
                      const memberOf = JSON.parse(data[key]).items;

                      user.memberOf = memberOf;
                      user.memberOfCount = JSON.parse(data[key]).total_count;
                      user.organizationNames = _.map(memberOf, function (mmb) {
                        return mmb.organization.name;
                      });

                      var ownership = _.find(user.memberOf, function (mmb) {
                        return (mmb.is_owner === true);
                      });
                      user.isOwner = ownership ? true : false;
                    });
                  });
                },
              },
              {
                token: 'users',
                deps: ['$stateParams', 'api', 'paginator'],
                resolveFn($stateParams, api, paginator) {
                  paginator.setMaxItemPage($stateParams.limit);

                  return api.call({
                    method: 'GET',
                    url: '/users',
                    params: {
                      search: $stateParams.search || null,
                      limit: paginator.getMaxItemPage(),
                      offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                      orderBy: 'modified_at:desc',
                      fields: 'avatar,roles,display_full_name,email,is_inactive,is_valid',
                    },
                  }).then((res) => res.data);
                },
              },
            ],
          })

          .state('professions', {
            parent: 'root',
            url: '/professions/list',
            templateUrl: 'users/professions.html',
            controller: 'ListProfessionsController',
            controllerAs: 'vm',
            resolve: [
              {
                token: 'professions',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/settings/user/professions',
                  }).then((res) => res.data.value);
                },
              },
              {
                token: 'professionsCounts',
                deps: ['api', 'professions'],
                resolveFn(api, professions) {
                  angular.forEach(professions, (elementIgnored, key) => {
                    api.call({
                      method: 'GET',
                      url: '/users/total_count',
                      params: {
                        query: JSON.stringify([['profession', '==', professions[key].value]]),
                      },
                    }).then((res) => {
                      professions[key].users_count = res.data.total_count;
                    });
                  });
                },
              },
            ],
          })

          .state('users-by-organization', {
            parent: 'root',
            url: '/organization/{orgaGid}/users/{index:int}?{limit:int}?{search :string}',
            templateUrl: 'users/users-by-organization.html',
            controller: 'ListUsersByOrganizationController',
            controllerAs: 'vm',
            resolve: {
              usersAndOrgBatch: [
                '$stateParams',
                'api',
                'paginator',
                '$httpParamSerializer',
                function ($stateParams, api, paginator, $httpParamSerializer) {
                  paginator.setMaxItemPage($stateParams.limit);

                  const params = {
                    search: $stateParams.search || null,
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    orderBy: 'modified_at:desc',
                    query: JSON.stringify([['member_of.organization.gid', '==', $stateParams.orgaGid]]),
                    fields: 'lastname,firstname,email',
                  };
                  const batchRequest = [
                    {
                      method: 'GET',
                      relative_url: `/v1/users?${$httpParamSerializer(params)}`,
                    },
                    {
                      method: 'GET',
                      relative_url: `/v1/organizations/${$stateParams.orgaGid}?${$httpParamSerializer({ fields: 'gid,href,name,owner' })}`,
                    },
                  ];
                  return api.call({
                    method: 'POST',
                    url: '/batch',
                    data: batchRequest,
                  }).then((result) => {
                    const data = _.pluck(result.data, 'body');

                    return {
                      users: JSON.parse(data[0]),
                      organization: JSON.parse(data[1]),
                    };
                  });
                },
              ],
            },
          })

          .state('users-edit', {
            parent: 'root',
            url: '/users/{id}/edit',
            templateUrl: 'users/edit.html',
            controller: 'EditUsersController',
            controllerAs: 'vm',
            resolve: [
              {
                token: 'userAndProfessionsBatch',
                deps: ['api', '$httpParamSerializer', '$stateParams'],
                resolveFn(api, $httpParamSerializer, $stateParams) {

                  var userParams = {
                    fields: 'locale,firstname,lastname,email,phone,mobile_phone,profession,organization_template,newsletters_option,roles',
                  };

                  const batchRequest = [
                    {
                      method: 'GET',
                      relative_url: `/v1/users/${$stateParams.id}?${$httpParamSerializer(userParams)}`,
                    },
                    {
                      method: 'GET',
                      relative_url: '/v1/settings/user/professions',
                    },
                  ];
                  return api.call({
                    method: 'POST',
                    url: '/batch',
                    data: batchRequest,
                  }).then((result) => {
                    const data = _.pluck(result.data, 'body');
                    return {
                      user: JSON.parse(data[0]),
                      professions: JSON.parse(data[1]).value,
                    };
                  });
                },
              },
            ],
          })

          .state('invitations', {
            parent: 'root',
            url: '/invitations',
            templateUrl: 'users/invitations.html',
            controller: 'InvitationsController',
            controllerAs: 'vm',
            resolve: {
              invitations: [
                'api',
                function (api) {
                  return api.call({
                    method: 'GET',
                    url: '/invitations',
                    params: {
                      fields: 'email,organization,has_user,user_rank,gid,token',
                      orderBy: 'created_at:desc',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('user', {
            parent: 'root',
            url: '/users/{gid:[0-9a-fA-F]+}',
            templateUrl: 'users/user.html',
            controller: 'ViewUserController',
            controllerAs: 'userVm',
            resolve: {
              userAndStatsBatch: [
                '$stateParams',
                'api',
                '$httpParamSerializer',
                function ($stateParams, api, $httpParamSerializer) {
                  const params = {
                    fields: 'gid,display_full_name,email,avatar,hubspot_contact_url,newsletters_optin',
                  };

                  const batchRequest = [
                    {
                      method: 'GET',
                      relative_url: `/v1/users/${$stateParams.gid}?${$httpParamSerializer(params)}`,
                    },
                    {
                      method: 'GET',
                      relative_url: `/v1/users/${$stateParams.gid}/stat`,
                    },
                  ];

                  return api.call({
                    method: 'POST',
                    url: '/batch',
                    data: batchRequest,
                  }).then((result) => {
                    const data = _.pluck(result.data, 'body');
                    return {
                      user: JSON.parse(data[0]),
                      userStats: JSON.parse(data[1]),
                    };
                  });
                },
              ],
            },
          })

          .state('user.other-orgs', {
            controller: 'ListOtherOrgsForUserController',
            templateUrl: '/users/user.orgs.html',
            controllerAs: 'vm',
            url: '/other-orgs/{index:int}?{limit:int}&{orderBy:string}&{search:string}',
            params: {
              index: 1,
              search: null,
            },
            resolve: {
              orgs: [
                '$stateParams',
                'paginator',
                'api',
                function ($stateParams, paginator, api) {
                  paginator.setMaxItemPage($stateParams.limit);
                  const params: ParamsQuery = {
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    fields: 'name',
                  };

                  const query = [];

                  if ($stateParams.orderBy) {
                    params.orderBy = $stateParams.orderBy;
                  }
                  query.push(['gid', 'exclude', ['members.user.gid', '==', $stateParams.gid]]);

                  if ($stateParams.search) {
                    params.search = $stateParams.search;
                  }

                  params.query = JSON.stringify(query);

                  return api.call({
                    method: 'GET',
                    url: '/organizations',
                    params,
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('user.orgs', {
            controller: 'ListOrgsForUserController',
            templateUrl: '/users/user.orgs.html',
            controllerAs: 'vm',
            url: '/orgs/{index:int}?{limit:int}&{orderBy:string}&{search:string}',
            params: {
              index: 1,
              search: null,
            },
            resolve: {
              orgs: [
                '$stateParams',
                'paginator',
                'api',
                function ($stateParams, paginator, api) {
                  paginator.setMaxItemPage($stateParams.limit);
                  const params: ParamsQuery = {
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    fields: 'name',
                  };

                  const query = [];

                  if ($stateParams.orderBy) {
                    params.orderBy = $stateParams.orderBy;
                  }
                  query.push(['members.user.gid', '==', $stateParams.gid]);

                  if ($stateParams.search) {
                    params.search = $stateParams.search;
                  }

                  params.query = JSON.stringify(query);

                  return api.call({
                    method: 'GET',
                    url: '/organizations',
                    params,
                  }).then((res) => res.data);
                },
              ],
            },
          });

        $urlRouterProvider
          .when('/users', '/users/1')
        ;
      },
    ])

    .controller('ListUsersController', [
      'ROLES',
      '$translate',
      '$uibModal',
      'api',
      'currentUser',
      'notifications',
      'users',
      function ListUsersController(ROLES, $translate, $uibModal, api, currentUser, notifications, users) {
        const vm = this;

        vm.ROLES = ROLES;
        vm.users = users.items;
        vm.totalCount = users.total_count;
        vm.currentUser = currentUser;

        vm.getDeleteButtonTooltipMessageForUser = function getDeleteButtonTooltipMessageForUser(user) {
          let status = null;

          if (vm.currentUser.gid === user.gid) {
            status = $translate.instant('users.tooltip.delete_forbidden.is_current_user')
          } else if (!status && !user.memberOfCount) {
            status = $translate.instant('users.tooltip.delete_forbidden.is_deleted');
          } else if (!status && vm.currentUser.roles.indexOf(vm.ROLES.SUPER_ADMIN) < 0 && user.roles.indexOf(vm.ROLES.ADMIN) >= 0) {
            status = $translate.instant('users.tooltip.delete_forbidden.is_admin');
          } else if (!status && user.isOwner) {
            status = $translate.instant('users.tooltip.delete_forbidden.is_owner');
          }

          return $translate
            .instant(
              (!status)
                ? 'users.actions.delete'
                : 'users.actions.delete_forbidden', { status },
            );
        };

        /**
         * [reset-password]
         * @param  {object} user
         * @return {void}
         */
        vm.resetPassword = function resetPassword(user) {
          api
            .call({
              method: 'POST',
              url: '/p/users/forgot-password',
              data: {
                email: user.email,
              },
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('users.password_request.success'),
              });
            })
            .error((data) => {
              throw new Error(`Error${data}`);
            });
        };

        vm.popReassociateProjectModal = (user) => {
          $uibModal.open({
            templateUrl: '/users/reassociate_projects_modal.html',
            controller: 'ReassociateProjectsController',
            controllerAs: '$reassociateProjectsVm',
            bindToController: true,
            resolve: {
              user() {
                return user;
              },
              projects: [
                'api',
                function (api) {
                  return api
                    .call({
                      method: 'GET',
                      url: '/projects',
                      params: {
                        query: JSON.stringify(['owner.gid', '==', user.gid]),
                        fields: 'owner,organization',
                        limit: 30,
                        offset: 0,
                      },
                    })
                    .then((res) => res.data);
                },
              ],
            },
          });
        };

        vm.popDeleteUserModal = (user) => {
          $uibModal.open({
            templateUrl: '/users/delete_user_modal.html',
            controller: 'DeleteUserController',
            controllerAs: '$deleteUserVm',
            bindToController: true,
            resolve: {
              user() {
                return user;
              }
            },
          });
        };
      },
    ])

    .controller('ListProfessionsController', [
      'professions',
      function ListProfessionsController(professions) {
        const vm = this;

        vm.professions = professions;
      },
    ])

    .controller('ListUsersByOrganizationController', [
      'ROLES',
      '$translate',
      '$state',
      'api',
      'notifications',
      'usersAndOrgBatch',
      function ListUsersByOrganizationController(ROLES, $translate, $state, api, notifications, usersAndOrgBatch) {
        const vm = this;

        vm.ROLES = ROLES;
        vm.users = usersAndOrgBatch.users.items;
        vm.organizationsResults = [];
        vm.organization = usersAndOrgBatch.organization;
        vm.totalCount = usersAndOrgBatch.users.total_count;
        vm.orgaName = [];

        angular.forEach(vm.users, (element) => {
          element.organizationNames = [];
          api
            .call({
              url: `/users/${element.gid}/member_of/${usersAndOrgBatch.organization.gid}`,
              method: 'GET',
              ignoreLoadingBar: true,
              params: {
                fields: 'organization,monthly_budget,team',
              }
            }).then(function (userOrg) {
              element.membership = userOrg.data;
            });
        });

        vm.fetchOrgsFromSearch = function fetchOrgsFromSearch(searchQuery) {
          if ('' === searchQuery) {
            return;
          }
          return api.call({
            method: 'GET',
            url: '/organizations',
            params: {
              search: searchQuery,
              fields: 'name',
            },
          }).then((response) => {
            vm.organizationsResults = response.data.items;
          });
        };

        vm.reload = function reload() {
          $state.go('users-by-organization', { orgaGid: vm.organization.gid, index: 1 });
        };

        vm.kickUserFromOrganization = function kickUserFromOrganization(user, $index) {
          api
            .call({
              method: 'DELETE',
              url: `/users/${user.gid}/member_of/${vm.organization.gid}`,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('L\'utilisateur n\'est plus dans l\'organisation.'),
              });
              vm.users.splice($index, 1);
            }).error(() => {
              notifications.showError({
                message: $translate.instant('Erreur, l\'utilisateur ne peut pas être enlevé.'),
              });
            });
        };
      },
    ])

    .controller('EditUsersController', [
      'ROLES',
      '$translate',
      '$ngBootbox',
      '$state',
      '$stateParams',
      'api',
      'notifications',
      'enabledLocales',
      'userAndProfessionsBatch',
      'currentUser',
      function EditUsersController(ROLES, $translate, $ngBootbox, $state, $stateParams, api, notifications, enabledLocales, userAndProfessionsBatch, currentUser) {
        const vm = this;

        vm.emailIsOkay = null;
        vm.roleCheck = true;
        vm.roles = [];
        vm.user = userAndProfessionsBatch.user;
        vm.role = [];
        vm.ROLES = ROLES;
        vm.professions = [];
        vm.currentUser = currentUser;


        if (userAndProfessionsBatch.user.gid) {
          vm.role[ROLES.CUSTOMER] = vm.user.roles.indexOf(ROLES.CUSTOMER) >= 0;
          vm.role[ROLES.ADMIN] = vm.user.roles.indexOf(ROLES.ADMIN) >= 0;
          vm.role[ROLES.PRICING_ADMIN] = vm.user.roles.indexOf(ROLES.PRICING_ADMIN) >= 0;
        } else {
          vm.role[ROLES.CUSTOMER] = true;
        }

        vm.professions = userAndProfessionsBatch.professions;
        vm.user.profession = _.findWhere(vm.professions, { value: vm.user.profession });

        vm.locales = enabledLocales;
        if (!_.findWhere(vm.locales, { code: userAndProfessionsBatch.user.locale })) {
          vm.currentUser.locale = vm.locales[0].code;
        }

        vm.roles = [
          {
            name: 'ADMIN',
            value: 'admin',
          },
          {
            name: 'PRICING_ADMIN',
            value: 'pricing_admin',
          },
        ];

        vm.checkEmail = function checkEmail(email) {
          api
            .call({
              method: 'POST',
              url: '/users/check-email',
              data: {
                email,
              },
              ignoreLoadingBar: true,
            })
            .success(() => {
              vm.emailIsOkay = true;
            })
            .error(() => {
              vm.emailIsOkay = false;
            });
        };

        vm.checkRoles = function checkRoles(value) {
          if ('admin' === value || 'pricing_admin' === value) {
            if (vm.role[value]) {
              $ngBootbox.confirm($translate.instant('Le role admin donne accès à l\'intégralité des données et leur modification, êtes vous sur de vouloir l\'attribuer à cet utilisateur?'))
                .then(() => {
                  vm.role[value] = true;
                }, () => {
                  vm.role[value] = false;
                });
            }
          }
          let correct = false;
          Object.keys(vm.role).forEach((element) => {
            if (vm.role[element]) {
              correct = true;
            }
          });
          vm.roleCheck = correct;
          return correct;
        };

        vm.getMatchingOrgTemplate = function getMatchingOrgTemplate(search) {
          if (!search) {
            return;
          }
          api.call({
            method: 'GET',
            url: '/organizations',
            params: {
              query: JSON.stringify([['template', '==', null]]),
              search,
              fields: 'gid,name',
            },
          }).then((res) => {
            vm.orgsResults = res.data.items;
          });
        };
        /**
         * [submit]
         * @return {void}
         */
        vm.submit = function submit() {
          if (vm.form.$submitted && vm.form.$valid && vm.roleCheck) {
            vm.user.roles = [];
            Object.keys(vm.role).forEach((element) => {
              if (vm.role[element]) {
                vm.user.roles.push(element);
              }
            });

            const dataToSend:{
              firstname: String,
              lastname: String,
              email: String,
              phone: String,
              mobile_phone: String,
              locale: String,
              profession: {},
              organization_template: {},
              roles?: Array<String>,
            } = {
              firstname: vm.user.firstname,
              lastname: vm.user.lastname,
              email: vm.user.email,
              phone: vm.user.phone,
              mobile_phone: vm.user.mobile_phone,
              locale: vm.user.locale,
              profession: vm.user.profession,
              organization_template: vm.user.organization_template,
            };

            if (vm.currentUser.roles.indexOf(vm.ROLES.SUPER_ADMIN) >= 0) {
              dataToSend.roles = vm.user.roles;
            }

            api
              .call({
                method: 'PUT',
                url: `/users/${$stateParams.id}`,
                data: dataToSend,
              })
              .success((dataIgnored, statusIgnored, headers) => {
                notifications.showSuccess({
                  message: $translate.instant('L\'utilisateur a bien été modifié.'),
                });
                if (!userAndProfessionsBatch.user.gid) {
                  const userGid = headers('Location').replace(/^\/v1\/users\//, '');
                  $state.go('user.other-orgs', { gid: userGid });
                } else {
                  $state.go('users', { index: 1 });
                }
              })
              .error(() => {
              });
          }
        };
      },
    ])

    .controller('InviteUsersController', [
      'ROLES',
      '$state',
      '$translate',
      'notifications',
      'enabledLocales',
      'api',
      'currentUser',
      function InviteUsersController(
        ROLES,
        $state,
        $translate,
        notifications,
        enabledLocales,
        api,
        currentUser,
      ) {
        const vm = this;

        vm.currentUser = currentUser;
        vm.ROLES = ROLES;
        vm.userRanks = [
          {
            name: 'Utilisateur',
            value: 'user',
          },
          {
            name: 'Owner',
            value: 'owner',
          },
        ];
        const ownerRank = vm.userRanks[1];

        vm.invitation = {
          email: '',
          user_rank: 'user',
          user_role: ROLES.CUSTOMER,
          organization: null,
          locale: 'fr_FR',
        };
        vm.organizations = [];
        vm.locales = enabledLocales;

        vm.fetchOrgasFromSearch = function fetchOrgasFromSearch(query) {
          if (!query) {
            return;
          }
          api
            .call({
              method: 'GET',
              url: '/organizations',
              params: {
                search: query,
                fields: 'name,reseller',
              },
            })
            .success((res) => {
              vm.organizations = res.items;
              vm.invitation.team = null;
            });
        };

        vm.fetchTeams = async () => {
          if (vm.invitation.organization) {
            vm.teams = (await api
              .call({
                method: 'GET',
                url: `/orgs/${vm.invitation.organization.gid}/teams`,
                params: {
                  fields: 'organization,name,budget,permissions',
                },
              })).data.items;
          }
        };

        vm.submit = function submit() {
          const invitationCopy = { ...vm.invitation };
          if (vm.form.$submitted && vm.form.$valid) {
            api
              .call({
                method: 'POST',
                url: '/invitations',
                data: invitationCopy,
              })
              .success(() => {
                notifications.showSuccess({
                  message: $translate.instant('L\'utilisateur va recevoir un lien par e-mail.'),
                });
                $state.go('invitations', { index: 1 });
              })
              .error((data) => {
                if (400 === data.error.code) {
                  vm.form.$setValidity('organization', false);
                } else if (409 === data.error.code) {
                  vm.form.$setValidity('invalidEmail', false);
                }
              });
          }
        };

        vm.restoreValidity = function restoreValidity() {
          vm.invitation.user_role = ROLES.CUSTOMER;

          if (!vm.invitation.organization.template_ownership_enabled && vm.userRanks.length <= 1) {
            vm.userRanks.push(ownerRank);
          } else if (vm.invitation.organization.template_ownership_enabled && 2 === vm.userRanks.length) {
            vm.userRanks.pop();
          }
          vm.form.$setValidity('organization', true);
          vm.form.$setPristine();
        };
      },
    ])

    .controller('InvitationsController', [
      'notifications',
      '$translate',
      'api',
      'invitations',
      function InvitationsController(notifications, $translate, api, invitations) {
        const vm = this;
        vm.invitations = invitations.items;

        vm.remove = function remove(invitation) {
          api
            .call({
              method: 'DELETE',
              url: `/invitations/${invitation.gid}`,
            })
            .success(() => {
              notifications.showWarning({
                message: $translate.instant('L\'utilisateur ne pourra plus l\'utiliser.'),
              });
              vm.invitations.splice(vm.invitations.indexOf(invitation), 1);
            })
            .error(() => {
            });
        };

        vm.resend = function resend(invitation) {
          api
            .call({
              method: 'PUT',
              url: `/invitations/${invitation.token}`,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('Invitation renvoyée.'),
              });
            })
            .error(() => {
            });
        };
      },
    ])

    .controller('ViewUserController', [
      '$translate',
      'api',
      'notifications',
      'userAndStatsBatch',
      function ViewUserController($translate, api, notifications, userAndStatsBatch) {
        const vm = this;

        vm.user = userAndStatsBatch.user;
        vm.userStats = userAndStatsBatch.userStats;

        vm.addOrg = function addOrg(org) {
          return api
            .call({
              method: 'POST',
              url: `/users/${userAndStatsBatch.user.gid}/member_of`,
              data: {
                organization: {
                  gid: org.gid,
                },
              },
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('L\'utilisateur a été ajouté à l\'organisation.'),
              });
              org.activationState = 'enabled';
            });
        };

        vm.removeOrg = function removeOrg(org) {
          return api
            .call({
              method: 'DELETE',
              url: `/users/${userAndStatsBatch.user.gid}/member_of/${org.gid}`,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('L\'utilisateur a été retiré de l\'organisation.'),
              });
              org.activationState = 'disabled';
            })
            .error(() => {
              notifications.showError({
                message: $translate.instant('users.error.remove_membership_fail'),
              });
            });
        };
      },
    ])

    .controller('ListOtherOrgsForUserController', [
      'orgs',
      function ListOtherOrgsForUserController(orgs) {
        const vm = this;

        vm.viewMode = 'disabled';
        vm.reversedViewMode = 'enabled';
        vm.orgs = orgs.items;
        vm.totalCount = orgs.total_count;
      },
    ])

    .controller('ListOrgsForUserController', [
      'orgs',
      function ListOrgsForUserController(orgs) {
        const vm = this;

        vm.viewMode = 'enabled';
        vm.reversedViewMode = 'disabled';
        vm.orgs = orgs.items;
        vm.totalCount = orgs.total_count;
      },
    ])

    .controller('ReassociateProjectsController', [
      '$scope',
      '$state',
      '$translate',
      'notifications',
      'api',
      'user',
      'projects',
      function ReassociateProjects($scope, $state, $translate, notifications, api, user, projects) {
        const vm = this;

        vm.user = user;
        vm.users = {};
        vm.newOwner = {};
        vm.projectsFetching = true;

        const updateProjectsList = () => {
          vm.projectsFetching = false;

          vm.userProjectsOrgs = _.map(projects.items, ((p) => p.organization));

          vm.userProjectsOrgs = _.uniq(vm.userProjectsOrgs, (org) => org.gid);
        };

        if (projects.items.length === projects.total_count) {
          updateProjectsList();
        }

        let offset = 0;
        for (let index = 30; index < projects.total_count; index += 30) {
          offset += 30;
          api.call({
            method: 'GET',
            url: '/projects',
            params: {
              query: JSON.stringify(['owner.gid', '==', user.gid]),
              fields: 'owner,organization,gid',
              limit: 30,
              offset,
            },
          }).then(((res) => {
            res.data.items.map((p) => projects.items.push(p));
            if (projects.items.length === projects.total_count) {
              updateProjectsList();
            }
          }));
        }
        vm.getMatchingUsers = function getMatchingUsers(searchQuery, org) {
          if ('' === searchQuery) {
            return;
          }
          return api.call({
            method: 'GET',
            url: `/orgs/${org.gid}/users`,
            params: {
              search: searchQuery,
              fields: 'display_full_name,email',
            },
          }).then((response) => {
            vm.users[org.gid] = _.filter(response.data.items, (u) => u.gid !== vm.user.gid);
            return vm.users[org.gid];
          });
        };

        function batchRemoveUserFromOrgs() {
          return api.call({
            method: 'DELETE',
            url: `/users/${user.gid}/member_of`,
          }).then((response) => {
            $scope.$close();
            notifications.showSuccess({
              message: $translate.instant('users.delete_modal.success'),
            });
            $state.go($state.current.name, {}, { reload: true });
          }, () => {
            $scope.$close();
            notifications.showError({
              message: $translate.instant('users.delete_modal.some_fail'),
            });
            $state.go($state.current.name, {}, { reload: true });
          });
        }

        vm.submit = function submit() {
          const projectsBatch = [];
          angular.forEach(projects.items, (p) => {
            projectsBatch.push({
              method: 'PUT',
              relative_url: `/v1/projects/${p.gid}`,
              body: {
                owner: {
                  gid: vm.newOwner[p.organization.gid].gid,
                },
              },
            });
          });

          if (!projectsBatch.length) {
            batchRemoveUserFromOrgs();
            return;
          }

          return api.call({
            method: 'POST',
            url: '/batch',
            data: projectsBatch,
          }).then((response) => {
            const error = _.find(response.data, (res) => res.code >= 400);
            if (error) {
              $scope.$close();
              notifications.showError({
                message: $translate.instant('users.delete_modal.some_fail'),
              });
              $state.go($state.current.name, {}, { reload: true });
            } else {
              batchRemoveUserFromOrgs();
            }
          }, () => {
            $scope.$close();
            notifications.showError({
              message: $translate.instant('users.delete_modal.fail'),
            });
            $state.go($state.current.name, {}, { reload: true });
          });
        };
      },
    ])

    .controller('DeleteUserController', [
      '$scope',
      '$state',
      '$translate',
      'notifications',
      'api',
      'user',
      function DeleteUserController($scope, $state, $translate, notifications, api, user) {
        const vm = this;

        vm.user = user;

        vm.submit = () => {
          return api
            .call({
              method: 'DELETE',
              url: `/users/${user.gid}`,
            })
            .success(() => {
              $scope.$close();
              notifications.showSuccess({
                message: $translate.instant('users.delete_modal.success'),
              });
              user.is_deleted = true;
              $state.go($state.current.name, {}, { reload: true });
            })
            .error((response) => {
              $scope.$close();
              switch (response.error.code) {
                case 400:
                  if (response.error.exception) {
                    response.error.exception.forEach((exception) => {
                      if (exception.message.includes('You cannot enable or disable user')) {
                        notifications.showError({
                          message: $translate.instant('users.delete_modal.errors.toggle_delete'),
                        });
                        // dirty but only way to break foreach
                        throw $translate.instant('users.delete_modal.errors.toggle_delete');
                      }
                    });
                  }
                  break;
                default:
                  notifications.showError({
                    message: $translate.instant('users.delete_modal.errors.delete'),
                  });
                  break;
              }
              $state.go($state.current.name, {}, { reload: true });
            })
          ;
        };
      },
    ]);
}());

import axios from 'axios';

(function () {
  angular
    .module('partners-bo.components.services')
    .service('dataGouv', [
      function dataGouv() {
        const vm = this;

        vm.API_URL = 'https://entreprise.data.gouv.fr/api/sirene/v1';

        vm.getIntraCommunityVatNumberBySiren = async function getIntraCommunityVatNumberBySiren(siren) {
          let vatNumber = null;

          if (siren) {
            await axios
              .get(`${vm.API_URL}/siren/${siren}`)
              .then((response) => {
                vatNumber = response.data.numero_tva_intra;
              })
              .catch(() => {
              })
            ;
          }

          return vatNumber;
        };
      },
    ])
  ;
}());

(function () {
  /**
   * Shorthand filter to trust an html string
   * /!\ WARNING: do no use this in non trustable user-input
   */
  angular
    .module('partners-bo.components.filters')
    .filter('trust',
      ['$sce', function ($sce) {
        return function (htmlCode) {
          return $sce.trustAsHtml(htmlCode);
        };
      }]);
}());

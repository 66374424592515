(function () {
  /**
  * iCheck directive
  */
  angular
    .module('partners-bo.components.directives')
    .directive('icheck', [
      '$timeout',
      '$parse',
      function ($timeout) {
        return {
          scope: {
            color: '@',
          },
          require: '?ngModel',
          restrict: 'A',
          link($scope: any, $el, attr, ngModel: any) {
            $timeout(() => {
              const { value } = attr;
              function update(checked) {
                if ('radio' === attr.type) {
                  ngModel.$setViewValue(value);
                } else {
                  ngModel.$setViewValue(checked);
                }
              }

              $scope.color = $scope.color || 'blue';
              $el.iCheck({
                checkboxClass: `icheckbox_square-${$scope.color}`,
                radioClass: `iradio_square-${$scope.color}`,
              }).on('ifChanged', (e) => {
                $scope.$apply(() => {
                  update(e.target.checked);
                });
              });

              $scope.$watch(attr.ngChecked, (checked) => {
                if ('undefined' === typeof checked) checked = !!ngModel.$viewValue;
                update(checked);
              }, true);

              $scope.$watch(attr.ngModel, () => {
                $($el).iCheck('update');
              }, true);
            });
          },
        };
      },
    ]);
}());

(function () {
  /**
   *
   * This filter transforms Firstname Lastname into F. Lastname
   */
  angular
    .module('partners-bo.components.filters')
    .filter('avatar', [
      () => {
        return (person, variation: string) => {
          let avatar: String = 'assets/img/default_avatar.png';

          if (person && person.avatar) {
            avatar = person.avatar.url;

            if (variation && person.avatar.variations[variation]) {
              avatar = person.avatar.variations[variation].url;
            }
          }

          return avatar;
        };
      }])
  ;
  }());

(function () {
  /**
  * Filter Capitalize
  */
  angular
    .module('partners-bo.components.filters')
    .filter('capitalize', [
      function () {
        return function (input) {
          return input.charAt(0).toUpperCase() + input.slice(1);
        };
      },
    ]);
}());

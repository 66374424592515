(function () {
  /**
   * ngNotificationsBar config
   */
  angular
    .module('partners-bo')
    .config([
      'notificationsConfigProvider',
      function (notificationsConfigProvider) {
        // auto hide
        notificationsConfigProvider.setAutoHide(true);

        // delay before hide
        notificationsConfigProvider.setHideDelay(3000);

        // support HTML
        notificationsConfigProvider.setAcceptHTML(false);

        // Set an animation for hiding the notification
        notificationsConfigProvider.setAutoHideAnimation('slideOutUp');

        // delay between animation and removing the nofitication
        notificationsConfigProvider.setAutoHideAnimationDelay(1200);
      },
    ]);
}());

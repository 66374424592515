(function () {
  angular
    .module('partners-bo.reviews', [
    ])
    .config([
      '$stateProvider',
      function ($stateProvider) {
        $stateProvider

          .state('reviews', {
            parent: 'root',
            url: '/reviews/{index:int}?{limit:int}&{am:string}&{w:string}&{o:string}&{u:string}&{type:string}&{workWithAgain:string}&{workWithManagerAgain:string}&{orderBy:string}',
            templateUrl: 'reviews/reviews.html',
            controller: 'ListReviewsController',
            controllerAs: 'vm',
            reloadOnSearch: true,
            params: {
              index: 1,
              limit: 10,
              am: 'all',
            },
            resolve: [
              {
                token: 'reviewQuery',
                deps: ['$stateParams', 'paginator'],
                resolveFn($stateParams, paginator) {
                  paginator.setMaxItemPage($stateParams.limit);
                  let query: ConditionQuery|String = [];
                  let orderBy = 'created_at:desc';

                  if ($stateParams.w) {
                    query.push(['worker.gid', '==', $stateParams.w]);
                  }
                  if ($stateParams.o) {
                    query.push(['project.organization.gid', '==', $stateParams.o]);
                  }
                  if ($stateParams.u) {
                    query.push(['project.owner.gid', '==', $stateParams.u]);
                  }
                  if ($stateParams.am && $stateParams.am !== 'all') {
                    query.push(['project.project_manager.gid', '==', $stateParams.am]);
                  }

                  if ($stateParams.type === 'worker') {
                    query.push(['type', '==', $stateParams.type]);
                  } else if ($stateParams.type === 'user') {
                    query.push(['type', '==', $stateParams.type]);
                  }
                  if ($stateParams.workWithAgain === 'no') {
                    query.push(['work_with_again', '==', 0]);
                  } else if ($stateParams.workWithAgain === 'yes') {
                    query.push(['work_with_again', '==', 1]);
                  } else if ($stateParams.workWithAgain === 'noreply') {
                    query.push(['work_with_again', '==', 2]);
                  }
                  if ($stateParams.workWithManagerAgain === 'no') {
                    query.push(['work_with_manager_again', '==', 0]);
                  } else if ($stateParams.workWithManagerAgain === 'yes') {
                    query.push(['work_with_manager_again', '==', 1]);
                  } else if ($stateParams.workWithManagerAgain === 'noreply') {
                    query.push(['work_with_manager_again', '==', 2]);
                  }

                  query = query.length ? JSON.stringify(query) : null;

                  if ($stateParams.orderBy) {
                    orderBy = $stateParams.orderBy;
                  }

                  return { query, orderBy };
                },
              },
              {
                token: 'reviews',
                deps: ['$stateParams', 'api', 'paginator', 'reviewQuery'],
                resolveFn($stateParams, api, paginator, reviewQuery) {
                  paginator.setMaxItemPage($stateParams.limit);
                  return api.call({
                    method: 'GET',
                    url: '/reviews',
                    params: {
                      query: reviewQuery.query,
                      limit: paginator.getMaxItemPage(),
                      offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                      orderBy: reviewQuery.orderBy,
                      fields: 'gid,project,quality,reactivity,work_with_again,work_with_manager_again,worker,public_comment,private_comment,type,created_at',
                    },
                  }).then((res) => res.data);
                },
              },
              {
                token: 'workers',
                deps: ['api', 'reviews'],
                resolveFn(api, reviews) {
                  const workersIds = [];
                  angular.forEach(reviews.items, (element) => {
                    workersIds.push(element.worker.creads_id);
                  });
                  return api.call({
                    method: 'GET',
                    url: '/workers',
                    params: {
                      query: JSON.stringify(['creadsId', 'in', workersIds]),
                      fields: 'gid,href,username',
                    },
                  }).then((res) => res.data);
                },
              },
              {
                token: 'accountManagers',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/project-managers',
                    params: {
                      fields: 'display_full_name',
                      limit: -1
                    },
                  }).then((res) => res.data.items);
                },
              },
              {
                token: 'filterWorker',
                deps: ['api', '$stateParams'],
                resolveFn(api, $stateParams) {
                  if (!$stateParams.w) {
                    return null;
                  }

                  return api.call({
                    method: 'GET',
                    url: `/workers/${$stateParams.w}`,
                    params: {
                      fields: 'username',
                    },
                  }).then((res) => res.data);
                },
              },
              {
                token: 'filterOrg',
                deps: ['api', '$stateParams'],
                resolveFn(api, $stateParams) {
                  if (!$stateParams.o) {
                    return null;
                  }

                  return api.call({
                    method: 'GET',
                    url: `/organizations/${$stateParams.o}`,
                    params: {
                      fields: 'name',
                    },
                  }).then((res) => res.data);
                },
              },
              {
                token: 'filterUser',
                deps: ['api', '$stateParams'],
                resolveFn(api, $stateParams) {
                  if (!$stateParams.u) {
                    return null;
                  }

                  return api.call({
                    method: 'GET',
                    url: `/users/${$stateParams.u}`,
                    params: {
                      fields: 'display_full_name',
                    },
                  }).then((res) => ({
                    user: res.data,
                    term: res.data.display_full_name,
                  }));
                },
              },
              {
                token: 'reviewsAverage',
                deps: ['$stateParams', 'api', 'paginator', 'reviewQuery'],
                resolveFn($stateParams, api, paginator, reviewQuery) {
                  paginator.setMaxItemPage($stateParams.limit);
                  return api.call({
                    method: 'GET',
                    url: '/average-reviews',
                    params: {
                      query: reviewQuery.query
                    },
                  }).then((res) => res.data);
                },
              },
            ],
          });
      },
    ])

    .controller('ListReviewsController', [
      '$state',
      '$stateParams',
      '$translate',
      'accountManagers',
      'api',
      'filterOrg',
      'filterUser',
      'filterWorker',
      'partnersLink',
      'reviews',
      'reviewsAverage',
      'workers',
      function ListReviewsController($state, $stateParams, $translate, accountManagers, api, filterOrg, filterUser, filterWorker, partnersLink, reviews, reviewsAverage, workers) {
        const vm = this;

        vm.reviews = reviews.items;
        vm.totalCount = reviews.total_count;
        vm.partnersLink = partnersLink;
        vm.workersMap = _.indexBy(workers.items, 'gid');
        vm.reviewsAverage = reviewsAverage;

        const accountManagersOptions = [
          { value: 'all', displayText: $translate.instant('common.filters.all') }
        ];

        for (const am of accountManagers) {
          accountManagersOptions.push({ value: am.gid, displayText: am.display_full_name });
        }

        vm.filters = {
          worker: filterWorker,
          organization: filterOrg,
          user: filterUser ? filterUser.user : null,
          types: [
            { value: 'all', displayText: 'Tout le monde' },
            { value: 'user', displayText: 'Client' },
            { value: 'worker', displayText: 'Créatif' },
          ],
          workWithAgain: [
            { value: '', displayText: 'Tous' },
            { value: 'no', displayText: 'Non' },
            { value: 'yes', displayText: 'Oui' },
            { value: 'noreply', displayText: 'Pas de réponse' },
          ],
          workWithManagerAgain: [
            { value: '', displayText: 'Tous' },
            { value: 'no', displayText: 'Non' },
            { value: 'yes', displayText: 'Oui' },
            { value: 'noreply', displayText: 'Pas de réponse' },
          ],
          accountManagersOptions,
        };

        vm.filterType = $stateParams.type || 'all';

        vm.applyTypeFilter = function applyOriginFilter() {
          $state.go($state.current.name, { type: vm.filterType });
        };

        vm.filterWorkWithAgain = $stateParams.workWithAgain || '';
        vm.filterWorkWithManagerAgain = $stateParams.workWithManagerAgain || '';

        vm.filterAccountManager = $stateParams.am;

        vm.applyWorkWithAgainFilter = function applyWorkWithAgainFilter() {
          $state.go($state.current.name, { workWithAgain: vm.filterWorkWithAgain });
        };
        vm.applyWorkWithManagerAgainFilter = function applyWorkWithManagerAgainFilter() {
          $state.go($state.current.name, { workWithManagerAgain: vm.filterWorkWithManagerAgain });
        };

        vm.applyAccountManagerFilter = () => {
          $state.go($state.current.name, { am: vm.filterAccountManager });
        };

        vm.getMatchingWorkers = function getMatchingWorkers(search) {
          api
            .call({
              method: 'GET',
              url: '/workers',
              params: {
                query: JSON.stringify(['username', '~=', search]),
                fields: 'username',
              },
            })
            .success((res) => {
              vm.workersResults = res.items;
            });
        };

        vm.getMatchingOrganizations = function getMatchingOrganizations(search) {
          api
            .call({
              method: 'GET',
              url: '/organizations',
              params: {
                search,
                fields: 'name',
              },
            })
            .success((res) => {
              vm.organizationsResults = res.items;
            });
        };

        vm.getMatchingUsers = function getMatchingUsers(search) {
          api
            .call({
              method: 'GET',
              url: '/users',
              params: {
                search,
                fields: 'display_full_name',
              },
            })
            .success((res) => {
              vm.usersResults = res.items;
            });
        };

        vm.filterUpdated = function filterUpdated() {
          $state.go(
            $state.current.name,
            {
              w: vm.filters.worker ? vm.filters.worker.gid : null,
              o: vm.filters.organization ? vm.filters.organization.gid : null,
              u: vm.filters.user ? vm.filters.user.gid : null,
            },
          );
        };
      },
    ]);
}());

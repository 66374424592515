(function () {
  /**
  * Organizations Module
  */
  angular
    .module('partners-bo.dashboard', [
    ])
    .config([
      '$stateProvider',
      function ($stateProvider) {
        $stateProvider

          .state('dashboard', {
            parent: 'root',
            url: '/dashboard?{f}&{o:string}',
            templateUrl: 'dashboard/dashboard.html',
            controller: 'DashboardController',
            controllerAs: 'vm',
            reloadOnSearch: true,
            resolve: [
              {
                token: 'countAndStatsBatch',
                policy: { when: 'LAZY', async: 'NOWAIT' },
                deps: ['api', '$httpParamSerializer'],
                resolveFn(api, $httpParamSerializer) {
                  const batchRequest = [
                    {
                      method: 'GET',
                      relative_url: '/v1/projects/total_count',
                    },
                    {
                      method: 'GET',
                      relative_url: '/v1/users/total_count',
                    },
                    {
                      method: 'GET',
                      relative_url: '/v1/organizations/total_count',
                    },
                    {
                      method: 'GET',
                      relative_url: `/v1/products/total_count?${$httpParamSerializer({ params: 'show_unpriced=1&locale=fr_FR' })}`,
                    },
                  ];
                  return api.call({
                    method: 'POST',
                    url: '/batch',
                    data: batchRequest,
                  }).then((result) => {
                    const data = _.pluck(result.data, 'body');
                    return {
                      projectsCount: JSON.parse(data[0]),
                      usersCount: JSON.parse(data[1]),
                      organizationsCount: JSON.parse(data[2]),
                      productsCount: JSON.parse(data[3]),
                    };
                  });
                },
              },
              {
                token: 'orgsGids',
                deps: ['$stateParams', '$state', 'ipCookie'],
                resolveFn($stateParams, $state, ipCookie) {
                  let orgs = null;

                  // load parameters saved in cookie if possible
                  const orgsFilter = ipCookie('notificationsOrganizationFilter');


                  if ('string' === typeof $stateParams.o) {
                    // if parameters is set, store it in a cookie
                    ipCookie('notificationsOrganizationFilter', $stateParams.o, { expires: 365, expirationUnit: 'days' });
                    orgs = String($stateParams.o);
                  } else if (orgsFilter) {
                    orgs = String(orgsFilter);
                    // add loaded parameters to the current url
                    $state.transitionTo('dashboard', Object.assign($state.params, { o: orgs }), { reload: false });
                  }

                  return orgs;
                },
              },
              {
                token: 'organizations',
                deps: ['orgsGids', 'api'],
                resolveFn(orgsGids, api) {
                  if (orgsGids) {
                    const orgsArray = orgsGids.split(',');
                    return api.call({
                      method: 'GET',
                      url: '/organizations',
                      params: {
                        query: JSON.stringify(['gid', 'in', orgsArray]),
                        fields: 'name,gid',
                      },
                    }).then((res) => res.data);
                  }
                  return { total_count: 0, items: [] };
                },
              },
              {
                token: 'f',
                deps: ['$stateParams', 'ipCookie'],
                resolveFn($stateParams, ipCookie) {
                  let f = $stateParams.f ? JSON.parse($stateParams.f) : null;
                  if (!f) {
                    const userFilter = ipCookie('dashboardNotifsFilter');
                    if (userFilter) {
                      f = userFilter;
                    } else {
                      f = {
                        'project.new': true,
                        'user.new': true,
                        'comment.new': true,
                        'work.new': true,
                        'project.winner.new': true,
                        'credit.breakdown': true,
                      };
                    }
                  }
                  return f;
                },
              },
              {
                token: 'baseNotifQuery',
                deps: ['orgsGids', 'ipCookie', 'f'],
                resolveFn(orgsGids, ipCookie, f) {
                  let query: ConditionQuery = [['OR', []]];
                  ipCookie('dashboardNotifsFilter', f, { expires: 15 });
                  for (const filter in f) {
                    if (f[filter]) {
                      query[0][1].push(['type', '==', filter]);
                      if ('work.new' === filter) {
                        query[0][1].push(['type', '==', 'project.message.delivery.new']);
                      } else if ('comment.new' === filter) {
                        query[0][1].push(['type', '==', 'project.message.new']);
                      } else if ('project.winner.new' === filter) {
                        query[0][1].push(['type', '==', 'project.message.delivery.winner']);
                      }
                    }
                  }
                  if (!query[0][1].length) {
                    query = [['type', '==', 'none']];
                  }

                  if (orgsGids) {
                    const orgsArray = orgsGids.split(',');
                    query.push(['organization.gid', 'in', orgsArray]);
                  }

                  return {
                    method: 'GET',
                    url: '/notifications',
                    params: {
                      fields: 'title,created_at,message,project,type,organization',
                      query: JSON.stringify(query),
                      orderBy: 'created_at:desc',
                      limit: 10,
                      offset: 0,
                    },
                  };
                },
              },
              {
                token: 'apiNotifs',
                deps: ['api', 'baseNotifQuery'],
                resolveFn(api, baseNotifQuery) {
                  return api.call(baseNotifQuery).then((res) => res.data);
                },
              },
            ],
          })
        ;
      }])

    .controller('DashboardController', [
      'NOTIFS',
      '$timeout',
      '$translate',
      '$state',
      'ipCookie',
      '$q',
      'api',
      'partnersLink',
      'f',
      'apiNotifs',
      'baseNotifQuery',
      'countAndStatsBatch',
      'organizations',
      function DashboardController(
        NOTIFS,
        $timeout,
        $translate,
        $state,
        ipCookie,
        $q,
        api,
        partnersLink,
        f,
        apiNotifs,
        baseNotifQuery,
        countAndStatsBatch,
        organizations,
      ) {
        const vm = this;
        vm.baseNotifQuery = baseNotifQuery;
        vm.notifTotalCount = apiNotifs.total_count;
        vm.dashboardNotifications = [];
        vm.organizations = organizations.items;

        vm.NOTIFS = NOTIFS;

        vm.notifFilter = f;

        vm.notifFilters = [
          {
            isOn: f[NOTIFS.TYPE.NEW_PROJECT],
            displayName: $translate.instant('Nouveaux projets'),
            value: NOTIFS.TYPE.NEW_PROJECT,
          },
          {
            isOn: f[NOTIFS.TYPE.NEW_USER],
            displayName: $translate.instant('Nouveaux utilisateurs'),
            value: NOTIFS.TYPE.NEW_USER,
          },
          {
            isOn: f[NOTIFS.TYPE.NEW_COMMENT],
            displayName: $translate.instant('Nouveaux commentaires'),
            value: NOTIFS.TYPE.NEW_COMMENT,
          },
          {
            isOn: f[NOTIFS.TYPE.NEW_WORK],
            displayName: $translate.instant('Nouvelles creations'),
            value: NOTIFS.TYPE.NEW_WORK,
          },
          {
            isOn: f[NOTIFS.TYPE.NEW_WINNER_WORK],
            displayName: $translate.instant('Vainqueurs'),
            value: NOTIFS.TYPE.NEW_WINNER_WORK,
          },
          {
            isOn: f[NOTIFS.TYPE.CREDIT_BREAKDOWN_TYPE],
            displayName: $translate.instant('Crédits ventilés'),
            value: NOTIFS.TYPE.CREDIT_BREAKDOWN_TYPE,
          },
        ];

        countAndStatsBatch.then((result) => {
          vm.projectsCount = result.projectsCount.total_count;
          vm.usersCount = result.usersCount.total_count;
          vm.organizationsCount = result.organizationsCount.total_count;
          vm.productsCount = result.productsCount.total_count;
        });

        vm.updateOrganizationFilter = function updateOrganizationFilter() {
          const orgs = _.pluck(vm.organizations, 'gid');
          ipCookie('notificationsOrganizationFilter', orgs.join(','), { expires: 365, expirationUnit: 'days' });
          $state.go($state.current.name, { o: orgs.join(',') });
        };

        vm.loadOrganizationTags = function loadOrganizationTags(query) {
          const deferred = $q.defer();
          api
            .call({
              url: '/organizations',
              method: 'GET',
              ignoreLoadingBar: true,
              params: {
                search: query,
                fields: 'name',
              },
            })
            .success((res) => {
              deferred.resolve(res.items);
            })
            .error(() => {
              deferred.reject();
            });
          return deferred.promise;
        };

        vm.changeOneFilter = function changeOneFilter(index) {
          vm.notifFilter[vm.notifFilters[index].value] = vm.notifFilters[index].isOn;
          // timeout is needed to avoid ui-router 'superseded' error
          // see http://stackoverflow.com/a/31803834/3275276 for more info
          $timeout(() => {
            $state.go($state.current.name, { f: JSON.stringify(vm.notifFilter) }, { reload: true });
          });
        };

        vm.onCheckAllFilters = function onCheckAllFilters() {
          // timeout is needed to avoid ui-router 'superseded' error
          // see http://stackoverflow.com/a/31803834/3275276 for more info
          $timeout(() => {
            $state.go($state.current.name, {
              f: JSON.stringify({
                'project.new': true,
                'user.new': true,
                'comment.new': true,
                'work.new': true,
                'project.winner.new': true,
                'credit.breakdown': true,
              }),
            }, { reload: true });
          });
        };

        vm.onUncheckAllFilters = function onUncheckAllFilters() {
          // timeout is needed to avoid ui-router 'superseded' error
          // see http://stackoverflow.com/a/31803834/3275276 for more info
          $timeout(() => {
            $state.go($state.current.name, {
              f: JSON.stringify({
                'project.new': false,
                'user.new': false,
                'comment.new': false,
                'work.new': false,
                'project.winner.new': false,
                'credit.breakdown': false,
              }),
            }, { reload: true });
          });
        };

        vm.dashboardNotifications = apiNotifs.items;

        vm.getCssClassForNotif = function getCssClassForNotif(notif) {
          let cssClass = 'alert-primary';
          switch (notif.type) {
            case NOTIFS.TYPE.NEW_USER:
              cssClass = 'alert-info';
              break;
            case NOTIFS.TYPE.NEW_PROJECT:
            /* falls through */
            case NOTIFS.TYPE.NEW_MESSAGE_DELIVERY:
            /* falls through */
            case NOTIFS.TYPE.NEW_WINNER_DELIVERY:
            /* falls through */
            case NOTIFS.TYPE.NEW_WINNER_WORK:
              cssClass = 'alert-danger';
              break;
            case NOTIFS.TYPE.CREDIT_BREAKDOWN_TYPE:
              cssClass = 'alert-success';
              break;
            case NOTIFS.TYPE.NEW_WORK:
            /* falls through */
            case NOTIFS.TYPE.NEW_COMMENT:
            /* falls through */
            default:
              cssClass = 'alert-warning';
              break;
          }

          return cssClass;
        };

        vm.getExternalLinkForNotif = function getExternalLinkForNotif(n) {
          if (!n) {
            return null;
          }
          let link = null;
          if (n.project && n.organization) {
            n.project.organization = n.organization;
          }
          switch (n.type) {
            case NOTIFS.TYPE.NEW_PROJECT:
              link = partnersLink.getProjectUrlByShortCode(n.project.short_code);
              break;
            case NOTIFS.TYPE.NEW_WINNER_WORK:
              n.work.project = n.project;
              link = partnersLink.getWorkUrl(n.work);
              break;
            case NOTIFS.TYPE.NEW_WORK:
              n.work.project = n.project;
              link = partnersLink.getWorkUrl(n.work);
              break;
            case NOTIFS.TYPE.NEW_COMMENT:
              if (n.work) {
                n.work.project = n.project;
                link = partnersLink.getWorkUrl(n.work, n.comment);
              } else {
                link = partnersLink.getProjectUrl(n.project, n.comment);
              }
              break;
            case NOTIFS.TYPE.NEW_MESSAGE_DELIVERY:
              /* falls through */
            case NOTIFS.TYPE.NEW_WINNER_DELIVERY:
              /* falls through */
            case NOTIFS.TYPE.NEW_MESSAGE:
              link = partnersLink.getProjectFeedUrl(n.project, n.project_message);
              break;
            case NOTIFS.TYPE.CREDIT_BREAKDOWN_TYPE:
              link = partnersLink.getOrganizationUrl(n.organization);
              break;
            case NOTIFS.TYPE.NEW_USER:
              /* falls through */
            default:
              link = null;
              break;
          }

          return link;
        };
      },
    ]);
}());

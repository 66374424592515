(function () {
  angular
    .module('partners-bo.components.directives')
    .directive('purchasePrice', [
      PurchasePriceDirective,
    ])
  ;

  function PurchasePriceDirective() {
    function PurchasePriceController(api) {
      const vm = this;

      if (null === vm.editAllowed || 'undefined' === typeof vm.editAllowed) {
        vm.editAllowed = true;
      }
    }

    return {
      scope: {
        price: '=',
        organization: '=',
        product: '=',
        skill: '@',
        mode: '@',
        onPriceChange: '=',
        editAllowed: '=?',
      },
      controller: [
        'api',
        PurchasePriceController,
      ],
      controllerAs: 'vm',
      restrict: 'E',
      templateUrl: '/components/directives/purchase-price.html',
      bindToController: true,
    };
  }
}());

(function () {
  angular
    .module('partners-bo.components.directives')
    .directive('decreasingPrices', [
      DecreasingPricesDirective,
    ])
  ;

  /**
   * @name DecreasingPricesDirective
   * @desc <decreasing-prices></decreasing-prices>
   */
  function DecreasingPricesDirective() {
    function DecreasingPricesController() {
      const vm = this;

      vm.add = function () {
        vm.ngModel.push({ quantity: 0, discount: 0 });
      };

      vm.remove = function (level) {
        vm.ngModel = _.without(vm.ngModel, _.findWhere(vm.ngModel, level));
      };
    }

    return {
      scope: {
        ngModel: '=',
      },
      restrict: 'E',
      controller: [
        DecreasingPricesController,
      ],
      controllerAs: 'vm',
      templateUrl: '/components/directives/decreasing-prices.html',
      bindToController: true,
    };
  }
}());

(function () {
  angular
    .module('partners-bo.workers', [
    ])
    .config([
      '$stateProvider',
      function ($stateProvider) {
        $stateProvider

          .state('worker', {
            parent: 'root',
            url: '/worker/{gid:string}',
            templateUrl: 'workers/worker.html',
            controller: 'ManageWorkerController',
            controllerAs: 'vm',
            resolve: {
              workerAndStatsBatch: [
                '$stateParams',
                '$httpParamSerializer',
                'api',
                function ($stateParams, $httpParamSerializer, api) {
                  const workerParams = {
                    fields: 'avatar,username,full_name',
                  };

                  const batchRequest = [
                    {
                      method: 'GET',
                      relative_url: `/v1/workers/${$stateParams.gid}?${$httpParamSerializer(workerParams)}`,
                    },
                    {
                      method: 'GET',
                      relative_url: `/v1/workers/${$stateParams.gid}/stat`,
                    },
                  ];
                  return api.call({
                    method: 'POST',
                    url: '/batch',
                    data: batchRequest,
                  }).then((result) => {
                    const data = _.pluck(result.data, 'body');

                    return {
                      worker: JSON.parse(data[0]),
                      stats: JSON.parse(data[1]),
                    };
                  });
                },
              ],
              negativeReviews: [
                'api',
                'workerAndStatsBatch',
                function (api, workerAndStatsBatch) {
                  return api.call({
                    method: 'GET',
                    url: '/reviews',
                    params: {
                      query: JSON.stringify([['work_with_again', '==', 0], ['worker.gid', '==', workerAndStatsBatch.worker.gid]]),
                      orderBy: 'created_at:desc',
                      fields: 'created_at,project,public_comment,private_comment',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          });
      },
    ])

    .controller('ManageWorkerController', [
      'workerAndStatsBatch',
      'negativeReviews',
      function ManageWorkerController(workerAndStatsBatch, negativeReviews) {
        const vm = this;

        vm.worker = workerAndStatsBatch.worker;
        vm.stats = workerAndStatsBatch.stats;
        vm.negativeReviews = negativeReviews.items;
      },
    ]);
}());

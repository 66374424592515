(function () {
  angular
    .module('partners-bo.components.directives')
    .directive('searchBar', [
      SearchBarDirective,
    ])
  ;

  /**
   * @name SearchBarDirective
   * @desc <search-bar></search-bar>
   */
  function SearchBarDirective() {
    function SearchBarController($state) {
      const vm = this;
      vm.search = vm.searched || '';

      vm.searchFor = function searchFor(expr) {
        if (expr.length < 1) {
          $state.go($state.current.name, { search: null });
        } else {
          $state.go($state.current.name, { search: expr });
        }
      };

      vm.changeSearch = function changeSearch(event) {
        if (13 == event.keyCode) {
          vm.searchFor(vm.search);
        }
      };
    }

    return {
      scope: {
        searched: '@?',
        placeholder: '@?',
      },
      restrict: 'E',
      controller: [
        '$state',
        SearchBarController,
      ],
      controllerAs: 'vm',
      templateUrl: '/components/directives/search-bar.html',
      bindToController: true,
    };
  }
}());

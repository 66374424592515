(function () {
  angular
    .module('partners-bo.payments', [])

    .controller('AddCreditNoteController', [
      'api',
      'organization',
      'amount',
      'availableCredit',
      'expiresAt',
      function AddCreditNoteController(api, organization, amount, availableCredit, expiresAt) {
        const vm = this;
        vm.organization = organization;
        vm.amount = amount;
        vm.newBalanceAmount = availableCredit + amount;

        const data:{organization:Organization, tax_inclusive_amount?:{}, amount?:{}, expires_at?:string} = {
          organization: vm.organization
        };

        if (vm.organization.tax_inclusive) {
          data.tax_inclusive_amount = { amount: vm.amount };
        } else {
          data.amount = { amount: vm.amount };
        }

        if (expiresAt) {
          data.expires_at = expiresAt;
        }

        vm.addCreditNote = function addCreditNote() {
          return api.call({
            method: 'POST',
            url: '/admin/credit_notes',
            data,
          }).success(() => {
            availableCredit = vm.newBalanceAmount;
            vm.$close();
          }).error((err) => err);
        };
      }],
    );
}());

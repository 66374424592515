(function () {
  /**
  * Organizations Module
  */
  angular
    .module('partners-bo.organizations', [
    ])
    .config([
      '$stateProvider',
      function ($stateProvider) {
        $stateProvider
          .state('organizations', {
            parent: 'root',
            url: '/organizations/list/{index:int}?{limit:int}&{search:string}&{template:string}&{templatesOnly:bool}',
            params: {
              index: 1,
              templatesOnly: false,
            },
            templateUrl: 'organizations/organizations.html',
            controller: 'ListOrganizationsController',
            controllerAs: 'vm',
            resolve: {
              organizations: [
                '$stateParams',
                'api',
                'paginator',
                '$httpParamSerializer',
                function ($stateParams, api, paginator, $httpParamSerializer) {
                  paginator.setMaxItemPage($stateParams.limit);
                  const query = [];

                  if ($stateParams.templatesOnly) {
                    query.push(['template', '==', null], ['is_deleted', '==', false]);
                  }

                  if ($stateParams.template) {
                    const { template } = $stateParams;
                    query.push(['template.gid', '==', template]);
                  }

                  const orgsParams = {
                    search: $stateParams.search || null,
                    query: query.length > 0 ? JSON.stringify(query) : null,
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    showDeleted: true,
                    orderBy: 'modified_at:desc',
                    fields: 'gid,name,owner,is_deleted,stat,payment_status,payment_method,logo,debt_enabled,template,is_template,inherits_from_template,is_deletable,template_ownership_enabled,template_billing_enabled,is_billing_edit_enabled',
                  };

                  return api.call({
                    method: 'GET',
                    url: `/organizations?${$httpParamSerializer(orgsParams)}`,
                  }).then((res) => res.data);
                },
              ],
              templateOrg: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  if (!$stateParams.template) {
                    return null;
                  }
                  return api.call({
                    method: 'GET',
                    url: `/organizations/${$stateParams.template}`,
                    params: {
                      fields: 'gid,name',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('organizations-add', {
            parent: 'root',
            url: '/organizations/add',
            templateUrl: 'organizations/edit.form.html',
            controller: 'ManageOrganizationsController',
            controllerAs: 'vm',
            resolve: [
              {
                token: 'defaultMaxUsers',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/admin/settings/default_max_users',
                  }).then((res) => res.data);
                },
              },
              {
                token: 'organization',
                deps: [
                  'PAYMENT_METHOD',
                  'DEFAULT_REFERRAL',
                  'defaultMaxUsers',
                ],
                resolveFn (PAYMENT_METHOD, DEFAULT_REFERRAL, defaultMaxUsers) {
                  return {
                    email_enabled: true,
                    email_review_disabled: false,
                    owner: null,
                    billing_address: {
                      country: 'FR',
                      siren: null,
                    },
                    billing_contact: [{
                      email: '',
                      phone: '',
                    }],
                    payment_method: PAYMENT_METHOD.ONLINE,
                    tax_inclusive: false,
                    monthly_billing: false,
                    debt_enabled: true,
                    custom_data: {},
                    inherits_from_template: true,
                    is_facebook_enabled: true,
                    referral_amount: DEFAULT_REFERRAL.AMOUNT,
                    referral_enabled: DEFAULT_REFERRAL.ENABLED,
                    external_purchase_order_required: false,
                    is_billing_edit_enabled: true,
                    invoices_due_days: 30,
                    invoices_due_days_type: 'exact',
                    users_count: 0,
                    max_users: defaultMaxUsers.value,
                    maximum_allowed_debt: null,
                    region: null,
                    country: null,
                    favicon: null,
                    notifications_recipients: []
                  };
                },
              },
              {
                token: 'batchResult',
                deps: [
                  'DEFAULT_TEMPLATE_ORG_GID',
                  'api',
                ],
                resolveFn (DEFAULT_TEMPLATE_ORG_GID, api) {
                  return api.call({
                    method: 'GET',
                    url: `/organizations/${DEFAULT_TEMPLATE_ORG_GID}`,
                    params: {
                      fields: 'gid,name,template,billing_contact,billing_address,vat,invoices_terms,tax_inclusive,template_billing_enabled,is_billing_edit_enabled,template_ownership_enabled,owner,referral_enabled,debt_enabled,payment_method,monthly_billing',
                    },
                  }).then((result) => ({
                    owner: {
                      items: [],
                      total_count: 0,
                    },
                    billableProjectsCount: {
                      items: [],
                      total_count: 0,
                    },
                    organizationStats: {
                      balance: {
                        amount: 0,
                        currency: 'EUR',
                      },
                      balance_vat: {
                        amount: 0,
                        currency: 'EUR',
                      },
                      ongoing_chargeable_amount: {
                        amount: 0,
                        currency: 'EUR',
                      },
                      ongoing_chargeable_amount_vat: {
                        amount: 0,
                        currency: 'EUR',
                      },
                      available_credit: {
                        amount: 0.0,
                        currency: 'EUR',
                      },
                      available_credit_vat: {
                        amount: 0.0,
                        currency: 'EUR',
                      },
                      tax_inclusive_available_credit: {
                        amount: 0.0,
                        currency: 'EUR',
                      },
                    },
                    templateOrg: result.data
                  }));
                },
              },
              {
                token: 'blockedEmailDomains',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/settings/blocked_email_domains',
                  }).then((res) => res.data);
                },
              },
            ],
          })

          .state('organization', {
            parent: 'root',
            url: '/organizations/{gid:[0-9a-fA-F]+}',
            templateUrl: 'organizations/organization.html',
            controller: 'ViewOrganizationController',
            controllerAs: 'orgVm',
            resolve: {
              organization: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/organizations/${$stateParams.gid}`,
                    params: {
                      fields: 'billing_address,billing_contact,gid,href,debt_enabled,is_facebook_enabled,logo,name,owner,sageone_contact_url,payment_status,payment_method,vat,template,is_template,inherits_from_template,tax_inclusive,auxiliary_account,is_reseller_children,reseller,monthly_billing,maximum_allowed_debt',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('organization.disabled-products', {
            controller: 'ListDisabledProductsForOrganizationController',
            templateUrl: '/organizations/organization.products.html',
            reloadOnSearch: true,
            controllerAs: 'vm',
            url: '/disabled-products/{index:int}?{limit:int}&{orderBy:string}&{search:string}',
            params: {
              index: 1,
              search: null,
            },
            resolve: {
              products: [
                '$stateParams',
                'paginator',
                'api',
                function ($stateParams, paginator, api) {
                  paginator.setMaxItemPage($stateParams.limit);
                  const params: ParamsQuery = {
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    fields: 'title,gid,category,available_options,is_enabled,is_deleted,default',
                    locale: 'fr_FR',
                  };
                  const query = [];

                  // Todo with https://creads.atlassian.net/browse/CREADS-3140
                  // if ($stateParams.orderBy) {
                  //   params.orderBy = $stateParams.orderBy;
                  // }
                  query.push(['gid', 'exclude', ['organizations.gid', '==', $stateParams.gid]]);

                  if ($stateParams.search) {
                    params.search = $stateParams.search;
                  }

                  params.query = JSON.stringify(query);

                  return api.call({
                    method: 'GET',
                    url: '/products?show_unpriced=1',
                    params,
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('organization.products', {
            controller: 'ListEnabledProductsForOrganizationController',
            templateUrl: '/organizations/organization.products.html',
            controllerAs: 'vm',
            url: '/products/{index:int}?{limit:int}&{orderBy:string}&{search:string}',
            params: {
              index: 1,
              search: null,
            },
            resolve: {
              products: [
                '$stateParams',
                'paginator',
                'api',
                function ($stateParams, paginator, api) {
                  paginator.setMaxItemPage($stateParams.limit);
                  const params: ParamsQuery = {
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    fields: 'title,gid,category,available_options,is_enabled,is_deleted,default',
                    locale: 'fr_FR',
                  };

                  const query = [];

                  // Todo with https://creads.atlassian.net/browse/CREADS-3140
                  // if ($stateParams.orderBy) {
                  //   params.orderBy = $stateParams.orderBy;
                  // }
                  query.push(['organizations.gid', '==', $stateParams.gid]);

                  if ($stateParams.search) {
                    params.search = $stateParams.search;
                  }

                  params.query = JSON.stringify(query);

                  return api.call({
                    method: 'GET',
                    url: '/products?show_unpriced=1',
                    params,
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('organization.auto-matching', {
            controller: 'ListEnabledAutoMatchingController',
            templateUrl: '/organizations/organization.auto-matching.html',
            controllerAs: 'vm',
            url: '/auto-matching/{index:int}?{limit:int}&{orderBy:string}&{search:string}',
            params: {
              index: 1,
              search: null,
            },
            resolve: {
              products: [
                'api',
                'organization',
                function (api, organization) {
                  const params: ParamsQuery = {
                    fields: 'title,gid',
                    locale: 'fr_FR',
                  };

                  return api.call({
                    method: 'GET',
                    url: `/orgs/${organization.gid}/products?show_unpriced=1`,
                    params,
                  }).then((res) => res.data);
                },
              ],
              listings: [
                'api',
                function (api) {
                  const params: ParamsQuery = {
                    fields: 'name,gid',
                  };

                  return api.call({
                    method: 'GET',
                    url: '/listings',
                    params,
                  }).then((res) => res.data);
                },
              ],
              listingsProducts: [
                '$stateParams',
                'paginator',
                'api',
                'organization',
                function ($stateParams, paginator, api, organization) {
                  paginator.setMaxItemPage($stateParams.limit);
                  const params: ParamsQuery = {
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    fields: 'listing,product',
                    orderBy: $stateParams.orderBy || 'modified_at:desc',
                    query: JSON.stringify(['organization.gid', '==', organization.gid]),
                  };

                  // Todo with https://creads.atlassian.net/browse/CREADS-3140
                  // if ($stateParams.orderBy) {
                  //   params.orderBy = $stateParams.orderBy;
                  // }

                  return api.call({
                    method: 'GET',
                    url: '/listings-products',
                    params,
                  }).then((res) => res.data);
                },
              ],
              organizations: [
                'api',
                function (api) {
                  const params: ParamsQuery = {
                    fields: 'name,gid',
                  };

                  return api.call({
                    method: 'GET',
                    url: '/listings',
                    params,
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('organization.excludedWorkers', {
            controller: 'ExcludedWorkerController',
            templateUrl: '/organizations/excluded_workers.html',
            controllerAs: 'vm',
            url: '/excl-workers',
            resolve: {
              excludedWorkers: [
                'api',
                'organization',
                function (api, organization) {
                  return api.call({
                    method: 'GET',
                    url: `/orgs/${organization.gid}/excluded-workers`,
                    params: {
                      fields: 'worker,excluded_by',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('organization.edit-price', {
            controller: 'EditPriceController',
            templateUrl: '/organizations/edit-price.form.html',
            controllerAs: 'vm',
            url: '/products/{productGid}/edit-prices',
            resolve: {
              prices: [
                '$stateParams',
                'api',
                'organization',
                function ($stateParams, api, organization) {
                  return api.call({
                    method: 'GET',
                    url: `/orgs/${organization.gid}/products/${$stateParams.productGid}/prices`,
                    params: {
                      fields: 'gid,amount,purchase_amount,options,auto_close_delay',
                    },
                  }).then((res) => res.data);
                },
              ],
              product: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/products/${$stateParams.productGid}`,
                    params: {
                      fields: 'title,gid,category,available_options,is_enabled,is_deleted,default',
                      locale: 'fr_FR',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })

          .state('organization.sales-rates', {
            controller: 'ProductsPricesController',
            templateUrl: '/organizations/sales-rates.html',
            controllerAs: 'vm',
            url: '/sales-rates',
            resolve: {
              products: [
                'api',
                'organization',
                function (api, organization) {
                  const params: ParamsQuery = {
                    fields: 'title,gid,category,available_options,custom_data',
                    locale: 'fr_FR',
                  };

                  return api.call({
                    method: 'GET',
                    url: `/orgs/${organization.gid}/products?show_unpriced=1&without_limit=1`,
                    params,
                  }).then((res) => res.data.items);
                },
              ],
              prices: [
                '$stateParams',
                'api',
                'organization',
                function ($stateParams, api, organization) {
                  return api.call({
                    method: 'GET',
                    url: `/orgs/${organization.gid}/prices`,
                    params: {
                      fields: 'gid,amount,product,organization,currency,options,auto_close_delay',
                    },
                  }).then((res) => res.data.items);
                },
              ],
            },
          })

          .state('organization.purchase-prices', {
            controller: 'ProductsPricesController',
            templateUrl: '/organizations/purchase-prices.html',
            controllerAs: 'vm',
            url: '/purchase-prices',
            resolve: {
              products: [
                'api',
                'organization',
                function (api, organization) {
                  const params: ParamsQuery = {
                    fields: 'title,gid,category,available_options',
                    locale: 'fr_FR',
                  };

                  return api.call({
                    method: 'GET',
                    url: `/orgs/${organization.gid}/products?show_unpriced=1&without_limit=1`,
                    params,
                  }).then((res) => res.data.items);
                },
              ],
              prices: [
                '$stateParams',
                'api',
                'organization',
                function ($stateParams, api, organization) {
                  return api.call({
                    method: 'GET',
                    url: `/orgs/${organization.gid}/prices`,
                    params: {
                      fields: 'gid,amount,product,organization,currency,options,auto_close_delay,purchase_amount,purchase_ratio',
                    },
                  }).then((res) => res.data.items);
                },
              ],
            },
          })

          .state('organizations-edit', {
            abstract: true,
            parent: 'root',
            templateUrl: 'organizations/edit.html',
          })

          .state('organizations-edit.views', {
            url: '/organizations/{id}/edit',
            views: {
              edit: {
                templateUrl: 'organizations/edit.form.html',
                controller: 'ManageOrganizationsController',
                controllerAs: 'vm',
              },
            },
            resolve: [
              {
                token: 'organization',
                deps: [
                  '$stateParams',
                  'api',
                ],
                resolveFn ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/organizations/${$stateParams.id}`,
                    params: {
                      fields: 'billing_address,billing_contact,gid,href,debt_enabled,is_facebook_enabled,logo,name,owner,sageone_contact_url,payment_status,payment_method,test,vat,template,custom_data,inherits_from_template,email_enabled,additional_meta,tax_inclusive,invoices_terms,referral_enabled,referral_amount,is_billing_edit_enabled,template_billing_enabled,template_ownership_enabled,default_team,external_purchase_order_required,invoices_due_days,invoices_due_days_type,invoices_due_day_of_month,reseller,hidden_price,monthly_billing,region,country,maximum_allowed_debt,favicon,users_count,max_users,notifications_recipients,email_review_disabled',
                    },
                  }).then((res) => res.data);
                },
              },
              {
                token: 'batchResult',
                deps: [
                  'api',
                  'organization',
                  'PROJECT',
                  '$httpParamSerializer',
                ],
                resolveFn (api, organization, PROJECT, $httpParamSerializer) {
                  const projectsQueryParam = $httpParamSerializer({ query: JSON.stringify([['organization.gid', '==', organization.gid], ['state', '==', PROJECT.STATE.PUBLISHED]]) });
                  const orgStatsQueryParams = $httpParamSerializer({ showDeleted: true });
                  const batchRequest = [
                    {
                      method: 'GET',
                      relative_url: `/v1/projects/total_count?${projectsQueryParam}`,
                    },
                    {
                      method: 'GET',
                      relative_url: `/v1/orgs/${organization.gid}/stat?${orgStatsQueryParams}`,
                    },
                  ];

                  if (organization.template) {
                    batchRequest.push({
                      method: 'GET',
                      relative_url: `/v1/organizations/${organization.template.gid}?fields=gid,name,template,is_billing_edit_enabled,template_billing_enabled,billing_contact,billing_address,vat,invoices_terms,tax_inclusive,template_ownership_enabled,owner,referral_enabled,debt_enabled,payment_method,reseller,hidden_price,monthly_billing`,
                    });
                  }

                  return api.call({
                    method: 'POST',
                    url: '/batch',
                    data: batchRequest,
                  }).then((result) => {
                    const data = _.pluck(result.data, 'body');

                    return {
                      billableProjectsCount: JSON.parse(data[0]),
                      organizationStats: JSON.parse(data[1]),
                      templateOrg: data[2] ? JSON.parse(data[2]) : null,
                    };
                  });
                },
              },
              {
                token: 'blockedEmailDomains',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/settings/blocked_email_domains',
                  }).then((res) => res.data);
                },
              },
            ],
            onEnter: [
              '$state',
              '$translate',
              'notifications',
              'organization',
              function ($state, $translate, notifications, organization) {
                if (organization.is_deleted) {
                  notifications.showError({
                    message: $translate.instant('L\'organisation n\'existe pas.'),
                  });
                  $state.go('organizations', { index: 1 });
                }
              },
            ],
          })
      },
    ])

    .controller('ListOrganizationsController', [
      'PAYMENT_STATUS',
      '$translate',
      '$state',
      '$stateParams',
      '$uibModal',
      'ipCookie',
      'api',
      'notifications',
      'partnersLink',
      'template',
      'organizations',
      'templateOrg',
      'ROLES',
      'currentUser',
      function ListOrganizationsController(
        PAYMENT_STATUS,
        $translate,
        $state,
        $stateParams,
        $uibModal,
        ipCookie,
        api,
        notifications,
        partnersLink,
        template,
        organizations,
        templateOrg,
        ROLES,
        currentUser,
    ) {
        const vm = this;

        vm.organizations = organizations.items;
        vm.totalCount = organizations.total_count;
        vm.template = template;
        vm.PAYMENT_STATUS = PAYMENT_STATUS;
        vm.checkAll = false;
        vm.checkedElements = [];
        vm.templateOrgsOnly = $stateParams.templatesOnly;
        vm.templateOrgs = [];
        vm.templateOrg = templateOrg;
        vm.editAllowed = currentUser.roles.includes(ROLES.SUPER_ADMIN) ||
            currentUser.roles.includes(ROLES.SALES_ADMIN);

        if (ipCookie('partners.organizations.selection')) {
          vm.checkedElements = ipCookie('partners.organizations.selection');
          vm.checkedElements.forEach((gid) => {
            const elem = _.findWhere(vm.organizations, { gid });
            if (elem && elem.template) {
              elem.selected = true;
            }
          });
          updateGlobalCheckbox();
        }

        vm.globalActions = [
          {
            id: 'none',
            label: '--',
            callback() {},
          },
          {
            id: 'applyTemplate',
            label: 'Associer à une orga template',
            callback(gids) {
              $uibModal.open({
                templateUrl: '/organizations/confirm_apply_template_modal.html',
                controller: [
                  'notifications',
                  '$translate',
                  'api',
                  'gids',
                  'notSupportedOrgs',
                  function ConfirmApplyTemplateController(notifications, $translate, api, gids, notSupportedOrgs) {
                    const $confirmCtrl = this;

                    notSupportedOrgs = notSupportedOrgs.items;
                    $confirmCtrl.targetTemplate = null;
                    $confirmCtrl.totalCount = gids.length;
                    $confirmCtrl.templates = [];

                    const supported = [];

                    gids.forEach((gid) => {
                      if (!_.findWhere(notSupportedOrgs, { gid })) {
                        supported.push(gid);
                      }
                    });
                    $confirmCtrl.count = supported.length;

                    $confirmCtrl.getMatchingTemplates = function getMatchingTemplates(search) {
                      if (!search) {
                        $confirmCtrl.templates = [];
                        return;
                      }

                      return api
                        .call({
                          method: 'GET',
                          url: '/organizations',
                          params: {
                            search,
                            query: JSON.stringify(['template', '==', null]),
                            fields: 'name',
                          },
                        })
                        .then((res) => {
                          $confirmCtrl.templates = res.data.items;
                        });
                    };

                    $confirmCtrl.confirm = function confirm() {
                      const batchContent = [];

                      let batchIterator = 0;
                      supported.forEach((gid) => {
                        batchContent[batchIterator] = batchContent[batchIterator] || [];
                        batchContent[batchIterator].push({
                          method: 'PUT',
                          relative_url: `/v1/organizations/${gid}`,
                          body: {
                            template: {
                              gid: $confirmCtrl.targetTemplate.gid,
                            },
                            resync_with_template: true,
                          },
                        });
                        if (10 === batchContent[batchIterator].length) {
                          batchIterator += 1;
                        }
                      });

                      let successes = 0;
                      for (let index = 0; index < batchContent.length; index++) {
                        api
                          .call({
                            method: 'POST',
                            url: '/batch',
                            data: batchContent[index],
                          })
                          .then((res) => {
                            const errors = _.find(res.data, (response) => response.code > 399);
                            if (errors) {
                              notifications.showError({
                                message: $translate.instant('common.error.action_fail'),
                              });
                            } else {
                              successes++;
                              if (batchContent.length === successes) {
                                notifications.showSuccess({
                                  message: $translate.instant('common.error.action_pending'),
                                });
                                $confirmCtrl.$close();
                              }
                            }
                          }, () => {
                            notifications.showError({
                              message: $translate.instant('common.error.action_fail'),
                            });
                          });
                      }
                    };
                  },
                ],
                controllerAs: '$confirmCtrl',
                bindToController: true,
                resolve: {
                  gids() {
                    return gids;
                  },
                  totalCount: vm.totalCount,
                  notSupportedOrgs: [
                    'api',
                    function (api) {
                      return api
                        .call({
                          method: 'GET',
                          url: '/organizations',
                          params: {
                            showDeleted: true,
                            query: JSON.stringify(['OR', [['is_deleted', '==', true], ['template', '==', null]]]),
                            fields: 'gid',
                          },
                        })
                        .then((res) => res.data);
                    },
                  ],
                },
              });
            },
          },
        ];
        vm.globalAction = vm.globalActions[0];

        vm.refreshTemplateFilter = function refreshTemplateFilter() {
          $state.go($state.current.name, { templatesOnly: vm.templateOrgsOnly });
        };

        function updateGlobalCheckbox() {
          vm.checkAll = _.every(vm.organizations, (elem) => elem.selected);
        }

        vm.checkAllChanged = function checkAllChanged() {
          if (vm.checkAll) {
            vm.organizations.forEach((elem) => {
              if (elem.template) {
                elem.selected = true;
                if (!vm.checkedElements.includes(elem.gid)) {
                  vm.checkedElements.push(elem.gid);
                }
              }
            });
          } else {
            vm.organizations.forEach((elem) => {
              elem.selected = false;
              const existingIndex = vm.checkedElements.indexOf(elem.gid);
              if (existingIndex > -1) {
                vm.checkedElements.splice(existingIndex, 1);
              }
            });
          }
          ipCookie('partners.organizations.selection', vm.checkedElements);
        };

        vm.checkOne = function checkOne(organization) {
          if (organization.selected) {
            if (!vm.checkedElements.includes(organization.gid)) {
              vm.checkedElements.push(organization.gid);
            }
          } else {
            const existingIndex = vm.checkedElements.indexOf(organization.gid);
            if (existingIndex > -1) {
              vm.checkedElements.splice(existingIndex, 1);
            }
          }
          updateGlobalCheckbox();
          ipCookie('partners.organizations.selection', vm.checkedElements);
        };

        vm.uncheckAll = function uncheckAll() {
          vm.checkAll = false;
          vm.organizations.forEach((elem) => {
            elem.selected = false;
          });
          vm.checkedElements = [];
          ipCookie('partners.organizations.selection', vm.checkedElements);
        };

        vm.applyGlobalAction = function applyGlobalAction() {
          vm.globalAction.callback(vm.checkedElements);
        };

        /**
         * [remove]
         * @param  {object} organization
         * @return {void}
         */
        vm.remove = function remove(organization) {
          api
            .call({
              method: 'DELETE',
              url: `/organizations/${organization.gid}`,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('L\'organisation a bien été supprimée.'),
              });
              organization.is_deleted = true;
            })
            .error((response) => {
              switch (response.error.code) {
                case 400:
                  if (response.error.exception) {
                    response.error.exception.forEach((exception) => {
                      if (exception.message.includes('You cannot enable or disable organisation')) {
                        notifications.showError({
                          message: $translate.instant('organizations.edit.notifications.error.toggle_delete'),
                        });
                        // dirty but only way to break foreach
                        throw $translate.instant('organizations.edit.notifications.error.toggle_delete');
                      }
                    });
                  }
                  break;
                default:
                  notifications.showError({
                    message: $translate.instant('organizations.edit.notifications.error.delete'),
                  });
                  break;
              }
            })
          ;
        };

        vm.undelete = function undelete(organization) {
          api
            .call({
              method: 'PUT',
              url: `/organizations/${organization.gid}`,
              params: {
                showDeleted: true,
              },
              data: {},
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('L\'organisation a bien été réactivée.'),
              });
              organization.is_deleted = false;
            })
            .error((response) => {
              let displayError = (message) => {
                if (message.includes('You cannot enable or disable organisation')) {
                  notifications.showError({
                    message: $translate.instant('organizations.edit.notifications.error.toggle_delete'),
                  });
                  // dirty but only way to break foreach
                  throw $translate.instant('organizations.edit.notifications.error.toggle_delete');
                }
              };
              switch (response.error.code) {
                case 400:
                  // exceptions are only throw in dev environment
                  if (response.error.exception) {
                    response.error.exception.forEach((exception) => {
                      displayError(exception.message);
                    });
                  } else {
                    displayError(response.error.message);
                  }
                  break;
                default:
                  notifications.showError({
                    message: $translate.instant('organizations.edit.notifications.error.reactivate'),
                  });
                  break;
              }
            });
        };

        vm.getAppExternalLink = function getAppExternalLink(organization) {
          const gotourl = partnersLink.getOrganizationUrl(organization);
          if (gotourl && 'string' === typeof gotourl) {
            return gotourl;
          }
          return '';
        };

        vm.getMatchingTemplateOrgs = function getMatchingTemplateOrgs(search) {
          if ('' === search) {
            return;
          }
          return api.call({
            method: 'GET',
            url: '/organizations',
            params: {
              query: JSON.stringify([['template', '==', null]]),
              search,
              fields: 'name',
            },
          }).then((response) => {
            vm.templateOrgs = response.data.items;
          });
        };

        vm.templateChanged = function templateChanged() {
          $state.go($state.current.name, { template: vm.templateOrg ? vm.templateOrg.gid : null });
        };
      },
    ])

    .controller('ManageOrganizationsController', [
      'DEFAULT_ORGANIZATION_OWNER',
      'INVOICE_DUE_DAY_TYPE',
      'PAYMENT_METHOD',
      'PAYMENT_STATUS',
      'ROLES',
      '$state',
      '$translate',
      '$uibModal',
      '$interval',
      'api',
      'batchResult',
      'dataGouv',
      'notifications',
      'organization',
      'partnersVat',
      'stringHelper',
      'currentUser',
      'blockedEmailDomains',
      function ManageOrganizationsController(
        DEFAULT_ORGANIZATION_OWNER,
        INVOICE_DUE_DAY_TYPE,
        PAYMENT_METHOD,
        PAYMENT_STATUS,
        ROLES,
        $state,
        $translate,
        $uibModal,
        $interval,
        api,
        batchResult,
        dataGouv,
        notifications,
        organization,
        partnersVat,
        stringHelper,
        currentUser,
        blockedEmailDomains,
      ) {
        const vm = this;
        const existingVatNumber = !!organization.vat;

        vm.ROLES = ROLES;
        vm.INVOICE_DUE_DAY_TYPE = INVOICE_DUE_DAY_TYPE;
        vm.PAYMENT_STATUS = PAYMENT_STATUS;
        vm.organization = organization;
        vm.users = [];
        vm.organization.logo = vm.organization.logo ? [vm.organization.logo] : [];
        vm.orgLogo = [];
        vm.orgFavicon = null;
        vm.uploaderValidate = {
          pattern: '.jpg,.gif,.jpeg,.png',
          accept: 'image/jpg,image/jpeg,image/png,image/gif',
          size: { max: '8MB' },
        };
        vm.uploadError = {};
        vm.faviconUploaderValidate = {
          pattern: '.ico',
          size: { max: '1MB' },
        };
        vm.faviconUploadError = {};
        vm.organizationStats = batchResult.organizationStats;
        vm.balance = null;
        vm.availableCredit = null;
        vm.partnersVat = partnersVat;
        vm.invoices_due_days_type = [
          { value: INVOICE_DUE_DAY_TYPE.EXACT, name: $translate.instant('organizations.edit.payment.invoices_due_days_type.exact') },
          { value: INVOICE_DUE_DAY_TYPE.END_OF_MONTH, name: $translate.instant('organizations.edit.payment.invoices_due_days_type.end_of_month') },
          { value: INVOICE_DUE_DAY_TYPE.AT_X_OF_MONTH, name: $translate.instant('organizations.edit.payment.invoices_due_days_type.at_x_of_month') },
        ];
        vm.templateEditAllowed = currentUser.roles.includes(ROLES.PRICING_ADMIN);

        vm.organization.is_billing_edit_enabled = !!(vm.organization.is_billing_edit_enabled);
        vm.organization.referral_amount = vm.organization.referral_amount || 1;
        vm.organization.referral_enabled = !!(vm.organization.referral_enabled);
        vm.organization.template_billing_enabled = !!(vm.organization.template_billing_enabled);
        vm.organization.template_ownership_enabled = !!(vm.organization.template_ownership_enabled);
        const blockedEmailDomainsValue = blockedEmailDomains.value;

        // Because empty objet is returned as [] by API, we want to force it to {}
        vm.organization.custom_data = (
          !_.isObject(vm.organization.custom_data)
          || vm.organization.custom_data instanceof Array
        )
          ? {}
          : vm.organization.custom_data;

        if (vm.organization.tax_inclusive) {
          vm.balance = vm.organizationStats.tax_inclusive_balance.amount;
          vm.availableCredit = vm.organizationStats.tax_inclusive_available_credit.amount;
        } else {
          vm.balance = vm.organizationStats.balance.amount;
          vm.availableCredit = vm.organizationStats.available_credit.amount;
        }

        if (vm.organization.logo[0]) {
          vm.orgLogo = [angular.copy(vm.organization.logo[0])];
        }
        if (vm.organization.favicon) {
          vm.organization.favicon = {url: vm.organization.favicon.url};
          vm.orgFavicon = {
            url: vm.organization.favicon.url || null
          }
        }

        vm.organization.test = vm.organization.test || false;

        if (!vm.organization.additional_meta || 0 === vm.organization.additional_meta.length) {
          vm.organization.additional_meta = {};
        }

        if (!vm.organization.additional_meta.bottom_line) {
          vm.organization.additional_meta.bottom_line = '';
        }

        vm.billableProjectsCount = batchResult.billableProjectsCount.total_count;
        vm.templateOrg = batchResult.templateOrg;

        if (vm.templateOrg) {
          vm.templateOrgs = [vm.templateOrg];
        }

        vm.hasSpecificAgreement = !!vm.organization.custom_data.specific_agreement;

        vm.availablePayments = [
          PAYMENT_METHOD.ONLINE,
          PAYMENT_METHOD.OFFLINE,
          PAYMENT_METHOD.SMART_TRESO,
        ];

        vm.notificationsRecipients = vm.organization.notifications_recipients.map(email => ({email}));

        vm.addCreditNote = function addCreditNote(amount, expiresAt) {
          if (amount) {
            const creditModal = $uibModal.open({
              templateUrl: '/payments/add_credit_modal.html',
              controller: 'AddCreditNoteController',
              controllerAs: '$creditNoteVm',
              bindToController: true,
              resolve: {
                organization() {
                  return vm.organization;
                },
                amount,
                availableCredit: vm.availableCredit,
                expiresAt: null
              },
            });

            creditModal.closed.then(() => {
              vm.credit_note_amount = null;
              vm.credit_note_expires_at = null;
            });
          }
        };

        vm.changeCreditAmount = function changeCreditAmount() {
          const min = 1.00;
          const max = 9999999999.99;

          if (vm.credit_note_amount < min) {
            vm.credit_note_amount = min;
          } else if (vm.credit_note_amount > max) {
            vm.credit_note_amount = max;
          }
        };

        vm.changeSpecificAgreement = function changeSpecificAgreement() {
          if (false === vm.hasSpecificAgreement) {
            vm.organization.custom_data.specific_agreement = null;
          }
        };

        vm.getMatchingUsers = function getMatchingUsers(searchQuery) {
          if ('' === searchQuery) {
            return;
          }
          api.call({
            method: 'GET',
            url: '/users',
            params: {
              search: searchQuery,
              fields: 'email,display_full_name',
            },
          }).then((response) => {
            vm.users = response.data.items;
          });
        };

        vm.getMatchingTemplateOrgs = function getMatchingTemplateOrgs(search) {
          if ('' === search) {
            return;
          }
          api.call({
            method: 'GET',
            url: '/organizations',
            params: {
              query: JSON.stringify([['template.gid', '==', null]]),
              search,
              fields: 'name,template,billing_contact,billing_address,vat,invoices_terms,tax_inclusive,template_billing_enabled,is_billing_edit_enabled,template_ownership_enabled,owner,referral_enabled,debt_enabled,payment_method,monthly_billing',
            },
          }).then((response) => {
            vm.templateOrgs = response.data.items;
          });
        };

        if (vm.templateOrg
          && vm.templateOrg.template_billing_enabled
          && vm.organization.inherits_from_template) {
          vm.organization.billing_address = vm.templateOrg.billing_address;
          vm.organization.billing_contact = vm.templateOrg.billing_contact;
          vm.organization.vat = vm.templateOrg.vat;
          vm.organization.invoices_terms = vm.templateOrg.invoices_terms;
          vm.organization.tax_inclusive = vm.templateOrg.tax_inclusive;
          vm.organization.monthly_billing = vm.templateOrg.monthly_billing;
        }

        if (vm.templateOrg && vm.templateOrg.template_ownership_enabled) {
          vm.organization.owner = vm.templateOrg.owner;
        }

        vm.onAddLogo = function onAddLogo() {
          return function (file) {
            vm.organization.logo = [file];
            vm.orgLogo = vm.organization.logo;
          };
        };

        vm.onAddFavicon = function onAddFavicon() {
          return function (file) {
            vm.organization.favicon = {url: file.url};
            vm.orgFavicon = vm.organization.favicon;
          };
        };

        vm.onRemoveLogo = function onRemoveLogo() {
          if (!vm.organization.gid) {
            return;
          }

          api
            .call({
              method: 'PUT',
              url: `/organizations/${vm.organization.gid}`,
              data: {
                logo: null,
              },
            })
            .success(() => {
              vm.organization.logo = [];
            })
            .error(() => {
              notifications.showError({
                message: $translate.instant('Le logo n\'a pas pu être supprimé.'),
              });
            });
        };

        vm.vatChanged = function vatChanged() {
          if (!vm.partnersVat.countryIsFrench(vm.organization.billing_address.country)) {
            return;
          }

          if ('string' === typeof vm.organization.vat) {
            const vat = vm.organization.vat.replace(/\s/g, '');

            if (existingVatNumber && !vat) {
              vm.form.vat.$setValidity('notRemoveExistingVAT', false);
            } else {
              vm.form.vat.$setValidity('notRemoveExistingVAT', true);
              const country = angular.copy(vm.organization.billing_address.country);
              api
                .call({
                  method: 'POST',
                  url: '/orgs/check-vat',
                  data: {
                    vat: vat || null,
                    country,
                  },
                })
                .success(() => {
                  vm.organization.vat = vat;
                  vm.form.vat.$setValidity('validEuroTax', true);
                })
                .error(() => {
                  vm.organization.vat = vat;
                  vm.form.vat.$setValidity('validEuroTax', false);
                });
            }
          }
        };

        vm.sirenChanged = async () => {
          if (!vm.partnersVat.countryIsFrench(vm.organization.billing_address.country)) {
            return;
          }

          if (vm.organization.billing_address.siren) {
            const vat = await dataGouv.getIntraCommunityVatNumberBySiren(
              vm.organization.billing_address.siren,
            );

            if (vat && 12 === vat.length) {
              // Force to add 0 for FR Vat getting from dataGouv see https://creads.atlassian.net/browse/CREADS-2647
              vm.organization.vat = `${vat.slice(0, 2)}0${vat.slice(2)}`;
            }

            if (vat && vat.length > 12) {
              const country = angular.copy(vm.organization.billing_address.country);

              vm.organization.vat = vat;

              api
                .call({
                  method: 'POST',
                  url: '/orgs/check-vat',
                  data: {
                    vat,
                    country,
                  },
                })
                .success(() => {
                  vm.form.vat.$setValidity('validEuroTax', true);
                })
                .error(() => {
                  vm.form.vat.$setValidity('validEuroTax', false);
                });
            }
          }
        };

        vm.countryChanged = function countryChanged() {
          if (!vm.partnersVat.countryIsFrench(vm.organization.billing_address.country)) {
            vm.organization.tax_inclusive = false;
            vm.organization.billing_address.siren = null;
          }
          if (vm.partnersVat.countryIsOutOfEU(vm.organization.billing_address.country)) {
            vm.organization.vat = null;
          }
        };

        vm.templateChanged = function templateChanged() {
          if (vm.templateOrg) {
            return api.call({
              method: 'GET',
              url: `/organizations/${vm.templateOrg.gid}`,
              params: {
                fields: 'billing_contact,billing_address,vat,invoices_terms,tax_inclusive,owner,monthly_billing',
              },
            }).then((response) => {
              if (vm.organization.inherits_from_template) {
                vm.organization.additional_meta = vm.templateOrg.additional_meta;
                vm.organization.custom_data = vm.templateOrg.custom_data;
                vm.organization.debt_enabled = vm.templateOrg.debt_enabled;
                vm.organization.email_enabled = vm.templateOrg.email_enabled;
                vm.organization.email_review_disabled = vm.templateOrg.email_review_disabled;
                vm.organization.is_facebook_enabled = vm.templateOrg.is_facebook_enabled;
                vm.organization.payment_method = vm.templateOrg.payment_method;

                vm.organization.is_billing_edit_enabled = !(vm.templateOrg.template_billing_enabled);

                if (vm.templateOrg.template_billing_enabled) {
                  vm.organization.billing_address = response.data.billing_address;
                  vm.organization.billing_contact = response.data.billing_contact;
                  vm.organization.invoices_terms = response.data.invoices_terms;
                  vm.organization.tax_inclusive = response.data.tax_inclusive;
                  vm.organization.vat = response.data.vat;
                  vm.organization.monthly_billing = vm.templateOrg.monthly_billing;
                }
                if (vm.templateOrg.template_ownership_enabled) {
                  vm.organization.owner = response.data.owner;
                  vm.onOwnerChanged();
                }
              }
            });
          }
        };

        vm.changeReferralAmount = function changeReferralAmount() {
          vm.organization.referral_amount = vm.organization.referral_enabled ? 10 : null;
        };

        vm.emailIsUnique = null;

        vm.checkEmailDuplicate = function checkEmailDuplicate(input: string, index: number) {
          const filteredContactEmail = vm.organization.billing_contact
            .filter((el, id) => el && index !== id)
          ;
          vm.form.contact_email.$setValidity('unique', true);
          vm.emailIsUnique = true;

          if (_.findWhere(filteredContactEmail, { email: input })) {
            vm.form.contact_email.$setValidity('unique', false);
            vm.emailIsUnique = false;
          }
        };

        vm.addBillingContactEmail = function addBillingContactEmail() {
          vm.organization.billing_contact.push({ email: '' });
        };

        vm.addNotificationsRecipient = () => {
          vm.notificationsRecipients.push({email: ''});
        };

        vm.removeBillingContactEmail = function removeBillingContactEmail($index: number) {
          vm.organization.billing_contact.splice($index, 1);
          vm.checkEmailDuplicate();
        };

        vm.removeNotificationsRecipient = (index: number) => {
          vm.notificationsRecipients.splice(index, 1);
        };

        vm.onDebtEnabledChanged = () => {
          if (!vm.organization.debt_enabled) {
            vm.organization.maximum_allowed_debt = null;
          }
          return;
        };

        const formatTeams = (teams) => {
          if (!teams) {
            return;
          }

          teams.forEach((team) => {
            // we format teams to give sales staff infos about the default team they wanna choose
            // it will look like this
            // TeamName - (budget: teamBudget - permissionsCount/permissionsTotalCount)
            const formattedTeam = team;
            formattedTeam.permissions_count = Object.keys(team.permissions).length;

            if (!team.budget) {
              formattedTeam.budget_string = $translate.instant('organizations.edit.template.budget.unlimited');
              return formattedTeam;
            }

            formattedTeam.budget_string = `${team.budget.amount} EUR`
              || $translate.instant('organizations.edit.template.budget.restricted')
            ;

            return formattedTeam;
          });
        };

        const fetchTeams = async () => {
          if (vm.organization.gid) {
            ({ items: vm.teams, total_count: vm.totalCount } = (await api
              .call({
                method: 'GET',
                url: `/orgs/${vm.organization.gid}/teams`,
                params: {
                  showDeleted: true,
                  fields: 'name,permissions,budget',
                },
              })).data);
            const teamsToFormat = vm.organization.default_team ? [...vm.teams, vm.organization.default_team] : vm.teams;
            formatTeams(teamsToFormat);
          }
        };

        fetchTeams();

        vm.onResellerChange = () => {
          if (!vm.organization.reseller) {
            vm.organization.hidden_price = false;
          }

          vm.organization.template_billing_enabled = vm.organization.reseller
            ? true
            : vm.organization.template_billing_enabled
          ;

          return;
        };

        vm.onChangeName = () => {
          vm.form.name.$setValidity('duplicate', true);
        };

        /**
         * [submit]
         * @return {void}
         */
        vm.submit = function submit() {
          if (!vm.organization.owner) {
            // By default, Admin Creads is the owner
            vm.organization.owner = {
              gid: DEFAULT_ORGANIZATION_OWNER,
            };
          }

          vm.onMaxUsersChanged();

          if (vm.form.$submitted && vm.form.$valid) {
            // angular.copy doesnt get nested reference
            let orgaToSend = { ...vm.organization };
            orgaToSend.logo = (orgaToSend.logo && orgaToSend.logo.length) ? orgaToSend.logo[0] : null;
            vm.partnersVat.countryIsOutOfEU(vm.organization.billing_address.country);
            orgaToSend.notifications_recipients = _.pluck(vm.notificationsRecipients, 'email');

            orgaToSend.vat = orgaToSend.vat || null;

            if (vm.templateOrg) {
              if (vm.organization.inherits_from_template) {
                orgaToSend = _.pick(orgaToSend,
                  'billing_address',
                  'billing_contact',
                  'invoices_terms',
                  'logo',
                  'name',
                  'owner',
                  'payment_method',
                  'tax_inclusive',
                  'test',
                  'vat',
                  'external_purchase_order_required',
                  'invoices_due_days',
                  'invoices_due_days_type',
                  'invoices_due_day_of_month',
                  'max_users',
                  'hidden_price',
                  'monthly_billing',
                  'maximum_allowed_debt',
                  'country',
                  'region',
                  'notifications_recipients');
              } else {
                orgaToSend = _.pick(orgaToSend,
                  'additional_meta',
                  'billing_address',
                  'billing_contact',
                  'custom_data',
                  'debt_enabled',
                  'email_enabled',
                  'email_review_disabled',
                  'invoices_terms',
                  'is_facebook_enabled',
                  'logo',
                  'name',
                  'owner',
                  'payment_method',
                  'referral_amount',
                  'referral_enabled',
                  'tax_inclusive',
                  'default_member_role',
                  'test',
                  'vat',
                  'external_purchase_order_required',
                  'invoices_due_days',
                  'invoices_due_days_type',
                  'invoices_due_day_of_month',
                  'max_users',
                  'maximum_allowed_debt',
                  'reseller',
                  'hidden_price',
                  'monthly_billing',
                  'country',
                  'region',
                  'notifications_recipients');
              }
            } else {
              orgaToSend = _.pick(orgaToSend,
                'additional_meta',
                'billing_address',
                'billing_contact',
                'custom_data',
                'debt_enabled',
                'email_enabled',
                'email_review_disabled',
                'invoices_terms',
                'is_facebook_enabled',
                'logo',
                'name',
                'owner',
                'payment_method',
                'referral_amount',
                'referral_enabled',
                'tax_inclusive',
                'template_billing_enabled',
                'template_ownership_enabled',
                'default_member_role',
                'test',
                'vat',
                'default_team',
                'external_purchase_order_required',
                'invoices_due_days',
                'invoices_due_days_type',
                'invoices_due_day_of_month',
                'max_users',
                'maximum_allowed_debt',
                'reseller',
                'hidden_price',
                'monthly_billing',
                'country',
                'region',
                'favicon',
                'notifications_recipients');
            }

            if (INVOICE_DUE_DAY_TYPE.AT_X_OF_MONTH !== orgaToSend.invoices_due_days_type) {
              orgaToSend.invoices_due_day_of_month = null;
            }

            if (vm.templateOrg && vm.templateOrg.template_ownership_enabled) {
              orgaToSend = _.omit(orgaToSend,
                'owner');
            }

            if (vm.templateOrg && vm.templateOrg.template_billing_enabled) {
              orgaToSend = _.omit(orgaToSend,
                [
                  'billing_address',
                  'billing_contact',
                  'tax_inclusive',
                  'vat',
                  'invoices_terms',
                  'monthly_billing',
                ]);
            }

            orgaToSend.template = vm.templateOrg;

            // We use this method to count the string length in bytes,
            // in order to stay consistent with API validation
            if (stringHelper.byteLength(vm.organization.name) > 50) {
              vm.form.name.$setValidity('maxlength', false);
            }

            api
              .call({
                method: (vm.organization.gid) ? 'PUT' : 'POST',
                url: (vm.organization.gid) ? `/organizations/${vm.organization.gid}` : '/organizations',
                data: orgaToSend,
              })
              .success((bodyIgnored, statusIgnored, headers) => {
                notifications.showSuccess({
                  message: $translate.instant('L’organisation a bien été modifiée.'),
                });
                const orgGid = vm.organization.gid || headers('Location').substr('/v1/organizations/'.length);
                $state.go('organization', { gid: orgGid });
              })
              .error((response) => {
                if (400 === response.error.code) {
                  let displayError = (message) => {
                    if (message.includes('Another organization with the same name')) {
                      vm.form.name.$setValidity('duplicate', false);
                      $('#input-name').focus();
                    }
                    if (message.includes('You cannot edit payment method')) {
                      notifications.showError({
                        message: $translate.instant('organizations.edit.notifications.error.payment_method'),
                      });
                    }
                  };
                  // exceptions are only throw in dev environment
                  if (response.error.exception) {
                    response.error.exception.forEach((exception) => {
                      displayError(exception.message);
                    });
                  } else {
                    displayError(response.error.message);
                  }
                }
              })
            ;
          }
        };

        vm.onMaxUsersChanged = () => {
          vm.organization.max_users = parseInt(vm.organization.max_users);
          vm.organization.users_count = parseInt(vm.organization.users_count);
          if (vm.organization.max_users && vm.organization.max_users < vm.organization.users_count) {
            vm.form.maxUsers.$setValidity('tooLow', false);
          } else {
            vm.form.maxUsers.$setValidity('tooLow', true);
          }
        };

        vm.onOwnerChanged = () => {
          if (!vm.organization.owner) {
            return;
          }
          const currentDomain = vm.organization.owner.email.split('@')[1];
          if (blockedEmailDomainsValue.includes(currentDomain)) {
            vm.form.owner.$setValidity('blocked', false);
          } else {
            vm.form.owner.$setValidity('blocked', true);
          }
        };
        $interval(() => {
          vm.onOwnerChanged();
        }, 0, 1);
      },
    ])

    .controller('ViewOrganizationController', [
      'SERVER',
      'ROLES',
      '$state',
      '$translate',
      '$uibModal',
      'api',
      'notifications',
      'partnersLink',
      'template',
      'currentUser',
      'organization',
      function ViewOrganizationController(
        SERVER,
        ROLES,
        $state,
        $translate,
        $uibModal,
        api,
        notifications,
        partnersLink,
        template,
        currentUser,
        organization,
      ) {
        const vm = this;

        vm.organization = organization;
        vm.partnersLink = partnersLink;
        vm.template = template;
        vm.API_BASE = SERVER.API_ENDPOINT;
        vm.canCloneTemplate = true;
        vm.canUncloneTemplate = true;
        vm.priceExportUrl = `${SERVER.API_ENDPOINT}/orgs/${organization.gid}/pricing.csv`;
        vm.currentUser = currentUser;
        vm.ROLES = ROLES;

        vm.disableProduct = function disableProduct(product) {
          return api
            .call({
              method: 'DELETE',
              url: `/products/${product.gid}/organizations/${organization.gid}`,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('Le produit a été désactivé.'),
              });
              product.activationState = 'disabled';
            });
        };

        vm.enableProduct = function enableProduct(product) {
          return api
            .call({
              method: 'POST',
              url: `/products/${product.gid}/organizations`,
              data: {
                gid: organization.gid,
              },
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('Le produit a été activé.'),
              });
              product.activationState = 'enabled';
            });
        };

        vm.cloneTemplateForOrg = function cloneTemplateForOrg(org) {
          vm.canCloneTemplate = false;
          template.cloneTemplateForOrg(org)
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('organization.template.clone.success'),
              });

              if (!organization.is_template) {
                $state.go('organization', {}, { reload: true });
              }
            })
            .error(() => {
              notifications.showError({
                message: $translate.instant('organization.template.clone.error'),
              });
            });
        };

        vm.uncloneTemplateForOrg = function uncloneTemplateForOrg(org) {
          vm.canUncloneTemplate = false;
          template.uncloneTemplateForOrg(org)
            .success(() => {
              if (!org.is_template && 'organization' !== $state.current.name) {
                $state.go('organization', {}, { reload: true });
              }

              notifications.showSuccess({
                message: $translate.instant('organization.template.unclone.success'),
              });
            })
            .error(() => {
              notifications.showError({
                message: $translate.instant('organization.template.unclone.error'),
              });
            });
        };

        vm.importCatalogue = () => {
          $uibModal.open({
            templateUrl: '/organizations/import_catalogue.html',
            controller: 'ImportOrganizationCatalogueController',
            controllerAs: 'importVm',
            bindToController: true,
            resolve: {
              organization() {
                return organization;
              },
            },
          });
        }
      },
    ])

    .controller('ListDisabledProductsForOrganizationController', [
      'ROLES',
      'currentUser',
      'organization',
      'products',
      function ListDisabledProductsForOrganizationController(ROLES, currentUser, organization, products) {
        const vm = this;

        vm.viewMode = 'disabled';
        vm.reversedViewMode = 'enabled';
        vm.products = products.items;
        vm.totalCount = products.total_count;
        vm.templateEditAllowed = organization.template || currentUser.roles.includes(ROLES.PRICING_ADMIN);
      },
    ])

    .controller('ListEnabledProductsForOrganizationController', [
      'ROLES',
      'currentUser',
      'organization',
      'products',
      function ListEnabledProductsForOrganizationController(ROLES, currentUser, organization, products) {
        const vm = this;

        vm.viewMode = 'enabled';
        vm.reversedViewMode = 'disabled';
        vm.products = products.items;
        vm.totalCount = products.total_count;
        vm.templateEditAllowed = organization.template || currentUser.roles.includes(ROLES.PRICING_ADMIN);
      },
    ])

    .controller('ListEnabledAutoMatchingController', [
      'ROLES',
      '$q',
      '$state',
      '$translate',
      'api',
      'currentUser',
      'notifications',
      'products',
      'listings',
      'listingsProducts',
      'organization',
      function ListEnabledAutoMatchingController(ROLES, $q, $state, $translate, api, currentUser, notifications, products, listings, listingsProducts, organization) {
        const vm = this;

        vm.products = products.items;
        vm.listings = listings.items;
        vm.listingsProducts = listingsProducts.items;
        vm.totalCount = listingsProducts.total_count;
        vm.organization = organization;
        vm.templateEditAllowed = currentUser.roles.includes(ROLES.PRICING_ADMIN);

        vm.listingsPagination = {
          offset: 10,
          limit: 10,
          max: listings.total_count,
          isLoading: false,
        };

        vm.productsPagination = {
          offset: 10,
          limit: 10,
          max: listings.total_count,
          isLoading: false,
        };

        vm.loadListings = function loadListings(query, triggeredBySearch) {
          const params: ParamsQuery = {
            fields: 'name',
          };
          if (triggeredBySearch) {
            vm.listingsPagination.offset = 0;
            vm.listingsPagination.max = listings.total_count;
          }

          params.offset = vm.listingsPagination.offset;
          params.limit = vm.listingsPagination.limit;

          params.query = JSON.stringify(['name', '~=', query]) || null;

          if (vm.listingsPagination.isLoading || params.offset >= vm.listingsPagination.max) {
            return;
          }

          vm.listingsPagination.isLoading = true;

          return api
            .call({
              url: '/listings',
              method: 'GET',
              ignoreLoadingBar: true,
              params,
            })
            .then((res) => {
              vm.listingsPagination.isLoading = false;
              vm.listings = triggeredBySearch ? res.data.items : vm.listings.concat(res.data.items);
              vm.listingsPagination.offset += vm.listingsPagination.limit;
              vm.listingsPagination.max = res.data.total_count;
            });
        };

        vm.loadProducts = function loadProducts(query, triggeredBySearch) {
          const params: ParamsQuery = {
            fields: 'title',
            locale: 'fr_FR',
          };

          if (triggeredBySearch) {
            vm.productsPagination.offset = 0;
            vm.productsPagination.max = products.total_count;
          }

          params.offset = vm.productsPagination.offset;
          params.limit = vm.productsPagination.limit;
          params.search = query;

          if (vm.productsPagination.isLoading || params.offset >= vm.productsPagination.max) {
            return;
          }

          vm.productsPagination.isLoading = true;

          return api
            .call({
              url: `/orgs/${organization.gid}/products?show_unpriced=1`,
              method: 'GET',
              ignoreLoadingBar: true,
              params,
            })
            .then((res) => {
              vm.productsPagination.isLoading = false;
              vm.products = triggeredBySearch ? res.data.items : vm.products.concat(res.data.items);
              vm.productsPagination.offset += vm.productsPagination.limit;
              vm.productsPagination.max = res.data.total_count;
            });
        };


        vm.addAutoMatchingRule = function addAutoMatchingRule() {
          api
            .call({
              method: 'POST',
              url: '/listings-products',
              data: {
                listing: vm.listing,
                product: vm.product,
                organization: vm.organization,
              },
            })
            .then(() => {
              vm.listing = null;
              vm.product = null;
              notifications.showSuccess({
                message: $translate.instant('organization.auto_matching.adding_rule_success'),
              });
              $state.go($state.current.name, {}, { reload: true });
            }, (error) => {
              if (409 === error.status) {
                notifications.showError({
                  message: $translate.instant('organization.auto_matching.adding_rule_conflict'),
                });
              } else {
                notifications.showError({
                  message: $translate.instant('organization.auto_matching.adding_rule_error'),
                });
              }
            });
        };

        vm.remove = function remove(listingProductGid) {
          api
            .call({
              method: 'DELETE',
              url: `/listings-products/${listingProductGid}`,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('organization.auto_matching.delete_rule_success'),
              });
              $state.go($state.current.name, {}, { reload: true });
            })
            .error(() => {
              notifications.showError({
                message: $translate.instant('organization.auto_matching.delete_rule_error'),
              });
            });
        };
      },
    ])

    .controller('EditPriceController', [
      '$translate',
      'api',
      'notifications',
      'prices',
      'product',
      function EditPriceController($translate, api, notifications, prices, product) {
        const vm = this;

        vm.product = product;
        const apiPrices = prices.items;
        const pricesMap = {};

        angular.forEach(product.available_options.skill.enum, (skill) => {
          const skillPrices = _.filter(apiPrices, (aPrice) => (aPrice.options.skill === skill));
          if (skillPrices.length) {
            pricesMap[skill] = {};
            angular.forEach(product.available_options.mode.enum, (mode) => {
              const modePrices = _.filter(skillPrices, (aPrice) => (aPrice.options.mode === mode));
              if (modePrices.length) {
                pricesMap[skill][mode] = modePrices[0];
              }
            });
            if (!Object.keys(pricesMap[skill]).length) {
              delete pricesMap[skill];
            }
          }
        });

        vm.pricesMap = pricesMap;

        vm.purchaseAmountChanged = function purchaseAmountChanged(priceGid : (string)[] = [], purchaseAmount: (number|null)) {
          api
            .call({
              method: 'PUT',
              url: `/prices/${priceGid}`,
              params: {
                showDeleted: true,
              },
              data: {
                purchase_amount: purchaseAmount,
              },
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('organization.edit_pricing.action_success'),
              });
            })
            .error(() => {
              notifications.showError({
                message: $translate.instant('organization.edit_pricing.action_error'),
              });
            });
        };

        vm.autoCloseDelayChanged = function autoCloseDelayChanged(priceGid : (string)[] = [], autoCloseDelay: (number|null)) {
          api
            .call({
              method: 'PUT',
              url: `/prices/${priceGid}`,
              params: {
                showDeleted: true,
              },
              data: {
                auto_close_delay: autoCloseDelay,
              },
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('organization.edit_pricing.auto_close_delay_action_success'),
              });
            })
            .error(() => {
              notifications.showError({
                message: $translate.instant('organization.edit_pricing.auto_close_delay_action_error'),
              });
            });
        };
      },
    ])

    .controller('ProductsPricesController', [
      'ROLES',
      'STEPS',
      '$translate',
      '$state',
      'api',
      'notifications',
      'organization',
      'products',
      'prices',
      'currentUser',
      function ProductsPricesController(ROLES, STEPS, $translate, $state, api, notifications, organization, products, prices, currentUser) {
        const vm = this;
        vm.organization = organization;
        vm.skills = ['conception', 'execution'];
        vm.modes = ['solo', 'multi'];
        vm.hasPricesChanged = false;
        vm.editAllowed = currentUser.roles.includes(ROLES.PRICING_ADMIN);

        vm.catColors = ['#1785fc', '#151b26', '#39c870', '#5080fa', '#fd3258', '#ff9800', '#ffc62d', '#ff924a', '#f95c3d', '#c37649', '#f33155', '#fc4381', '#e040fb', '#996efb', '#5636fd', '#047efb', '#3fc2ff', '#00cbd9', '#03ccba', '#21df9e'];
        let newPrices = {};
        const pricesToCreate = {};

        // https://ui-router.github.io/ng1/docs/latest/interfaces/ng1.ng1controller.html#uicanexit
        vm.uiCanExit = function uiCanExit() {
          if (vm.hasPricesChanged) {
            return confirm($translate.instant($translate.instant('organization.prices.leave')));
          }
          return true;
        };

        vm.uniqueProductCategories = _.uniq(products.map((p) => p.category), (category) => category.gid);
        // we add matched product(s) to categorie(s)
        const addProductsAndPricesToCategories = () => {
          products.forEach((product) => {
            const matchedCategory = vm.uniqueProductCategories.find((category) => category.gid === product.category.gid);

            if (product.hasOwnProperty('custom_data') && 0 === product.custom_data.length) {
              product.custom_data = {};
            }
            if (!matchedCategory) {
              return;
            }
            if (!matchedCategory.products) {
              matchedCategory.products = [];
            }
            matchedCategory.products.push(product);
            // we dont wanna push product(s) twice
            matchedCategory.products = _.uniq(matchedCategory.products, (el) => el.gid);
            const productWithPrices = { ...product, prices: {} };
            // add prices to matched product(s)
            prices.find((price) => {
              if (product.gid === price.product.gid) {
                productWithPrices.prices[`${price.options.skill}_${price.options.mode}`] = price;
              }
            });
            const matchedProductIndex = matchedCategory.products
              .findIndex((p) => p.gid === productWithPrices.gid)
            ;
            matchedCategory.products[matchedProductIndex].prices = productWithPrices.prices;
            if (matchedCategory.products[matchedProductIndex].prices) {
              for (const key in matchedCategory.products[matchedProductIndex].prices) {
                const price = matchedCategory.products[matchedProductIndex].prices[key];
                if (price) {
                  if ('number' === typeof price.purchase_ratio) {
                    price.editMode = 'percent';
                  } else {
                    price.editMode = 'amount';
                  }
                }
              }
            }

            matchedCategory.color = vm.catColors[Math.floor(Math.random() * vm.catColors.length)];
          });
        };
        addProductsAndPricesToCategories();

        vm.onPriceChange = (price: Price, options: PriceOptions, product: Resource) => {
          if (price.gid) {
            newPrices[`${product.gid}_${options.skill}_${options.mode}`] = price;
          } else {
            // Check if price already exist but was soft deleted
            api.call({
              method: 'GET',
              url: '/prices/for',
              params: {
                product_gid: product.gid,
                organization_gid: organization.gid,
                options_skill: options.skill,
                options_mode: options.mode,
                show_deleted: 1,
              },
              ignoreLoadingBar: true,
              options: {
                ignoreNotFound: true,
              },
            }).success((response) => {
              const deletedPriceToUpdate: Price = {
                amount: price.amount,
                gid: response.gid,
                options,
                product: {
                  gid: product.gid,
                },
                organization: {
                  gid: organization.gid,
                },
                deleted: false,
              };

              if (deletedPriceToUpdate.amount >= 0) {
                newPrices[`${product.gid}_${options.skill}_${options.mode}`] = deletedPriceToUpdate;
              }
            }).error(() => {
              const currentCreatedPrice = price;
              currentCreatedPrice.options = options;
              currentCreatedPrice.organization = organization;
              currentCreatedPrice.product = product;
              if (STEPS.TYPE.NUMBER === typeof currentCreatedPrice.purchase_ratio) {
                currentCreatedPrice.editMode = 'percent';
              } else {
                currentCreatedPrice.editMode = 'amount';
              }
              pricesToCreate[`${product.gid}_${options.skill}_${options.mode}`] = currentCreatedPrice;
            });
          }
          vm.hasPricesChanged = true;
        };

        vm.canEditPrice = (product, skill, mode) =>
            product.available_options.skill.enum.includes(skill) &&
            product.available_options.mode.enum.includes(mode);

        vm.removePrice = (price) => {
          if (price && price.gid) {
            api.call({
              method: 'DELETE',
              url: `/prices/${price.gid}`,
            }).success(() => {
              notifications.showSuccess({
                message: $translate.instant('organization.prices.notifications.deleted_success'),
              });
              price.amount = null;
            }).error(() => {
              notifications.showError({
                message: $translate.instant('organization.prices.notifications.deleted_error'),
              });
            });
          }
        };

        vm.saveLowestPrice = (product) => {
          api.call({
            method: 'PUT',
            url: `/products/${product.gid}`,
            data: {
              custom_data: product.custom_data
            },
          }).success(() => {
            notifications.showSuccess({
              message: $translate.instant('organization.prices.product.lowest_price.notification.success'),
            });
          }).error(() => {
            notifications.showError({
              message: $translate.instant('organization.prices.product.lowest_price.notification.error'),
            });
          });
        };

        vm.save = () => {
          const updatedPrices = [];
          if (vm.form.$submitted && vm.form.$valid) {
            angular.forEach(newPrices, (price: Price) => {
              if ('percent' === price.editMode) {
                price.purchase_amount = null;
              } else {
                price.purchase_ratio = null;
              }
              updatedPrices.push(
                _.pick(
                  price,
                  'gid',
                  'amount',
                  'options',
                  'organization',
                  'product',
                  'purchase_amount',
                  'purchase_ratio',
                  'deleted'
                ),
              );
            });
            if (updatedPrices.length) {
              api.call({
                method: 'PUT',
                url: '/prices',
                data: {
                  prices: updatedPrices,
                },
              }).success(() => {
                notifications.showSuccess({
                  message: $translate.instant('organization.prices.notifications.success'),
                });
                newPrices = {};
              }).error(() => {
                notifications.showError({
                  message: $translate.instant('organization.prices.notifications.error'),
                });
              });
            }

            angular.forEach(pricesToCreate, (price: Price) => {
              api.call({
                method: 'POST',
                url: '/prices',
                data: _.pick(
                  price,
                  'gid',
                  'amount',
                  'options',
                  'organization',
                  'product',
                ),
              }).success(() => {
                delete pricesToCreate[`${price.product.gid}_${price.options.skill}_${price.options.mode}`];

                // Can't edit price after it's creation without gid
                if (0 === Object.keys(pricesToCreate).length) {
                  notifications.showSuccess({
                    message: $translate.instant('organization.prices.notifications.created_success'),
                  });
                  $state.go($state.current.name, {}, { reload: true });
                }
              });
            });

            vm.hasPricesChanged = false;
          }
        };
      },
    ])

    .controller('ImportOrganizationCatalogueController', [
      '$translate',
      'notifications',
      'api',
      'organization',
      function ImportOrganizationCatalogueController($translate, notifications, api, organization) {
        const vm = this;

        vm.destinationOrg = organization;
        vm.sourceOrg = null;

        vm.fetchOrgsFromSearch = function fetchOrgsFromSearch(searchQuery: String) {
          if ('' === searchQuery) {
            return;
          }
          api.call({
            method: 'GET',
            url: '/organizations',
            params: {
              search: searchQuery,
              fields: 'gid,name',
            },
          }).then((response) => {
            vm.organizationsResults = response.data.items;
          });
        };

        vm.submit = async () => {
          if (vm.form.$valid) {
            if (confirm($translate.instant('common.action_disclaimer'))) {
              try {
                await api
                .call({
                  method: 'POST',
                  url: `/organizations/${vm.destinationOrg.gid}/products/import`,
                  data: {
                    source: {
                      gid: vm.sourceOrg.gid
                    }
                  }
                });
                notifications.showSuccess({
                  message: $translate.instant('organization.import_catalogue.success'),
                });
                vm.$close();
              } catch (error) {
                notifications.showError({
                  message: $translate.instant('organization.import_catalogue.error'),
                });
              }
            }
          }
        };
      }
    ])

    .controller('ExcludedWorkerController', [
      '$ngBootbox',
      '$state',
      '$translate',
      'notifications',
      'api',
      'organization',
      'excludedWorkers',
      'currentUser',
      'ROLES',
      function ExcludedWorkerController($ngBootbox, $state, $translate, notifications, api, organization, excludedWorkers, currentUser, ROLES) {
        const vm = this;

        vm.excludedWorkers = excludedWorkers.items;
        vm.newExcl = {
          worker: null
        };
        vm.isGrantedSalesAdmin = currentUser.roles?.includes(ROLES.SALES_ADMIN);

        vm.getMatchingWorkers = (search) => {
          api
            .call({
              method: 'GET',
              url: '/workers',
              params: {
                query: JSON.stringify(['username', '~=', search]),
                fields: 'username,firstname,lastname',
              },
            })
            .success((res) => {
              vm.workersResults = res.items;
            });
        };

        vm.addExclusion = () => {
          $ngBootbox.confirm($translate.instant('organization.excluded_workers.disclaimer_confirm'))
            .then(() => {
              api
                .call({
                  method: 'POST',
                  url: `/orgs/${organization.gid}/excluded-workers`,
                  data: vm.newExcl
                })
                .then(() => {
                  vm.newExcl = {
                    worker: null
                  };
                  $state.reload();
                }, () => {
                  notifications.showError({
                    message: $translate.instant('common.error.action_fail'),
                  });
                })
              ;
            })
          ;
        };

        vm.removeExclusion = (excl) => {
          api
            .call({
              method: 'DELETE',
              url: `/orgs/${organization.gid}/excluded-workers/${excl.gid}`
            })
            .then(() => {
              $state.reload();
            }, () => {
              notifications.showError({
                message: $translate.instant('common.error.action_fail'),
              });
            })
          ;
        };
      }
    ])
  ;
}());

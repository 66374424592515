(function () {
  /**
  * Services Module
  *
  * Description
  */
  angular
    .module('partners-bo.components.services', [
    ]);
}());

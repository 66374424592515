(function () {
  angular
    .module('partners-bo.settings', [])

    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('settings', {
            abstract: true,
            parent: 'root',
            url: '/settings',
            template: '<ui-view/>',
          })
          .state('settings.bonuses', {
            url: '/bonuses',
            controller: 'EditBonusesController',
            controllerAs: 'vm',
            templateUrl: 'settings/bonuses.html',
            reloadOnSearch: true,
            resolve: {
              thresholds: [
                'api',
                function (api) {
                  return api.call({
                    method: 'GET',
                    url: '/admin/settings/credit_thresholds',
                  }).then((res) => res.data);
                },
              ],
            },
          })
          .state('settings.specific_brief', {
            url: '/specific-brief',
            controller: 'SpecificBriefController',
            controllerAs: 'vm',
            templateUrl: 'settings/specific_brief.html',
            resolve: {
              specificProjectDescription: [
                'api',
                function (api) {
                  return api.call({
                    method: 'GET',
                    url: '/admin/settings/project.specific_request.default_description',
                  }).then((res) => res.data);
                },
              ],
            },
          })
          .state('settings.banners', {
            url: '/banners',
            controller: 'BannersController',
            controllerAs: 'vm',
            templateUrl: 'settings/banners.html',
            resolve: [
              {
                token: 'bannersBatch',
                deps: ['api'],
                resolveFn(api) {
                  const batchRequest = [
                    {
                      method: 'GET',
                      relative_url: '/v1/admin/settings/project.top_banner',
                    },
                    {
                      method: 'GET',
                      relative_url: '/v1/admin/settings/project.lateral_banner',
                    },
                  ];
                  return api.call({
                    method: 'POST',
                    url: '/batch',
                    data: batchRequest,
                  }).then((result) => {
                    const data = _.pluck(result.data, 'body');

                    return {
                      topBanner: JSON.parse(data[0]),
                      lateralBanner: JSON.parse(data[1]),
                    };
                  });
                },
              },
            ],
          })
          .state('settings.campaigns', {
            url: '/campaigns/list/{index:int}?{search:string}',
            controller: 'ListCampaignsController',
            controllerAs: 'vm',
            templateUrl: 'settings/campaigns.html',
            reloadOnSearch: true,
            resolve: [
              {
                token: 'request',
                deps: ['$stateParams', 'paginator'],
                resolveFn($stateParams, paginator) {
                  paginator.setMaxItemPage($stateParams.limit);
                  const params: ParamsQuery = {
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    orderBy: 'modified_at:desc',
                    fields: 'identifier,identifier_prefix,organization_template',
                  };

                  const query: ConditionQuery|String = [];
                  if ($stateParams.search) {
                    const { search } = $stateParams;
                    query.push(['identifier', '~=', search]);
                  }
                  params.query = JSON.stringify(query);

                  return {
                    method: 'GET',
                    url: '/campaigns',
                    params,
                  };
                },
              },
              {
                token: 'campaigns',
                deps: ['api', 'request'],
                resolveFn(api, request) {
                  return api.call(request).then((res) => res.data);
                },
              },
            ],
          })
          .state('settings.coupons', {
            url: '/coupons/list/{index:int}?{search:string}&{origin:string}&{state:string}',
            controller: 'ListCouponsController',
            controllerAs: 'vm',
            templateUrl: 'settings/coupons.html',
            reloadOnSearch: true,
            resolve: [
              {
                token: 'coupons',
                deps: ['$stateParams', 'paginator', 'api'],
                resolveFn($stateParams, paginator, api) {
                  paginator.setMaxItemPage($stateParams.limit);
                  const params: ParamsQuery = {
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    orderBy: 'modified_at:desc',
                    fields: 'origin,identifier,amount,percentage,uses,max_uses,expires_at,state',
                    query: null,
                  };

                  const query: ConditionQuery|String = [];
                  if ($stateParams.search) {
                    const { search } = $stateParams;
                    query.push(['OR', [['identifier', '~=', search], ['gid', '~=', search]]]);
                  }
                  if ($stateParams.origin) {
                    const { origin } = $stateParams;
                    query.push([['origin', '==', origin]]);
                  }
                  if ($stateParams.state) {
                    const { state } = $stateParams;
                    query.push([['state', '==', state]]);
                  }
                  params.query = JSON.stringify(query);

                  return api.call({
                    method: 'GET',
                    url: '/coupons',
                    params,
                  }).then((res) => res.data);
                },
              },
              {
                token: 'couponsStat',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/coupons/stat',
                  }).then((res) => res.data);
                },
              },
            ],
          })
          .state('settings.coupon-add', {
            url: '/coupons/add',
            controller: 'AddCouponController',
            controllerAs: 'vm',
            templateUrl: 'settings/add_coupon.html',
          })
          .state('settings.hubspot', {
            url: '/hubspot/api-key',
            controller: 'EditHubspotApiKeyController',
            controllerAs: 'editHubspotVm',
            templateUrl: 'settings/hubspot.html',
          })
          .state('settings.offline-background', {
            url: '/login-background',
            controller: 'OfflineBackgroundController',
            controllerAs: 'vm',
            templateUrl: 'settings/offline_background.html',
            resolve: [
              {
                token: 'offlineBackgroundFile',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/settings/offline_background_file',
                  }).then((res) => res.data);
                },
              },
              {
                token: 'signupBackgroundFile',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/settings/signup_background_file',
                  }).then((res) => res.data);
                },
              },
            ],
          })
          .state('settings.accounts', {
            url: '/accounts',
            controller: 'AccountsController',
            controllerAs: 'vm',
            templateUrl: 'settings/accounts.html',
            resolve: [
              {
                token: 'defaultMaxUsers',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/settings/default_max_users',
                  }).then((res) => res.data);
                },
              },
            ]
          })
          .state('settings.security', {
            url: '/security',
            controller: 'SecurityController',
            controllerAs: 'vm',
            templateUrl: 'settings/security.html',
            resolve: [
              {
                token: 'blockedEmailDomains',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/settings/blocked_email_domains',
                  }).then((res) => res.data);
                },
              },
            ]
          })
        ;

        $urlRouterProvider
          .when('/campaigns', '/campaigns/1')
          .when('/coupons', '/coupons/1')
        ;
      },
    ])

    .controller('EditBonusesController', [
      '$translate',
      'api',
      'notifications',
      'thresholds',
      function EditBonusesController($translate, api, notifications, thresholds) {
        const vm = this;

        vm.thresholds = thresholds.value;

        vm.onChangeBonusAmount = function onChangeBonusAmount(threshold) {
          const min = 0.00;
          const max = 999999999.99;
          if (threshold.amount < min) {
            threshold.amount = min;
          } else if (threshold.amount >= max) {
            threshold.amount = max;
          }
          vm.form.$setValidity('unique_bonus_amount', true);
        };
        vm.onChangeBonusRatio = function onChangeBonusRatio(threshold) {
          const min = 0;
          const max = 100;
          let percent = threshold.bonus_percent;

          if (percent < min) {
            percent = min;
          } else if (percent >= max) {
            percent = max;
          }
          threshold.bonus_ratio = percent / 100;
          threshold.bonus_percent = percent;
        };

        vm.addThreshold = function addThreshold() {
          const threshold = {
            amount: null,
            bonus_ratio: 0,
          };
          vm.thresholds.push(threshold);
        };

        vm.removeThreshold = function removeThreshold(threshold) {
          const i = vm.thresholds.indexOf(threshold);
          if (i !== -1) {
            vm.thresholds.splice(i, 1);
            vm.form.$setValidity('unique_bonus_amount', true);
            // To enabled submit button
            vm.form.$setDirty();
          }
        };

        vm.submit = function submit() {
          const data = {
            value: [],
          };
          const amountList = [];
          let hasDuplicateAmount = false;

          vm.thresholds.forEach((element) => {
            if (amountList.indexOf(element.amount) !== -1) {
              hasDuplicateAmount = true;
              return false;
            }
            amountList.push(element.amount);

            data.value.push({
              amount: element.amount,
              bonus_ratio: element.bonus_ratio,
            });
          });

          if (hasDuplicateAmount) {
            vm.form.$setValidity('unique_bonus_amount', false);

            return;
          }

          api.call({
            method: 'PUT',
            url: '/admin/settings/credit_thresholds',
            data,
          }).success(() => {
            notifications.showSuccess({
              message: $translate.instant('credit_notes.bonus.success'),
            });
            vm.form.$setPristine();
          }).error((err) => err);
        };
      },
    ])

    .controller('SpecificBriefController', [
      '$translate',
      'api',
      'specificProjectDescription',
      'notifications',
      function SpecificBriefController($translate, api, specificProjectDescription, notifications) {
        const vm = this;
        vm.specificProjectDescription = specificProjectDescription.value.description;

        vm.submit = function submit() {
          const data = {
            value: {
              description: vm.specificProjectDescription,
            },
          };
          api.call({
            method: 'PUT',
            url: '/admin/settings/project.specific_request.default_description',
            data,
          }).success(() => {
            notifications.showSuccess({
              message: $translate.instant('project.default_description.success'),
            });
            vm.form.$setPristine();
          }).error((err) => err);
        };
      },
    ])

    .controller('BannersController', [
      'SERVER',
      '$translate',
      'api',
      'notifications',
      'bannersBatch',
      function BannersController(SERVER, $translate, api, notifications, bannersBatch) {
        const vm = this;

        vm.topBanner = bannersBatch.topBanner.value;
        vm.lateralBanner = bannersBatch.lateralBanner.value;

        vm.topBanner.image = vm.topBanner.image ? vm.topBanner.image : null;
        vm.lateralBanner.image = vm.lateralBanner.image ? vm.lateralBanner.image : null;

        vm.topBannerFile = [];
        vm.lateralBannerFile = [];

        vm.FILE_ENDPOINT = `${SERVER.API_ENDPOINT}/files/`;

        if (vm.topBanner.image) {
          vm.topBannerFile = [vm.topBanner.image];
        }

        if (vm.lateralBanner.image) {
          vm.lateralBannerFile = [vm.lateralBanner.image];
        }

        vm.uploaderValidate = {
          width: { max: 728, min: 728 }, height: { max: 90, min: 90 }, pattern: '.jpg,.gif,.jpeg,.png', accept: 'image/jpg,image/jpeg,image/png,image/gif', size: { max: '50Kb' },
        };

        vm.uploaderValidate2 = {
          width: { max: 300, min: 300 }, pattern: '.jpg,.gif,.jpeg,.png', accept: 'image/jpg,image/jpeg,image/png,image/gif', size: { max: '50Kb' },
        };

        vm.uploadError = {};
        /* eslint-disable */
        // we need this bc eslint is bad at detecting unused variables inside closures
        vm.onAddBanner = function onAddBanner(model, array) {
          /* eslint-enable */
          return function (file) {
            model.image = file;
            array = [model.image];
          };
        };

        /* eslint-disable */
        vm.onRemoveBanner = function onRemoveBanner(model, array) {
          /* eslint-enable */
          return function (fileIgnored, callback) {
            model.image = null;
            array = [];
            callback();
          };
        };

        vm.submit = function submit(key, data) {
          api
            .call({
              method: 'PUT',
              url: `/admin/settings/${key}`,
              data,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('project.banner.success'),
              });
              vm.form.$setPristine();
            })
            .error((err) => err);
        };
      },
    ])

    .controller('ListCampaignsController', [
      'SERVER',
      '$translate',
      '$uibModal',
      'api',
      'campaigns',
      'notifications',
      function ListCampaignsController(SERVER, $translate, $uibModal, api, campaigns, notifications) {
        const vm = this;

        vm.SIGNUP_URL = `${SERVER.BUSINESS_URL}/#/pre-signup/`;
        vm.campaigns = campaigns.items;
        vm.totalCount = campaigns.total_count;
        vm.placeholder = $translate.instant('settings.onboarding.search');
        vm.addCampaignModal = function addCampaignModal(campaign) {
          $uibModal.open({
            templateUrl: '/settings/edit_campaign_modal.html',
            controller: 'EditCampaignController',
            controllerAs: '$editCampaignVm',
            bindToController: true,
            resolve: {
              campaign() {
                return campaign;
              },
            },
          });
        };
        vm.editCampaignModal = function editCampaignModal(campaign) {
          $uibModal.open({
            templateUrl: '/settings/edit_campaign_modal.html',
            controller: 'EditCampaignController',
            controllerAs: '$editCampaignVm',
            bindToController: true,
            resolve: {
              campaign: [
                'api',
                function (api) {
                  return api.call({
                    method: 'GET',
                    url: `/campaigns/${campaign.gid}`,
                    params: {
                      fields: 'identifier,identifier_prefix,organization_template',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          });
        };

        vm.deleteCampaignModal = function deleteCampaignModal(campaign) {
          api.call({
            method: 'DELETE',
            url: `/campaigns/${campaign.gid}`,
          })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('settings.onboarding.bootbox.success_delete'),
              });
              vm.campaigns.splice(vm.campaigns.indexOf(campaign), 1);
            })
            .error((data) => {
              notifications.showError({
                message: $translate.instant('settings.onboarding.bootbox.fail_delete'),
              });
              throw new Error(`Error${data}`);
            });
        };
      },
    ])

    .controller('EditCampaignController', [
      '$state',
      '$translate',
      'api',
      'notifications',
      'campaign',
      function EditCampaignController($state, $translate, api, notifications, campaign) {
        const vm = this;
        vm.orgTemplate = [];
        if (campaign) {
          vm.campaign = campaign;
        } else {
          vm.campaign = {};
        }

        vm.getMatchingOrgTemplate = function getMatchingOrgTemplate(search) {
          if (!search) {
            return;
          }
          api.call({
            method: 'GET',
            url: '/organizations',
            params: {
              query: JSON.stringify([['template', '==', null]]),
              search,
              fields: 'gid,name',
            },
          }).then((res) => {
            vm.orgsResults = res.data.items;
          });
        };

        vm.submit = function submit() {
          const campaignToSend = {
            organization_template: {
              gid: vm.campaign.organization_template.gid,
            },
            identifier: vm.campaign.identifier_prefix ? vm.campaign.identifier_prefix : vm.campaign.identifier,
          };

          if (vm.form.$valid) {
            api.call({
              method: campaign ? 'PUT' : 'POST',
              url: campaign ? (`/campaigns/${campaign.gid}`) : '/campaigns',
              data: campaignToSend,
            }).success(() => {
              notifications.showSuccess({
                message: campaign ? $translate.instant('settings.onboarding.modal.success_put') : $translate.instant('settings.onboarding.modal.success_submit'),
              });
              vm.form.$setPristine();
              vm.close();
            }).error(() => {
              notifications.showError({
                message: $translate.instant('settings.onboarding.modal.fail_submit'),
              });
            });
          }
        };

        vm.close = function close() {
          vm.$close();
          $state.reload();
        };
      },
    ])

    .controller('ListCouponsController', [
      'COUPON',
      '$state',
      '$stateParams',
      '$translate',
      'api',
      'coupons',
      'notifications',
      'couponsStat',
      function ListCouponsController(COUPON, $state, $stateParams, $translate, api, coupons, notifications, couponsStat) {
        const vm = this;
        vm.STATE = COUPON.STATE;
        vm.ORIGIN = COUPON.ORIGIN;
        vm.coupons = coupons.items;
        vm.totalCount = coupons.total_count;
        vm.totalMax = couponsStat.max;

        vm.filterOrigin = $stateParams.origin;
        vm.filterState = $stateParams.state;

        vm.couponOriginFilter = null;
        vm.couponStateFilter = null;

        vm.couponOriginFilterChoices = [vm.ORIGIN.CAMPAIGN, vm.ORIGIN.DEFAULT, vm.ORIGIN.INVITATION_RECEIVER, vm.ORIGIN.INVITATION_SENDER];
        vm.couponStateFilterChoices = [vm.STATE.VALID, vm.STATE.INVALID];

        vm.applyOriginFilter = function applyOriginFilter() {
          $state.go($state.current.name, { origin: vm.filterOrigin });
        };

        vm.applyStateFilter = function applyStateFilter() {
          $state.go($state.current.name, { state: vm.filterState });
        };

        vm.deleteCouponModal = function deleteCouponModal(coupon) {
          api.call({
            method: 'DELETE',
            url: `/coupons/${coupon.gid}`,
          })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('settings.coupons.bootbox.success_delete'),
              });
              vm.coupons.splice(vm.coupons.indexOf(coupon), 1);
            })
            .error((data) => {
              notifications.showError({
                message: $translate.instant('settings.coupons.bootbox.fail_delete'),
              });
              throw new Error(`Error${data}`);
            });
        };

        vm.downloadCsvFile = function downloadCsvFile() {
          const params: ParamsQuery = {
            orderBy: 'modified_at:desc',
          };
          const query = [];

          if ($stateParams.search) {
            query.push(['identifier', '~=', $stateParams.search]);
          }

          if ($stateParams.origin) {
            query.push(['origin', '~=', $stateParams.origin]);
          }

          if ($stateParams.state) {
            query.push(['state', '~=', $stateParams.state]);
          }

          params.query = JSON.stringify(query);

          api.call({
            method: 'GET',
            url: '/coupons/download',
            params,
          })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('settings.coupons.download.success'),
              });
            })
            .error((data) => {
              notifications.showError({
                message: $translate.instant('settings.coupons.download.error'),
              });
              throw new Error(`Error${data}`);
            });
        };
      },
    ])

    .controller('AddCouponController', [
      'COUPON',
      '$state',
      '$translate',
      'api',
      'moment',
      'notifications',
      function AddCouponController(COUPON, $state, $translate, api, moment, notifications) {
        const vm = this;

        vm.coupon = {
          amount: 0,
          percentage: 0,
          max_uses: 1,
        };

        vm.editMode = 'amount';
        vm.coupon.expires_at = vm.nextMonth;
        vm.DEFAULT_ORIGIN = COUPON.ORIGIN.DEFAULT;
        vm.CAMPAIGN_ORIGIN = COUPON.ORIGIN.CAMPAIGN;
        vm.applyQuantity = false;
        vm.expirationDatePickerOptions = {
          minDate: moment().add(1, 'days').toDate(),
        };

        vm.expirationDate = moment().add(1, 'months').toDate();
        vm.changeQuantity = function changeQuantity() {
          vm.coupon.quantity = vm.applyQuantity ? 1 : null;
        };
        // maxDate & minDate on uib-datepicker not working correctly
        // see https://github.com/angular-ui/bootstrap/issues/6025

        vm.checkIdentifier = function checkIdentifier(identifier) {
          if (!identifier) {
            vm.form.identifier.$setValidity('identifierAvailable', true);
            return;
          }
          api
            .call({
              method: 'POST',
              url: '/coupons/check-identifier',
              data: {
                identifier,
              },
              ignoreLoadingBar: true,
            })
            .success(() => {
              vm.form.identifier.$setValidity('identifierAvailable', true);
            })
            .error((error) => {
              if (409 === error.error.code) {
                vm.form.identifier.$setValidity('identifierAvailable', false);
              }
            });
        };

        vm.submit = function submit() {
          let couponToSend = {
            identifier: vm.coupon.identifier,
            amount: vm.editMode === 'amount' ? parseInt(vm.coupon.amount) : null,
            percentage: vm.editMode === 'percent' ? (parseInt(vm.coupon.percentage) / 100) : null,
            max_uses: parseInt(vm.coupon.max_uses),
            expires_at: moment(vm.expirationDate).toISOString(),
            origin: vm.coupon.quantity ? vm.CAMPAIGN_ORIGIN : vm.DEFAULT_ORIGIN,
            quantity: parseInt(vm.coupon.quantity),
            state: COUPON.STATE.VALID,
          };

          if (!vm.coupon.quantity) {
            couponToSend = _.omit(couponToSend, 'quantity');
          }
          if (vm.form.$valid) {
            api.call({
              method: 'POST',
              url: vm.coupon.quantity ? ('/coupons/batch') : '/coupons',
              data: couponToSend,
            }).success(() => {
              notifications.showSuccess({
                message: vm.coupon.quantity ? $translate.instant('settings.coupons.success_quantity_submit', { quantity: vm.coupon.quantity }) : $translate.instant('settings.coupons.success_submit'),
              });
              $state.go('settings.coupons', { index: 1 });
            }).error(() => {
              notifications.showError({
                message: $translate.instant('settings.coupons.fail_submit'),
              });
            });
          }
        };
      },
    ])

    .controller('EditHubspotApiKeyController', [
      'SERVER',
      '$http',
      '$translate',
      'notifications',
      function EditHubspotApiKeyController(SERVER, $http, $translate, notifications) {
        const editHubspotVm = this;
        let url = SERVER.API_ENDPOINT.replace('/v1', '');

        editHubspotVm.hubspotApiKey = null;

        editHubspotVm.getHubspotApiKey = () => {
          $http({
            method: 'GET',
            url: `${url}/hubspot/api-key`,
          })
          .success((data) => {
            editHubspotVm.hubspotApiKey = data.api_key;
          })
          .error((data) => {
            throw new Error(data);
          });
        };
        editHubspotVm.getHubspotApiKey();

        editHubspotVm.submit = () => {
          if (editHubspotVm.form.$submitted && editHubspotVm.form.$valid) {
            $http({
              method: 'POST',
              url: `${url}/hubspot/api-key`,
              data: {
                api_key: editHubspotVm.newHubspotApiKey
              }
            }).success(() => {
              editHubspotVm.hubspotApiKey = editHubspotVm.newHubspotApiKey;
              editHubspotVm.newHubspotApiKey = null;
              editHubspotVm.form.$setPristine();
              notifications.showSuccess({
                message: $translate.instant('settings.hubspot.success.api-key'),
              });
            })
            .error((data) => {
              throw new Error(data);
            });
          }
        };
      }
    ])

    .controller('OfflineBackgroundController', [
      '$translate',
      'api',
      'notifications',
      'offlineBackgroundFile',
      'signupBackgroundFile',
      function OfflineBackgroundController($translate, api, notifications, offlineBackgroundFile, signupBackgroundFile) {
        const vm = this;

        vm.offlineBackground = offlineBackgroundFile.value;
        vm.offlineBackgroundFile = null;

        vm.signupBackground = signupBackgroundFile.value;
        vm.signupBackgroundFile = null;

        if (vm.offlineBackground?.image?.url) {
          vm.offlineBackgroundFile = vm.offlineBackground.image;
        }

        if (vm.signupBackground?.image?.url) {
          vm.signupBackgroundFile = vm.signupBackground.image;
        }

        vm.uploaderValidate = {
          pattern: '.jpg,.gif,.jpeg,.png,.svg', accept: 'image/jpg,image/jpeg,image/png,image/gif,image/svg', size: { max: '2Mb' },
        };

        vm.uploadError = {};
        vm.uploadErrorSignup = {};

        /* eslint-disable */
        // we need this bc eslint is bad at detecting unused variables inside closures
        vm.onAddOfflineBackground = function onAddOfflineBackground() {
          /* eslint-enable */
          return function (file) {
            vm.offlineBackground.image = file;
            vm.offlineBackgroundFile = vm.offlineBackground.image;
          };
        };

        /* eslint-disable */
        vm.onRemoveOfflineBackground = function onRemoveOfflineBackground() {
          /* eslint-enable */
          return function (fileIgnored, callback) {
            vm.offlineBackground.image = null;
            vm.offlineBackgroundFile = null;
            callback();
          };
        };

        vm.onAddSignupBackground = function onAddSignupBackground() {
          /* eslint-enable */
          return function (file) {
            vm.signupBackground.image = file;
            vm.signupBackgroundFile = vm.signupBackground.image;
          };
        };

        /* eslint-disable */
        vm.onRemoveSignupBackground = function onRemoveSignupBackground() {
          /* eslint-enable */
          return function (fileIgnored, callback) {
            vm.signupBackground.image = null;
            vm.signupBackgroundFile = null;
            callback();
          };
        };

        vm.submit = function submit(data) {
          api
            .call({
              method: 'PUT',
              url: '/admin/settings/offline_background_file',
              data,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('settings.bg_offline.success'),
              });
              vm.form.$setPristine();
            })
            .error((err) => err);
        };

        vm.submitSignup = function submit(data) {
          api
            .call({
              method: 'PUT',
              url: '/admin/settings/signup_background_file',
              data,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('settings.bg_offline.success'),
              });
              vm.formSignup.$setPristine();
            })
            .error((err) => err);
        };
      }
    ])

    .controller('AccountsController', [
      'notifications',
      '$translate',
      'api',
      'defaultMaxUsers',
      function AccountsController(notifications, $translate, api, defaultMaxUsers) {
        const vm = this;

        vm.defaultMaxUsers = defaultMaxUsers;

        vm.submit = function submit(data) {
          api
            .call({
              method: 'PUT',
              url: '/admin/settings/default_max_users',
              data: vm.defaultMaxUsers,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('settings.accounts.success'),
              });
            });
        };
      }
    ])

    .controller('SecurityController', [
      'notifications',
      '$translate',
      'api',
      'blockedEmailDomains',
      function SecurityController(notifications, $translate, api, blockedEmailDomains) {
        const vm = this;

        vm.blockedEmailDomains = blockedEmailDomains.value;
        vm.domainPattern = /^((?!-)[A-Za-z0–9-]{1,63}(?<!-)\.)+[A-Za-z]{2,24}$/;

        vm.addDomain = () => {
          vm.blockedEmailDomains.push('');
        };

        vm.removeDomain = (index) => {
          vm.blockedEmailDomains.splice(index, 1);
        };

        vm.submit = function submit(data) {
          api
            .call({
              method: 'PUT',
              url: '/admin/settings/blocked_email_domains',
              data: {
                value: vm.blockedEmailDomains
              },
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('settings.security.success'),
              });
            });
        };
      }
    ])
  ;
}());

(function () {
  angular
    .module('partners-bo.layout', [
    ])

    .config([
      '$stateProvider',
      function ($stateProvider) {
        $stateProvider
          .state('root', {
            templateUrl: 'layout/layout.html',
            controller: 'RootController',
            controllerAs: 'vm',
            abstract: true,
            resolve: [
              {
                token: 'currentUser',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/me',
                    params: {
                      fields: 'display_full_name,gid,email,roles,avatar',
                    },
                  }).then((res) => res.data);
                },
              },
              {
                token: 'defaultOrganization',
                deps: ['api', '$q'],
                resolveFn(api, $q) {
                  const deferred = $q.defer();
                  api.call({
                    method: 'GET',
                    url: '/organizations?limit=1',
                    params: {
                      fields: 'debt_enabled,email_enabled,gid,href,is_deleted,logo,name,owner,stat',
                    },
                  }).success((organizations) => {
                    deferred.resolve(organizations.items[0]);
                  }).error(() => {
                    deferred.resolve(null);
                  });
                  return deferred.promise;
                },
              },

            ],
          })

          .state('notFound', {
            templateUrl: 'layout/notFound.html',
            controller: [
              '$rootScope',
              function NotFoundController($rootScope) {
                // will hide the splash screen
                $rootScope.$broadcast('ready');
              },
            ],
            data: {
              public: true,
            },
          });
      },
    ])

    .controller('RootController', [
      '$rootScope',
      'currentUser',
      'defaultOrganization',
      'moment',
      function RootController($rootScope, currentUser, defaultOrganization, moment) {
        const vm = this;

        vm.hideSidebar = vm.hideSidebar || false;
        vm.defaultOrgaGid = defaultOrganization.gid;
        vm.currentUser = currentUser;
        vm.CONSOLE_VERSION = __VERSION__;
        vm.currentYear = moment().year();

        vm.toggleSidebar = function toggleSidebar() {
          vm.hideSidebar = !vm.hideSidebar;
        };

        $rootScope.$watch('hideSidebar', (newValue) => {
          vm.hideSidebar = newValue;
        });
        // will hide the splash screen
        $rootScope.$broadcast('ready');
      },
    ]);
}());

(function () {
  angular
    .module('partners-bo.components.directives')
    .directive('customStep', [
      CustomStepDirective,
    ])
  ;

  function CustomStepDirective() {
    function CustomStepController(STEPS) {
      const vm = this;
      vm.STEPS = STEPS;

      vm.getTemplateUrl = () => {
        const customTypes = [
          STEPS.TYPE.CHECKBOX,
          STEPS.TYPE.DATE,
          STEPS.TYPE.NUMBER,
          STEPS.TYPE.NUMBERS,
          STEPS.TYPE.RADIO,
          STEPS.TYPE.RADIO_IMAGE,
          STEPS.TYPE.RANGE,
          STEPS.TYPE.SELECT,
        ];

        if (-1 !== customTypes.indexOf(vm.step.type)) {
          return `/components/directives/steps/${vm.step.type}.html`;
        }

        return '/components/directives/steps/default.html';
      }
    }

    return {
      scope: {
        form: '=?',
        step: '=?',
        stepService: '=?',
      },
      restrict: 'E',
      controller: [
        'STEPS',
        CustomStepController,
      ],
      controllerAs: 'vm',
      bindToController: true,
      template : '<div ng-include="vm.getTemplateUrl()" class="overflow-auto"></div>'
    };
  }
}());

(function () {
  /**
   * API config
   */
  angular
    .module('partners-bo')
    .config([
      'PAGINATOR',
      'paginatorProvider',
      function (PAGINATOR, paginatorProvider) {
        paginatorProvider
          .setMaxItemPage(PAGINATOR.NB_ITEM_PAGE)
          .setMaxItemPagination(PAGINATOR.NB_ITEM_PAGINATION);
      },
    ]);
}());

(function () {
  angular
    .module('partners-bo.components.directives')
    .directive('pageLimit', [
      PageLimitDirective,
    ])
  ;

  /**
   * @name PageLimitDirective
   * @desc <page-limit></page-limit>
   */
  function PageLimitDirective() {
    function PageLimitController($state, $stateParams, paginator) {
      const vm = this;
      vm.limit = $stateParams.limit || paginator.getMaxItemPage();
      // @todo maybe could be provided via paginator.getPossibleLimits ?
      vm.limits = [
        5,
        10,
        15,
        30,
        50,
        100,
      ];

      vm.onChange = function onChange() {
        $state.go($state.current.name, { limit: vm.limit });
      };
    }

    return {
      scope: {
      },
      restrict: 'E',
      controller: [
        '$state',
        '$stateParams',
        'paginator',
        PageLimitController,
      ],
      controllerAs: 'vm',
      templateUrl: '/components/directives/page-limit.html',
      bindToController: true,
    };
  }
}());

(function () {
  /**
   * locales config
   */
  angular
    .module('partners-bo')
    .constant('enabledLocales', [
      {
        code: 'en_US',
        name: 'English',
      },
      {
        code: 'es_ES',
        name: 'Español',
      },
      {
        code: 'fr_FR',
        name: 'Français',
      },
      {
        code: 'pt_PT',
        name: 'Português'
      }
    ])
    .constant('translationAvailableLocales', [
      {
        code: 'en_US',
        name: 'English',
      },
      {
        code: 'es_ES',
        name: 'Español',
      },
      {
        code: 'pt_PT',
        name: 'Português'
      }
    ])
    .constant('availableLocales', [
      {
        code: 'fr_FR',
        name: 'French (France)',
      },
      {
        code: 'en_US',
        name: 'English (United States)',
      },
      {
        code: 'af_NA',
        name: 'Afrikaans (Namibia)',
      },
      {
        code: 'af_ZA',
        name: 'Afrikaans (South Africa)',
      },
      {
        code: 'ak_GH',
        name: 'Akan (Ghana)',
      },
      {
        code: 'sq_AL',
        name: 'Albanian (Albania)',
      },
      {
        code: 'sq_XK',
        name: 'Albanian (Kosovo)',
      },
      {
        code: 'sq_MK',
        name: 'Albanian (Macedonia)',
      },
      {
        code: 'am_ET',
        name: 'Amharic (Ethiopia)',
      },
      {
        code: 'ar_DZ',
        name: 'Arabic (Algeria)',
      },
      {
        code: 'ar_BH',
        name: 'Arabic (Bahrain)',
      },
      {
        code: 'ar_TD',
        name: 'Arabic (Chad)',
      },
      {
        code: 'ar_KM',
        name: 'Arabic (Comoros)',
      },
      {
        code: 'ar_DJ',
        name: 'Arabic (Djibouti)',
      },
      {
        code: 'ar_EG',
        name: 'Arabic (Egypt)',
      },
      {
        code: 'ar_ER',
        name: 'Arabic (Eritrea)',
      },
      {
        code: 'ar_IQ',
        name: 'Arabic (Iraq)',
      },
      {
        code: 'ar_IL',
        name: 'Arabic (Israel)',
      },
      {
        code: 'ar_JO',
        name: 'Arabic (Jordan)',
      },
      {
        code: 'ar_KW',
        name: 'Arabic (Kuwait)',
      },
      {
        code: 'ar_LB',
        name: 'Arabic (Lebanon)',
      },
      {
        code: 'ar_LY',
        name: 'Arabic (Libya)',
      },
      {
        code: 'ar_MR',
        name: 'Arabic (Mauritania)',
      },
      {
        code: 'ar_MA',
        name: 'Arabic (Morocco)',
      },
      {
        code: 'ar_OM',
        name: 'Arabic (Oman)',
      },
      {
        code: 'ar_PS',
        name: 'Arabic (Palestinian Territories)',
      },
      {
        code: 'ar_QA',
        name: 'Arabic (Qatar)',
      },
      {
        code: 'ar_SA',
        name: 'Arabic (Saudi Arabia)',
      },
      {
        code: 'ar_SO',
        name: 'Arabic (Somalia)',
      },
      {
        code: 'ar_SS',
        name: 'Arabic (South Sudan)',
      },
      {
        code: 'ar_SD',
        name: 'Arabic (Sudan)',
      },
      {
        code: 'ar_SY',
        name: 'Arabic (Syria)',
      },
      {
        code: 'ar_TN',
        name: 'Arabic (Tunisia)',
      },
      {
        code: 'ar_AE',
        name: 'Arabic (United Arab Emirates)',
      },
      {
        code: 'ar_EH',
        name: 'Arabic (Western Sahara)',
      },
      {
        code: 'ar_YE',
        name: 'Arabic (Yemen)',
      },
      {
        code: 'hy_AM',
        name: 'Armenian (Armenia)',
      },
      {
        code: 'as_IN',
        name: 'Assamese (India)',
      },
      {
        code: 'az_AZ',
        name: 'Azerbaijani (Azerbaijan)',
      },
      {
        code: 'eu_ES',
        name: 'Basque (Spain)',
      },
      {
        code: 'be_BY',
        name: 'Belarusian (Belarus)',
      },
      {
        code: 'bn_BD',
        name: 'Bengali (Bangladesh)',
      },
      {
        code: 'bn_IN',
        name: 'Bengali (India)',
      },
      {
        code: 'bs_BA',
        name: 'Bosnian (Bosnia & Herzegovina)',
      },
      {
        code: 'br_FR',
        name: 'Breton (France)',
      },
      {
        code: 'bg_BG',
        name: 'Bulgarian (Bulgaria)',
      },
      {
        code: 'my_MM',
        name: 'Burmese (Myanmar (Burma))',
      },
      {
        code: 'ca_AD',
        name: 'Catalan (Andorra)',
      },
      {
        code: 'ca_FR',
        name: 'Catalan (France)',
      },
      {
        code: 'ca_IT',
        name: 'Catalan (Italy)',
      },
      {
        code: 'ca_ES',
        name: 'Catalan (Spain)',
      },
      {
        code: 'zh_CN',
        name: 'Chinese (China)',
      },
      {
        code: 'zh_HK',
        name: 'Chinese (Hong Kong SAR China)',
      },
      {
        code: 'zh_MO',
        name: 'Chinese (Macau SAR China)',
      },
      {
        code: 'zh_SG',
        name: 'Chinese (Singapore)',
      },
      {
        code: 'zh_TW',
        name: 'Chinese (Taiwan)',
      },
      {
        code: 'kw_GB',
        name: 'Cornish (United Kingdom)',
      },
      {
        code: 'hr_BA',
        name: 'Croatian (Bosnia & Herzegovina)',
      },
      {
        code: 'hr_HR',
        name: 'Croatian (Croatia)',
      },
      {
        code: 'cs_CZ',
        name: 'Czech (Czech Republic)',
      },
      {
        code: 'da_DK',
        name: 'Danish (Denmark)',
      },
      {
        code: 'da_GL',
        name: 'Danish (Greenland)',
      },
      {
        code: 'nl_AW',
        name: 'Dutch (Aruba)',
      },
      {
        code: 'nl_BE',
        name: 'Dutch (Belgium)',
      },
      {
        code: 'nl_BQ',
        name: 'Dutch (Caribbean Netherlands)',
      },
      {
        code: 'nl_CW',
        name: 'Dutch (Cura\u00e7ao)',
      },
      {
        code: 'nl_NL',
        name: 'Dutch (Netherlands)',
      },
      {
        code: 'nl_SX',
        name: 'Dutch (Sint Maarten)',
      },
      {
        code: 'nl_SR',
        name: 'Dutch (Suriname)',
      },
      {
        code: 'dz_BT',
        name: 'Dzongkha (Bhutan)',
      },
      {
        code: 'en_AS',
        name: 'English (American Samoa)',
      },
      {
        code: 'en_AI',
        name: 'English (Anguilla)',
      },
      {
        code: 'en_AG',
        name: 'English (Antigua & Barbuda)',
      },
      {
        code: 'en_AU',
        name: 'English (Australia)',
      },
      {
        code: 'en_BS',
        name: 'English (Bahamas)',
      },
      {
        code: 'en_BB',
        name: 'English (Barbados)',
      },
      {
        code: 'en_BE',
        name: 'English (Belgium)',
      },
      {
        code: 'en_BZ',
        name: 'English (Belize)',
      },
      {
        code: 'en_BM',
        name: 'English (Bermuda)',
      },
      {
        code: 'en_BW',
        name: 'English (Botswana)',
      },
      {
        code: 'en_IO',
        name: 'English (British Indian Ocean Territory)',
      },
      {
        code: 'en_VG',
        name: 'English (British Virgin Islands)',
      },
      {
        code: 'en_CM',
        name: 'English (Cameroon)',
      },
      {
        code: 'en_CA',
        name: 'English (Canada)',
      },
      {
        code: 'en_KY',
        name: 'English (Cayman Islands)',
      },
      {
        code: 'en_CX',
        name: 'English (Christmas Island)',
      },
      {
        code: 'en_CC',
        name: 'English (Cocos (Keeling) Islands)',
      },
      {
        code: 'en_CK',
        name: 'English (Cook Islands)',
      },
      {
        code: 'en_DG',
        name: 'English (Diego Garcia)',
      },
      {
        code: 'en_DM',
        name: 'English (Dominica)',
      },
      {
        code: 'en_ER',
        name: 'English (Eritrea)',
      },
      {
        code: 'en_FK',
        name: 'English (Falkland Islands)',
      },
      {
        code: 'en_FJ',
        name: 'English (Fiji)',
      },
      {
        code: 'en_GM',
        name: 'English (Gambia)',
      },
      {
        code: 'en_GH',
        name: 'English (Ghana)',
      },
      {
        code: 'en_GI',
        name: 'English (Gibraltar)',
      },
      {
        code: 'en_GD',
        name: 'English (Grenada)',
      },
      {
        code: 'en_GU',
        name: 'English (Guam)',
      },
      {
        code: 'en_GG',
        name: 'English (Guernsey)',
      },
      {
        code: 'en_GY',
        name: 'English (Guyana)',
      },
      {
        code: 'en_HK',
        name: 'English (Hong Kong SAR China)',
      },
      {
        code: 'en_IN',
        name: 'English (India)',
      },
      {
        code: 'en_IE',
        name: 'English (Ireland)',
      },
      {
        code: 'en_IM',
        name: 'English (Isle of Man)',
      },
      {
        code: 'en_JM',
        name: 'English (Jamaica)',
      },
      {
        code: 'en_JE',
        name: 'English (Jersey)',
      },
      {
        code: 'en_KE',
        name: 'English (Kenya)',
      },
      {
        code: 'en_KI',
        name: 'English (Kiribati)',
      },
      {
        code: 'en_LS',
        name: 'English (Lesotho)',
      },
      {
        code: 'en_LR',
        name: 'English (Liberia)',
      },
      {
        code: 'en_MO',
        name: 'English (Macau SAR China)',
      },
      {
        code: 'en_MG',
        name: 'English (Madagascar)',
      },
      {
        code: 'en_MW',
        name: 'English (Malawi)',
      },
      {
        code: 'en_MY',
        name: 'English (Malaysia)',
      },
      {
        code: 'en_MT',
        name: 'English (Malta)',
      },
      {
        code: 'en_MH',
        name: 'English (Marshall Islands)',
      },
      {
        code: 'en_MU',
        name: 'English (Mauritius)',
      },
      {
        code: 'en_FM',
        name: 'English (Micronesia)',
      },
      {
        code: 'en_MS',
        name: 'English (Montserrat)',
      },
      {
        code: 'en_NA',
        name: 'English (Namibia)',
      },
      {
        code: 'en_NR',
        name: 'English (Nauru)',
      },
      {
        code: 'en_NZ',
        name: 'English (New Zealand)',
      },
      {
        code: 'en_NG',
        name: 'English (Nigeria)',
      },
      {
        code: 'en_NU',
        name: 'English (Niue)',
      },
      {
        code: 'en_NF',
        name: 'English (Norfolk Island)',
      },
      {
        code: 'en_MP',
        name: 'English (Northern Mariana Islands)',
      },
      {
        code: 'en_PK',
        name: 'English (Pakistan)',
      },
      {
        code: 'en_PW',
        name: 'English (Palau)',
      },
      {
        code: 'en_PG',
        name: 'English (Papua New Guinea)',
      },
      {
        code: 'en_PH',
        name: 'English (Philippines)',
      },
      {
        code: 'en_PN',
        name: 'English (Pitcairn Islands)',
      },
      {
        code: 'en_PR',
        name: 'English (Puerto Rico)',
      },
      {
        code: 'en_RW',
        name: 'English (Rwanda)',
      },
      {
        code: 'en_WS',
        name: 'English (Samoa)',
      },
      {
        code: 'en_SC',
        name: 'English (Seychelles)',
      },
      {
        code: 'en_SL',
        name: 'English (Sierra Leone)',
      },
      {
        code: 'en_SG',
        name: 'English (Singapore)',
      },
      {
        code: 'en_SX',
        name: 'English (Sint Maarten)',
      },
      {
        code: 'en_SB',
        name: 'English (Solomon Islands)',
      },
      {
        code: 'en_ZA',
        name: 'English (South Africa)',
      },
      {
        code: 'en_SS',
        name: 'English (South Sudan)',
      },
      {
        code: 'en_SH',
        name: 'English (St. Helena)',
      },
      {
        code: 'en_KN',
        name: 'English (St. Kitts & Nevis)',
      },
      {
        code: 'en_LC',
        name: 'English (St. Lucia)',
      },
      {
        code: 'en_VC',
        name: 'English (St. Vincent & Grenadines)',
      },
      {
        code: 'en_SD',
        name: 'English (Sudan)',
      },
      {
        code: 'en_SZ',
        name: 'English (Swaziland)',
      },
      {
        code: 'en_TZ',
        name: 'English (Tanzania)',
      },
      {
        code: 'en_TK',
        name: 'English (Tokelau)',
      },
      {
        code: 'en_TO',
        name: 'English (Tonga)',
      },
      {
        code: 'en_TT',
        name: 'English (Trinidad & Tobago)',
      },
      {
        code: 'en_TC',
        name: 'English (Turks & Caicos Islands)',
      },
      {
        code: 'en_TV',
        name: 'English (Tuvalu)',
      },
      {
        code: 'en_UM',
        name: 'English (U.S. Outlying Islands)',
      },
      {
        code: 'en_VI',
        name: 'English (U.S. Virgin Islands)',
      },
      {
        code: 'en_UG',
        name: 'English (Uganda)',
      },
      {
        code: 'en_GB',
        name: 'English (United Kingdom)',
      },
      {
        code: 'en_VU',
        name: 'English (Vanuatu)',
      },
      {
        code: 'en_ZM',
        name: 'English (Zambia)',
      },
      {
        code: 'en_ZW',
        name: 'English (Zimbabwe)',
      },
      {
        code: 'et_EE',
        name: 'Estonian (Estonia)',
      },
      {
        code: 'ee_GH',
        name: 'Ewe (Ghana)',
      },
      {
        code: 'ee_TG',
        name: 'Ewe (Togo)',
      },
      {
        code: 'fo_FO',
        name: 'Faroese (Faroe Islands)',
      },
      {
        code: 'fi_FI',
        name: 'Finnish (Finland)',
      },
      {
        code: 'fr_DZ',
        name: 'French (Algeria)',
      },
      {
        code: 'fr_BE',
        name: 'French (Belgium)',
      },
      {
        code: 'fr_BJ',
        name: 'French (Benin)',
      },
      {
        code: 'fr_BF',
        name: 'French (Burkina Faso)',
      },
      {
        code: 'fr_BI',
        name: 'French (Burundi)',
      },
      {
        code: 'fr_CM',
        name: 'French (Cameroon)',
      },
      {
        code: 'fr_CA',
        name: 'French (Canada)',
      },
      {
        code: 'fr_CF',
        name: 'French (Central African Republic)',
      },
      {
        code: 'fr_TD',
        name: 'French (Chad)',
      },
      {
        code: 'fr_KM',
        name: 'French (Comoros)',
      },
      {
        code: 'fr_CG',
        name: 'French (Congo - Brazzaville)',
      },
      {
        code: 'fr_CD',
        name: 'French (Congo - Kinshasa)',
      },
      {
        code: 'fr_CI',
        name: 'French (C\u00f4te d\u2019Ivoire)',
      },
      {
        code: 'fr_DJ',
        name: 'French (Djibouti)',
      },
      {
        code: 'fr_GQ',
        name: 'French (Equatorial Guinea)',
      },
      {
        code: 'fr_GF',
        name: 'French (French Guiana)',
      },
      {
        code: 'fr_PF',
        name: 'French (French Polynesia)',
      },
      {
        code: 'fr_GA',
        name: 'French (Gabon)',
      },
      {
        code: 'fr_GP',
        name: 'French (Guadeloupe)',
      },
      {
        code: 'fr_GN',
        name: 'French (Guinea)',
      },
      {
        code: 'fr_HT',
        name: 'French (Haiti)',
      },
      {
        code: 'fr_LU',
        name: 'French (Luxembourg)',
      },
      {
        code: 'fr_MG',
        name: 'French (Madagascar)',
      },
      {
        code: 'fr_ML',
        name: 'French (Mali)',
      },
      {
        code: 'fr_MQ',
        name: 'French (Martinique)',
      },
      {
        code: 'fr_MR',
        name: 'French (Mauritania)',
      },
      {
        code: 'fr_MU',
        name: 'French (Mauritius)',
      },
      {
        code: 'fr_YT',
        name: 'French (Mayotte)',
      },
      {
        code: 'fr_MC',
        name: 'French (Monaco)',
      },
      {
        code: 'fr_MA',
        name: 'French (Morocco)',
      },
      {
        code: 'fr_NC',
        name: 'French (New Caledonia)',
      },
      {
        code: 'fr_NE',
        name: 'French (Niger)',
      },
      {
        code: 'fr_RE',
        name: 'French (R\u00e9union)',
      },
      {
        code: 'fr_RW',
        name: 'French (Rwanda)',
      },
      {
        code: 'fr_SN',
        name: 'French (Senegal)',
      },
      {
        code: 'fr_SC',
        name: 'French (Seychelles)',
      },
      {
        code: 'fr_BL',
        name: 'French (St. Barth\u00e9lemy)',
      },
      {
        code: 'fr_MF',
        name: 'French (St. Martin)',
      },
      {
        code: 'fr_PM',
        name: 'French (St. Pierre & Miquelon)',
      },
      {
        code: 'fr_CH',
        name: 'French (Switzerland)',
      },
      {
        code: 'fr_SY',
        name: 'French (Syria)',
      },
      {
        code: 'fr_TG',
        name: 'French (Togo)',
      },
      {
        code: 'fr_TN',
        name: 'French (Tunisia)',
      },
      {
        code: 'fr_VU',
        name: 'French (Vanuatu)',
      },
      {
        code: 'fr_WF',
        name: 'French (Wallis & Futuna)',
      },
      {
        code: 'ff_CM',
        name: 'Fulah (Cameroon)',
      },
      {
        code: 'ff_GN',
        name: 'Fulah (Guinea)',
      },
      {
        code: 'ff_MR',
        name: 'Fulah (Mauritania)',
      },
      {
        code: 'ff_SN',
        name: 'Fulah (Senegal)',
      },
      {
        code: 'gl_ES',
        name: 'Galician (Spain)',
      },
      {
        code: 'lg_UG',
        name: 'Ganda (Uganda)',
      },
      {
        code: 'ka_GE',
        name: 'Georgian (Georgia)',
      },
      {
        code: 'de_AT',
        name: 'German (Austria)',
      },
      {
        code: 'de_BE',
        name: 'German (Belgium)',
      },
      {
        code: 'de_DE',
        name: 'German (Germany)',
      },
      {
        code: 'de_LI',
        name: 'German (Liechtenstein)',
      },
      {
        code: 'de_LU',
        name: 'German (Luxembourg)',
      },
      {
        code: 'de_CH',
        name: 'German (Switzerland)',
      },
      {
        code: 'el_CY',
        name: 'Greek (Cyprus)',
      },
      {
        code: 'el_GR',
        name: 'Greek (Greece)',
      },
      {
        code: 'gu_IN',
        name: 'Gujarati (India)',
      },
      {
        code: 'ha_GH',
        name: 'Hausa (Ghana)',
      },
      {
        code: 'ha_NE',
        name: 'Hausa (Niger)',
      },
      {
        code: 'ha_NG',
        name: 'Hausa (Nigeria)',
      },
      {
        code: 'he_IL',
        name: 'Hebrew (Israel)',
      },
      {
        code: 'hi_IN',
        name: 'Hindi (India)',
      },
      {
        code: 'hu_HU',
        name: 'Hungarian (Hungary)',
      },
      {
        code: 'is_IS',
        name: 'Icelandic (Iceland)',
      },
      {
        code: 'ig_NG',
        name: 'Igbo (Nigeria)',
      },
      {
        code: 'id_ID',
        name: 'Indonesian (Indonesia)',
      },
      {
        code: 'ga_IE',
        name: 'Irish (Ireland)',
      },
      {
        code: 'it_IT',
        name: 'Italian (Italy)',
      },
      {
        code: 'it_SM',
        name: 'Italian (San Marino)',
      },
      {
        code: 'it_CH',
        name: 'Italian (Switzerland)',
      },
      {
        code: 'ja_JP',
        name: 'Japanese (Japan)',
      },
      {
        code: 'kl_GL',
        name: 'Kalaallisut (Greenland)',
      },
      {
        code: 'kn_IN',
        name: 'Kannada (India)',
      },
      {
        code: 'ks_IN',
        name: 'Kashmiri (India)',
      },
      {
        code: 'kk_KZ',
        name: 'Kazakh (Kazakhstan)',
      },
      {
        code: 'km_KH',
        name: 'Khmer (Cambodia)',
      },
      {
        code: 'ki_KE',
        name: 'Kikuyu (Kenya)',
      },
      {
        code: 'rw_RW',
        name: 'Kinyarwanda (Rwanda)',
      },
      {
        code: 'ko_KP',
        name: 'Korean (North Korea)',
      },
      {
        code: 'ko_KR',
        name: 'Korean (South Korea)',
      },
      {
        code: 'ky_KG',
        name: 'Kyrgyz (Kyrgyzstan)',
      },
      {
        code: 'lo_LA',
        name: 'Lao (Laos)',
      },
      {
        code: 'lv_LV',
        name: 'Latvian (Latvia)',
      },
      {
        code: 'ln_AO',
        name: 'Lingala (Angola)',
      },
      {
        code: 'ln_CF',
        name: 'Lingala (Central African Republic)',
      },
      {
        code: 'ln_CG',
        name: 'Lingala (Congo - Brazzaville)',
      },
      {
        code: 'ln_CD',
        name: 'Lingala (Congo - Kinshasa)',
      },
      {
        code: 'lt_LT',
        name: 'Lithuanian (Lithuania)',
      },
      {
        code: 'lu_CD',
        name: 'Luba-Katanga (Congo - Kinshasa)',
      },
      {
        code: 'lb_LU',
        name: 'Luxembourgish (Luxembourg)',
      },
      {
        code: 'mk_MK',
        name: 'Macedonian (Macedonia)',
      },
      {
        code: 'mg_MG',
        name: 'Malagasy (Madagascar)',
      },
      {
        code: 'ms_BN',
        name: 'Malay (Brunei)',
      },
      {
        code: 'ms_MY',
        name: 'Malay (Malaysia)',
      },
      {
        code: 'ms_SG',
        name: 'Malay (Singapore)',
      },
      {
        code: 'ml_IN',
        name: 'Malayalam (India)',
      },
      {
        code: 'mt_MT',
        name: 'Maltese (Malta)',
      },
      {
        code: 'gv_IM',
        name: 'Manx (Isle of Man)',
      },
      {
        code: 'mr_IN',
        name: 'Marathi (India)',
      },
      {
        code: 'mn_MN',
        name: 'Mongolian (Mongolia)',
      },
      {
        code: 'ne_IN',
        name: 'Nepali (India)',
      },
      {
        code: 'ne_NP',
        name: 'Nepali (Nepal)',
      },
      {
        code: 'nd_ZW',
        name: 'North Ndebele (Zimbabwe)',
      },
      {
        code: 'se_FI',
        name: 'Northern Sami (Finland)',
      },
      {
        code: 'se_NO',
        name: 'Northern Sami (Norway)',
      },
      {
        code: 'se_SE',
        name: 'Northern Sami (Sweden)',
      },
      {
        code: 'no_NO',
        name: 'Norwegian (Norway)',
      },
      {
        code: 'nb_NO',
        name: 'Norwegian Bokm\u00e5l (Norway)',
      },
      {
        code: 'nb_SJ',
        name: 'Norwegian Bokm\u00e5l (Svalbard & Jan Mayen)',
      },
      {
        code: 'nn_NO',
        name: 'Norwegian Nynorsk (Norway)',
      },
      {
        code: 'or_IN',
        name: 'Oriya (India)',
      },
      {
        code: 'om_ET',
        name: 'Oromo (Ethiopia)',
      },
      {
        code: 'om_KE',
        name: 'Oromo (Kenya)',
      },
      {
        code: 'os_GE',
        name: 'Ossetic (Georgia)',
      },
      {
        code: 'os_RU',
        name: 'Ossetic (Russia)',
      },
      {
        code: 'ps_AF',
        name: 'Pashto (Afghanistan)',
      },
      {
        code: 'fa_AF',
        name: 'Persian (Afghanistan)',
      },
      {
        code: 'fa_IR',
        name: 'Persian (Iran)',
      },
      {
        code: 'pl_PL',
        name: 'Polish (Poland)',
      },
      {
        code: 'pt_AO',
        name: 'Portuguese (Angola)',
      },
      {
        code: 'pt_BR',
        name: 'Portuguese (Brazil)',
      },
      {
        code: 'pt_CV',
        name: 'Portuguese (Cape Verde)',
      },
      {
        code: 'pt_GW',
        name: 'Portuguese (Guinea-Bissau)',
      },
      {
        code: 'pt_MO',
        name: 'Portuguese (Macau SAR China)',
      },
      {
        code: 'pt_MZ',
        name: 'Portuguese (Mozambique)',
      },
      {
        code: 'pt_PT',
        name: 'Portuguese (Portugal)',
      },
      {
        code: 'pt_ST',
        name: 'Portuguese (S\u00e3o Tom\u00e9 & Pr\u00edncipe)',
      },
      {
        code: 'pt_TL',
        name: 'Portuguese (Timor-Leste)',
      },
      {
        code: 'pa_IN',
        name: 'Punjabi (India)',
      },
      {
        code: 'pa_PK',
        name: 'Punjabi (Pakistan)',
      },
      {
        code: 'qu_BO',
        name: 'Quechua (Bolivia)',
      },
      {
        code: 'qu_EC',
        name: 'Quechua (Ecuador)',
      },
      {
        code: 'qu_PE',
        name: 'Quechua (Peru)',
      },
      {
        code: 'ro_MD',
        name: 'Romanian (Moldova)',
      },
      {
        code: 'ro_RO',
        name: 'Romanian (Romania)',
      },
      {
        code: 'rm_CH',
        name: 'Romansh (Switzerland)',
      },
      {
        code: 'rn_BI',
        name: 'Rundi (Burundi)',
      },
      {
        code: 'ru_BY',
        name: 'Russian (Belarus)',
      },
      {
        code: 'ru_KZ',
        name: 'Russian (Kazakhstan)',
      },
      {
        code: 'ru_KG',
        name: 'Russian (Kyrgyzstan)',
      },
      {
        code: 'ru_MD',
        name: 'Russian (Moldova)',
      },
      {
        code: 'ru_RU',
        name: 'Russian (Russia)',
      },
      {
        code: 'ru_UA',
        name: 'Russian (Ukraine)',
      },
      {
        code: 'sg_CF',
        name: 'Sango (Central African Republic)',
      },
      {
        code: 'gd_GB',
        name: 'Scottish Gaelic (United Kingdom)',
      },
      {
        code: 'sr_BA',
        name: 'Serbian (Bosnia & Herzegovina)',
      },
      {
        code: 'sr_ME',
        name: 'Serbian (Montenegro)',
      },
      {
        code: 'sr_RS',
        name: 'Serbian (Serbia)',
      },
      {
        code: 'sh_BA',
        name: 'Serbo-Croatian (Bosnia & Herzegovina)',
      },
      {
        code: 'sn_ZW',
        name: 'Shona (Zimbabwe)',
      },
      {
        code: 'ii_CN',
        name: 'Sichuan Yi (China)',
      },
      {
        code: 'si_LK',
        name: 'Sinhala (Sri Lanka)',
      },
      {
        code: 'sk_SK',
        name: 'Slovak (Slovakia)',
      },
      {
        code: 'sl_SI',
        name: 'Slovenian (Slovenia)',
      },
      {
        code: 'so_DJ',
        name: 'Somali (Djibouti)',
      },
      {
        code: 'so_ET',
        name: 'Somali (Ethiopia)',
      },
      {
        code: 'so_KE',
        name: 'Somali (Kenya)',
      },
      {
        code: 'so_SO',
        name: 'Somali (Somalia)',
      },
      {
        code: 'es_AR',
        name: 'Spanish (Argentina)',
      },
      {
        code: 'es_BO',
        name: 'Spanish (Bolivia)',
      },
      {
        code: 'es_IC',
        name: 'Spanish (Canary Islands)',
      },
      {
        code: 'es_EA',
        name: 'Spanish (Ceuta & Melilla)',
      },
      {
        code: 'es_CL',
        name: 'Spanish (Chile)',
      },
      {
        code: 'es_CO',
        name: 'Spanish (Colombia)',
      },
      {
        code: 'es_CR',
        name: 'Spanish (Costa Rica)',
      },
      {
        code: 'es_CU',
        name: 'Spanish (Cuba)',
      },
      {
        code: 'es_DO',
        name: 'Spanish (Dominican Republic)',
      },
      {
        code: 'es_EC',
        name: 'Spanish (Ecuador)',
      },
      {
        code: 'es_SV',
        name: 'Spanish (El Salvador)',
      },
      {
        code: 'es_GQ',
        name: 'Spanish (Equatorial Guinea)',
      },
      {
        code: 'es_GT',
        name: 'Spanish (Guatemala)',
      },
      {
        code: 'es_HN',
        name: 'Spanish (Honduras)',
      },
      {
        code: 'es_MX',
        name: 'Spanish (Mexico)',
      },
      {
        code: 'es_NI',
        name: 'Spanish (Nicaragua)',
      },
      {
        code: 'es_PA',
        name: 'Spanish (Panama)',
      },
      {
        code: 'es_PY',
        name: 'Spanish (Paraguay)',
      },
      {
        code: 'es_PE',
        name: 'Spanish (Peru)',
      },
      {
        code: 'es_PH',
        name: 'Spanish (Philippines)',
      },
      {
        code: 'es_PR',
        name: 'Spanish (Puerto Rico)',
      },
      {
        code: 'es_ES',
        name: 'Spanish (Spain)',
      },
      {
        code: 'es_US',
        name: 'Spanish (United States)',
      },
      {
        code: 'es_UY',
        name: 'Spanish (Uruguay)',
      },
      {
        code: 'es_VE',
        name: 'Spanish (Venezuela)',
      },
      {
        code: 'sw_KE',
        name: 'Swahili (Kenya)',
      },
      {
        code: 'sw_TZ',
        name: 'Swahili (Tanzania)',
      },
      {
        code: 'sw_UG',
        name: 'Swahili (Uganda)',
      },
      {
        code: 'sv_AX',
        name: 'Swedish (\u00c5land Islands)',
      },
      {
        code: 'sv_FI',
        name: 'Swedish (Finland)',
      },
      {
        code: 'sv_SE',
        name: 'Swedish (Sweden)',
      },
      {
        code: 'tl_PH',
        name: 'Tagalog (Philippines)',
      },
      {
        code: 'ta_IN',
        name: 'Tamil (India)',
      },
      {
        code: 'ta_MY',
        name: 'Tamil (Malaysia)',
      },
      {
        code: 'ta_SG',
        name: 'Tamil (Singapore)',
      },
      {
        code: 'ta_LK',
        name: 'Tamil (Sri Lanka)',
      },
      {
        code: 'te_IN',
        name: 'Telugu (India)',
      },
      {
        code: 'th_TH',
        name: 'Thai (Thailand)',
      },
      {
        code: 'bo_CN',
        name: 'Tibetan (China)',
      },
      {
        code: 'bo_IN',
        name: 'Tibetan (India)',
      },
      {
        code: 'ti_ER',
        name: 'Tigrinya (Eritrea)',
      },
      {
        code: 'ti_ET',
        name: 'Tigrinya (Ethiopia)',
      },
      {
        code: 'to_TO',
        name: 'Tongan (Tonga)',
      },
      {
        code: 'tr_CY',
        name: 'Turkish (Cyprus)',
      },
      {
        code: 'tr_TR',
        name: 'Turkish (Turkey)',
      },
      {
        code: 'uk_UA',
        name: 'Ukrainian (Ukraine)',
      },
      {
        code: 'ur_IN',
        name: 'Urdu (India)',
      },
      {
        code: 'ur_PK',
        name: 'Urdu (Pakistan)',
      },
      {
        code: 'ug_CN',
        name: 'Uyghur (China)',
      },
      {
        code: 'uz_AF',
        name: 'Uzbek (Afghanistan)',
      },
      {
        code: 'uz_UZ',
        name: 'Uzbek (Uzbekistan)',
      },
      {
        code: 'vi_VN',
        name: 'Vietnamese (Vietnam)',
      },
      {
        code: 'cy_GB',
        name: 'Welsh (United Kingdom)',
      },
      {
        code: 'fy_NL',
        name: 'Western Frisian (Netherlands)',
      },
      {
        code: 'yo_BJ',
        name: 'Yoruba (Benin)',
      },
      {
        code: 'yo_NG',
        name: 'Yoruba (Nigeria)',
      },
      {
        code: 'zu_ZA',
        name: 'Zulu (South Africa)',
      },
    ]);
}());

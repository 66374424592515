(function () {
  /**
   * Translate config
   */
  angular
    .module('partners-bo')
    .config([
      '$translateProvider',
      '$ngBootboxConfigProvider',
      function ($translateProvider, $ngBootboxConfigProvider) {
        $translateProvider
          .useStaticFilesLoader({
            prefix: '/translations/locale-',
            suffix: '.json',
          })
          .registerAvailableLanguageKeys(['fr'])
          .preferredLanguage('fr')
          .fallbackLanguage('fr')
          .useLocalStorage()
          .useMessageFormatInterpolation()
          // before changing what's following, please read
          // http://stackoverflow.com/questions/31002499/angular-translate-sanitisation-fails-with-utf-characters
          .useSanitizeValueStrategy('escapeParameters')
        ;
        $ngBootboxConfigProvider.addLocale('fr', { OK: 'OK', CANCEL: 'Annuler', CONFIRM: 'Confirmer' });
      },
    ])
    .run([
      '$rootScope',
      '$translate',
      '$ngBootbox',
      'amMoment',
      function ($rootScope, $translate, $ngBootbox, amMoment) {
        $rootScope.$on('ready', () => {
          $translate.use('fr');
          amMoment.changeLocale($translate.use());
          $ngBootbox.setLocale($translate.use());
        });
      },
    ]);
}());

(function () {
  /**
   * Loading bar config
   */
  angular
    .module('partners-bo')
    .config([
      'cfpLoadingBarProvider',
      function (cfpLoadingBarProvider) {
        cfpLoadingBarProvider.includeSpinner = false;
        cfpLoadingBarProvider.loadingBarTemplate = '<div class="progress progress--fixed-top"><div class="progress__bar" role="progressbar"></div></div>';
      },
    ]);
}());

(function () {
  angular
    .module('partners-bo.components.directives')
    .directive('limitedList', [
      LimitedListDirective,
    ])
  ;

  /**
   * @name LimitedListDirective
   * @desc <limited-list></limited-list>
   */
  function LimitedListDirective() {
    function LimitedListController() {
      const vm = this;
      vm.tooltipContent = '';
      if ('function' === typeof vm.displayFunction) {
        vm.displayFunction = vm.displayFunction();
      } else {
        vm.displayFunction = function (element) {
          return element;
        };
      }
      if (vm.list.length > vm.limit) {
        angular.forEach(vm.list, (element, key) => {
          vm.tooltipContent += vm.displayFunction(element);
          if (key <= vm.list.length) {
            vm.tooltipContent += ', ';
          }
        });
      }
    }

    return {
      scope: {
        list: '=',
        limit: '@',
        displayFunction: '&?',
      },
      restrict: 'E',
      controller: [
        LimitedListController,
      ],
      controllerAs: 'vm',
      templateUrl: '/components/directives/limited-list.html',
      bindToController: true,
    };
  }
}());

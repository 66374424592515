(function () {
  /**
   * Use this directive to display a mark in the form of filled/empty colored stars
   */
  angular
    .module('partners-bo.components.directives')
    .directive('displayStars', [
      DisplayStarsDirective,
    ])
  ;

  /**
   * @name DisplayStarsDirective
   * @desc <display-stars></display-stars>
   */
  function DisplayStarsDirective() {
    function DisplayStarsController() {
      const vm = this;

      vm.max = vm.max ? parseInt(vm.max) : 5;
      vm.ratio = parseFloat(vm.mark) / vm.max;
      vm.mark = Math.round(vm.mark);

      if (vm.ratio < 0.33) {
        vm.colorClass = 'text-danger';
      } else if (vm.ratio > 0.66) {
        vm.colorClass = 'text-success';
      } else {
        vm.colorClass = 'text-warning';
      }

      vm.getNumber = function (num) {
        return new Array(Math.round(num));
      };
    }

    return {
      scope: {
        mark: '@',
        max: '@?',
      },
      restrict: 'E',
      controller: [
        DisplayStarsController,
      ],
      controllerAs: 'vm',
      templateUrl: '/components/directives/display-stars.html',
      bindToController: true,
    };
  }
}());

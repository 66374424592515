(function () {
  /**
   * This class is used to generate external link (to creads platform)
   */
  angular
    .module('partners-bo.components.services')
    .service('creadsLink', [
      'SERVER',
      function partnersLink(SERVER) {
        const vm = this;

        vm.getProjectUrl = function getProjectUrl(shortCode) {
          return `${SERVER.APP_URL}/crm/project/code/${shortCode}`;
        };

        vm.getFrontProjectUrl = function getFrontProjectUrl(shortCode) {
          return `${SERVER.APP_URL}/crm/project/code/${shortCode}/frontend`;
        };
      },
    ]);
}());

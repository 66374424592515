/* eslint-disable */
(function () {
  'use strict';

  angular.module('partners-bo')
    .constant('TIME', {
      MAX: 1,
    })

    .constant('SERVER', {
      API_ENDPOINT: envConfig.api.endpoint,
      MAX_FILE_SIZE: envConfig.console.maxFileSize,
      BUSINESS_URL: envConfig.business.url,
      CONSOLE_URL: envConfig.console.url,
      APP_URL: envConfig.creads.url,
    })

    .constant('PROJECT', {
      STATUS: {
        IN_PROGRESS: 'in_progress',
        FINISHED: 'finished',
        PAID: 'paid',
        WAITING_FOR_SOURCES: 'waiting_for_sources',
        WAITING_FOR_DECISION: 'waiting_for_decision',
        CANCELED: 'canceled',
      },
      STATE: {
        DRAFT: 'draft',
        CANCELED: 'canceled',
        PUBLISHED: 'published',
        REQUIRE_APPROVAL: 'require_approval',
        WAITING_FOR_PROPOSAL: 'waiting_for_proposal',
        PROPOSAL: 'proposal',
        ARCHIVED: 'archived',
        TO_PUBLISH: 'to_publish',
      },
    })

    .constant('ROLES', {
      CUSTOMER: 'customer',
      ADMIN: 'admin',
      SUPER_ADMIN: 'super_admin',
      SALES_ADMIN: 'sales_admin',
      DESIGNER: 'designer',
      PRICING_ADMIN: 'pricing_admin',
      RESELLER: 'reseller',
    })

    .constant('PRODUCT', {
      INTERFACE: {
        DEFAULT: 'default',
        TEXT: 'text',
      },
      WEIGHT_INTERVAL: 131072,
    })

    .constant('CREADS', {
      MEDIA_TYPE: {
        MEDIA: 'media',
        TEXT: 'text',
      },
    })

    .constant('PAGINATOR', {
      NB_ITEM_PAGE: 10,
      NB_ITEM_PAGINATION: 8,
    })

    .constant('NOTIFS', {
      TYPE: {
        NEW_PROJECT: 'project.new',
        NEW_USER: 'user.new',
        NEW_WORK: 'work.new',
        NEW_COMMENT: 'comment.new',
        NEW_WINNER_WORK: 'project.winner.new',
        NEW_MESSAGE_DELIVERY: 'project.message.delivery.new',
        NEW_MESSAGE: 'project.message.new',
        NEW_WINNER_DELIVERY: 'project.message.delivery.winner',
        CREDIT_BREAKDOWN_TYPE: 'credit.breakdown',
      },
    })

    .constant('DEFAULT_REFERRAL', {
      AMOUNT: 25.00,
      ENABLED: true,
    })

    .constant('DEFAULT_ORGANIZATION_OWNER', '7ac20o17e1337')
    .constant('DEFAULT_TEMPLATE_ORG_GID', '7fb63e39e3204')
    .constant('REDIRECT_STATE', 'auth.login')

    .constant('PAYMENT_METHOD', {
      OFFLINE: 'offline',
      SMART_TRESO: 'smart_treso',
      ONLINE: 'online',
    })

    .constant('PAYMENT_STATUS', {
      OFFLINE: 'offline',
      SMART_TRESO: 'smart_treso',
      ONLINE_OK: 'online_ok',
      ONLINE_KO: 'online_ko',
    })

    .constant('INVOICE_STATUS', {
      PAID_STATUS: 'paid',
      PAYMENT_IN_PROGRESS_STATUS: 'payment_in_progress',
      TO_PAY_STATUS: 'to_pay',
      IN_TIME_TO_PAY_STATUS: 'in_time_to_pay',
      LATE_TO_PAY_STATUS: 'late_to_pay',
    })

    .constant('INVOICE_DUE_DAY_TYPE', {
      EXACT: 'exact',
      END_OF_MONTH: 'end_of_month',
      AT_X_OF_MONTH: 'at_x_of_month',
    })

    .constant('CREDIT_NOTE', {
      STATUS: {
        PAID: 'paid',
        TO_PAY: 'to_pay',
      },
    })

    .constant('COUPON', {
      ORIGIN: {
        DEFAULT: 'manual',
        CAMPAIGN: 'campaign',
        INVITATION_SENDER: 'sender',
        INVITATION_RECEIVER: 'receiver',
      },
      STATE: {
        INVALID: 'invalid',
        VALID: 'valid',
        DELETED: 'deleted',
      },
    })

    .constant('STEPS', {
      TYPE: {
        PRODUCT: 'product',
        DESCRIPTION: 'description',
        FILES: 'files',
        QUANTITY: 'quantity',
        SKILL: 'skill',
        MODE: 'mode',
        TEXT: 'text',
        TEXTAREA: 'textarea',
        DATE: 'date',
        SELECT: 'select',
        RANGE: 'range',
        SLIDER: 'slider',
        NUMBER: 'number',
        RADIO: 'radio',
        RADIO_IMAGE: 'radio_image',
        CHECKBOX: 'checkbox',
        NUMBERS: 'numbers'
      },
      OPERATOR: {
        ADDITION: 'addition',
        MULTIPLICATION: 'multiplication',
        ANY: 'any'
      },
      THRESHOLD: {
        SALE: 'sale',
        PURCHASE: 'purchase'
      }
    })

  ;
}());

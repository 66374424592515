(function () {
  /**
  * Product service
  */
  angular
    .module('partners-bo.components.services')
    .service('productHelper', [
      'CREADS',
      'PRODUCT',
      '$http',
      '$q',
      'api',
      function productHelper(CREADS, PRODUCT, $http, $q, api) {
        const vm = this;

        /**
         * Refresh modes object in product
         * with mode object given
         * @param  {object} modes
         * @param  {object} product
         * @return {object}
         */
        vm.changeMode = function changeMode(modes, product) {
          Object.keys(modes).forEach(function (mode) {
            if (this[mode]) {
              if (-1 == product.available_options.mode.enum.indexOf(mode)) {
                product.available_options.mode.enum.push(mode);
                product.additional_meta = {
                  brief_template: '',
                };
              }
            } else if (product.available_options.mode.enum.indexOf(mode)) {
              product.available_options.mode.enum.splice(product.available_options.mode.enum.indexOf(mode), 1);
            }
          }, modes);

          return product;
        };

        /**
         * Update default product mode
         * if not exist
         * @param  {object} product
         * @return {object}
         */
        vm.changeDefaultMode = function changeDefaultMode(product) {
          if (-1 == product.available_options.mode.enum.indexOf(product.available_options.mode.default)) {
            product.available_options.mode.default = product.available_options.mode.enum[0];
          }

          return product;
        };

        vm.getCreadsProducts = function getCreadsProducts() {
          const deferred = $q.defer();
          $http
            .get('/creadsProducts.json')
            .success((data) => {
              deferred.resolve(data);
            })
            .error((data) => {
              deferred.reject(data);
            });
          return deferred.promise;
        };

        vm.getPartnersTypeFromCreadsMediaType = function getPartnersTypeFromCreadsMediaType(mediaType) {
          const mediaTypes = {};
          mediaTypes[CREADS.MEDIA_TYPE.MEDIA] = PRODUCT.INTERFACE.DEFAULT;
          mediaTypes[CREADS.MEDIA_TYPE.TEXT] = PRODUCT.INTERFACE.TEXT;

          return mediaTypes[mediaType];
        };

        vm.getPricesMapFromProduct = getPricesMapFromProduct;

        /**
         * build a pricesMap with this look:
         * {
         *   conception: {
         *     solo: {
         *       1: // price object,
         *       2: // price object,
         *       5: // price object,
         *       10: // price object
         *     }
         *     multi: {
         *       1: // price object,
         *       2: // price object,
         *       5: // price object,
         *       10: // price object
         *     }
         *   },
         *   execution: {
         *     solo: {
         *       1: // price object,
         *       2: // price object,
         *       5: // price object,
         *       10: // price object
         *     }
         *     multi: {
         *       1: // price object,
         *       2: // price object,
         *       5: // price object,
         *       10: // price object
         *     }
         *   }
         * }
         * @param  {object} product
         * @param  {object} organization
         * @return {object} the price map
         */
        function getPricesMapFromProduct(product, organization) {
          const dfd = $q.defer();

          const pricesMap = {};
          api
            .call({
              url: `/orgs/${organization.gid}/prices`,
              method: 'GET',
              params: {
                query: JSON.stringify([['product.gid', '==', product.gid]]),
                fields: 'options,amount,currency,vat',
              },
            })
            .success((data) => {
              const apiPrices = data.items;

              if (!data.items || !data.items.length) {
                dfd.resolve({});
              }

              angular.forEach(product.available_options.skill.enum, (skill) => {
                const skillPrices = _.filter(apiPrices, (aPrice) => (aPrice.options.skill === skill));
                if (skillPrices.length) {
                  pricesMap[skill] = {};
                  angular.forEach(product.available_options.mode.enum, (mode) => {
                    const modePrices = _.filter(skillPrices, (aPrice) => (aPrice.options.mode === mode));
                    if (modePrices.length) {
                      pricesMap[skill][mode] = modePrices[0];
                    }
                  });
                  if (!Object.keys(pricesMap[skill]).length) {
                    delete pricesMap[skill];
                  }
                }
              });
              dfd.resolve(pricesMap);
            })
            .error(() => {
              dfd.reject('Error getting prices');
            });
          return dfd.promise;
        }
      },
    ]);
}());

(function () {
  /**
  * User module
  */
  angular
    .module('partners-bo.auth', [
    ])
    .config([
      '$stateProvider',
      function ($stateProvider) {
        $stateProvider
          .state('auth', {
            abstract: true,
            views: {
              '@': {
                template: '<ui-view/>',
              },
            },
            data: {
              public: true,
            },
          })

          .state('auth.login', {
            url: '/login?{state:string}&{params:string}',
            templateUrl: 'auth/login.html',
            controller: 'LoginController',
            controllerAs: 'vm',
            resolve: [
              {
                token: 'offlineBackgroundFile',
                deps: ['api'],
                resolveFn(api) {
                  return api.call({
                    method: 'GET',
                    url: '/p/settings/offline_background_file',
                  }).then((res) => res.data);
                },
              },
            ],
          })

          .state('auth.logout', {
            url: '/logout',
            controller: 'LogoutController',
            controllerAs: 'vm',
          });
      },
    ])

    .controller('LoginController', [
      'ROLES',
      '$rootScope',
      '$translate',
      '$state',
      'api',
      '$stateParams',
      'moment',
      'offlineBackgroundFile',
      function LoginController(ROLES, $rootScope, $translate, $state, api, $stateParams, moment, offlineBackgroundFile) {
        const vm = this;

        vm.rememberMe = true;
        vm.user = {};
        vm.CONSOLE_VERSION = __VERSION__;
        vm.currentYear = moment().year();
        vm.offlineBackgroundFile = offlineBackgroundFile.value.image;

        let state = $stateParams.state ? decodeURI($stateParams.state) : false;
        let params;
        // try to decode params
        try {
          params = $stateParams.params ? JSON.parse(decodeURI($stateParams.params)) : {};
        } catch (err) {
          params = {};
        }
        // check route existence to avoid injection
        if ((state && !$state.href(state, params)) || 'auth.login' == state) {
          state = false;
        }

        /**
         * [submit description]
         * @return {[type]} [description]
         */
        vm.submit = function submit() {
          vm.reason = null;


          if (vm.form.$valid) {
            api.call({
              method: 'POST',
              url: '/jwt-token',
              data: {
                username: vm.user.username,
                password: vm.user.password,
              },
            })
              .success((data) => {
                if (data.roles.indexOf(ROLES.ADMIN) < 0) {
                  vm.reason = $translate.instant('user_not_allowed');
                } else if (state) {
                  $state.go(state, params);
                } else {
                  $state.go('dashboard');
                }

              })
              .error((dataIgnored, status) => {
                if (401 == status) {
                  vm.reason = $translate.instant('invalid_credentials');
                } else if (403 == status) {
                  vm.reason = $translate.instant('user_not_allowed');
                } else {
                  vm.reason = $translate.instant('server_error');
                }
              });
          }
        };

        // will hide the splash screen
        $rootScope.$broadcast('ready');
      },
    ])

    .controller('LogoutController', [
      'api',
      '$state',
      function LogoutController(api, $state) {
        api.call({
          method: 'GET',
          url: '/jwt-logout',
        })
          .success(() => {
            $state.go('auth.login');
          });
      },
    ]);
}());

(function () {
  /**
   * Router config
   */
  angular
    .module('partners-bo')
    .config([
      '$urlRouterProvider',
      function ($urlRouterProvider) {
        const defaultRedirect = ($injector) => {
          $injector.get('$state').go('dashboard');
        };
        $urlRouterProvider
          .when('/', defaultRedirect)
          .when('', defaultRedirect)
          .otherwise(($injector) => {
            $injector.get('$state').go('notFound');
          });
      },
    ])
    .run([
      '$rootScope',
      '$state',
      '$stateParams',
      '$location',
      '$window',
      '$translate',
      '$interval',
      'api',
      function ($rootScope, $state, $stateParams, $location, $window, $translate, $interval, api) {
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;
        $rootScope.location = $location;

        $state.defaultErrorHandler((error) => {
          if (error.message && error.message.includes('superseded')) {
            console.debug(error);
          } else {
            console.error(error);
          }
        });

        const checkVersionIsUpToDate = function checkVersionIsUpToDate() {
          api
            .call({
              method: 'GET',
              url: '/',
              ignoreLoadingBar: true,
            })
            .then((response) => {
              if (response.data.version !== __VERSION__) {
                if (confirm($translate.instant('common.needs_refresh'))) {
                  $window.location.reload();
                }
              }
            });
        };

        $interval(checkVersionIsUpToDate, 60 * 1000);
      }]);
}());

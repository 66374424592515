(function () {
  /**
   * API config
   */
  angular
    .module('partners-bo')
    .config([
      'SERVER',
      '$httpProvider',
      '$sceDelegateProvider',
      'apiProvider',
      'apiInterceptorProvider',
      function (SERVER, $httpProvider, $sceDelegateProvider, apiProvider, apiInterceptorProvider) {
        // attach httpOnly JWT cookie to api call
        $httpProvider.defaults.withCredentials = true;

        // set API endpoint according to passed node config
        apiProvider.setEndpoint(SERVER.API_ENDPOINT);

        apiInterceptorProvider
          .setOnRequest(($injectorIgnored, config) => {
            if (config.data && 'object' === typeof config.data && !Array.isArray(config.data)) {
              Object.keys(config.data).forEach(function (element) {
                if ('object' === typeof this[element] && this[element] !== null && typeof this[element].gid !== 'undefined') {
                  this[element] = {
                    gid: this[element].gid,
                  };
                }
                if (Array.isArray(this[element])) {
                  for (let object of this[element]) {
                    if ('object' === typeof object && object !== null && typeof object.gid !== 'undefined') {
                      object = {
                        gid: object.gid,
                      };
                    }
                  }
                }
              }, config.data);
            }
            return config;
          })
          .setOnResponseError(($injector, rejection) => {
            const $rootScope = $injector.get('$rootScope');
            const $state = $injector.get('$state');
            const $window = $injector.get('$window');
            const $q = $injector.get('$q');
            const api = $injector.get('api');

            const refreshToken = function refreshToken() {
              const dfd = $q.defer();
              api.call({
                method: 'GET',
                url: '/refresh-jwt-token',
                options: {
                  // this, so the call is not retried via the refresh token interceptor
                  ignoreErrors: [401],
                },
              }).success((response, status, headers, config) => {
                dfd.resolve({
                  response, status, headers, config,
                });
              }).error((response, status, headers, config) => {
                dfd.reject({
                  response, status, headers, config,
                });
              });
              return dfd.promise;
            };

            const ttl = new Date();
            ttl.setSeconds(ttl.getSeconds() - 15);

            if (
              rejection.config.options
              && rejection.config.options.ignoreErrors
              && rejection.config.options.ignoreErrors.includes(rejection.status)
            ) {
              return $q.reject(rejection);
            }

            switch (rejection.status) {
              case 401:
                // We intercept the 401
                // try a refresh of the jwt token
                // if it success, we create a new promise to continue like nothing happened
                // if it fails, we redirect end return the promise rejection
                return refreshToken()
                  .then(() => {
                    // dont retry the call on the second try
                    if (!rejection.config.options || !rejection.config.options.dontRetry) {
                      rejection.config.options = rejection.config.options || {};
                      rejection.config.options.dontRetry = true;
                      const $http = $injector.get('$http');
                      // returning a new http call allows us to retry seamlessly
                      return $http(rejection.config);
                    }
                    return $q.reject(rejection);
                  }, (response) => {
                    if (312 === response.status) {
                      /*
                       * We redirect the user to the partners location to renew the partners
                       * token - SignedAuthentication
                       */
                      if (response.headers('Location')) {
                        $window.location.href = response.headers('Location');
                        return $q.reject(rejection);
                      }
                    }

                    const transitionName = $state.transition
                      ? $state.transition.to().name
                      : null
                    ;

                    const args = $state.transition
                      ? JSON.stringify($state.transition.params())
                      : null
                    ;

                    $rootScope.$broadcast('auth-login-required', transitionName, args);

                    return $q.reject(rejection);
                  });
              case 403:
                $rootScope.$broadcast('auth-forbidden', rejection);
                return $q.reject(rejection);
              case 404:
                if (!(rejection.config.options && rejection.config.options.ignoreNotFound)) {
                  $injector.get('$state').go('notFound');
                }
                return $q.reject(rejection);
              case 400:
              case 409:
                return $q.reject(rejection);
              default:
                // do not flood event
                // track if the event 'auth-error' has already been fired
                $rootScope.authErrorEventFired = $rootScope.authErrorEventFired || false;

                if (!$rootScope.authErrorEventFired || ttl > $rootScope.authErrorEventFired) {
                  $rootScope.authErrorEventFired = new Date();
                  $rootScope.$broadcast('auth-error', rejection);
                }

                return $q.reject(rejection);
            }
          });

          $sceDelegateProvider.resourceUrlWhitelist([
            // Allow same origin resource loads.
            'self',
            // Allow loading from outer templates domain.
            `${SERVER.API_ENDPOINT}/**`,
          ]);
      },
    ]);
}());

(function () {
  /**
   * Exception config
   */
  angular
    .module('partners-bo')
    .config([
      '$provide',
      function ($provide) {
        $provide.decorator('$exceptionHandler', [
          '$delegate',
          '$injector',
          'CriticalError',
          function ($delegate, $injector, CriticalError) {
            return function (exception) {
              if (exception instanceof CriticalError) {
                const notifications = $injector.get('notifications');
                const $translate = $injector.get('$translate');
                notifications.showError({
                  message: $translate.instant('Une erreur critique empêche le bon fonctionnement de l\'application.'),
                  hide: false,
                });
              }

              $delegate(exception);
            };
          },
        ]);
      },
    ]);
}());

(function () {
  /**
  * Product module
  */
  angular
    .module('partners-bo.products', [
    ])

    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider

          .state('products-add', {
            parent: 'root',
            url: '/products/add',
            controller: 'EditProductsController',
            controllerAs: 'vm',
            templateUrl: 'products/edit.html',
            resolve: {
              categories: [
                'api',
                function (api) {
                  return api.call({
                    method: 'GET',
                    url: '/categories',
                    params: {
                      fields: 'title',
                    },
                  }).then((res) => res.data);
                },
              ],
              product: [
                function () {
                  return {};
                },
              ],
              creadsProducts: [
                'productHelper',
                function (productHelper) {
                  return productHelper.getCreadsProducts();
                },
              ],
            },
          })

          .state('products', {
            parent: 'root',
            url: '/products/list/{index:int}?{limit:int}&{orderBy:string}&{search:string}',
            controller: 'ListProductController',
            controllerAs: 'vm',
            templateUrl: 'products/products.html',
            reloadOnSearch: true,
            resolve: {
              request: [
                '$stateParams',
                'paginator',
                function ($stateParams, paginator) {
                  paginator.setMaxItemPage($stateParams.limit);
                  const params: ParamsQuery = {
                    fields: 'title,category,available_options,weight,href,gid,illustration,icon',
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    locale: 'fr_FR',
                    show_unpriced: "1",
                  };
                  const query = [];

                  if ($stateParams.orderBy) {
                    const orderBy = $stateParams.orderBy ? $stateParams.orderBy.split(':') : null;

                    if ('title' === orderBy[0] || 'category.title' === orderBy[0]) {
                      params.sort_index_by = $stateParams.orderBy;
                      params.exclude_synonyms = true;
                    } else {
                      params.orderBy = $stateParams.orderBy;
                    }
                  }

                  if ($stateParams.search) {
                    params.search = $stateParams.search;
                  }

                  params.query = JSON.stringify(query);

                  return {
                    method: 'GET',
                    url: '/products',
                    params,
                  };
                },
              ],
              products: [
                'api',
                'request',
                function (api, request) {
                  return api.call(request).then((res) => res.data);
                },
              ],
            },
          })

          .state('scenario-products', {
            parent: 'root',
            url: '/scenario/{gid:string}/products/list/{index:int}?{limit:int}&{orderBy:string}&{search:string}&{scenario:string}',
            controller: 'ListScenarioProductsController',
            controllerAs: 'listProductsVm',
            templateUrl: 'products/scenario_products.html',
            reloadOnSearch: true,
            resolve: {
              request: [
                '$stateParams',
                'paginator',
                function ($stateParams, paginator) {
                  paginator.setMaxItemPage($stateParams.limit);
                  const params: ParamsQuery = {
                    fields: 'title,category,available_options,gid,scenario',
                    limit: paginator.getMaxItemPage(),
                    offset: ($stateParams.index - 1) * paginator.getMaxItemPage(),
                    locale: 'fr_FR',
                  };
                  const query = [];

                  // Todo with https://creads.atlassian.net/browse/CREADS-3140
                  // if ($stateParams.orderBy) {
                  //   params.orderBy = $stateParams.orderBy;
                  // }

                  if ($stateParams.search) {
                    params.search = $stateParams.search;
                  }

                  if ($stateParams.scenario) {
                    if ($stateParams.scenario === 'associate') {
                      query.push(['scenario.gid', '==', $stateParams.gid]);
                    } else if ($stateParams.scenario === 'dissociate') {
                      query.push(['OR', [['scenario', '==', null], ['scenario.gid', '!=', $stateParams.gid]]]);
                    }
                  }

                  params.query = JSON.stringify(query);

                  return {
                    method: 'GET',
                    url: '/products?show_unpriced=1',
                    params,
                  };
                },
              ],
              scenario: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/scenarios/${$stateParams.gid}`,
                    params: {
                      fields: 'gid,name,editable,default,modified_at',
                    },
                  }).then((res) => res.data);
                },
              ],
              products: [
                'api',
                'request',
                function (api, request) {
                  return api.call(request).then((res) => res.data);
                },
              ],
            },
          })

          .state('products-edit', {
            parent: 'root',
            url: '/products/{id:string}',
            controller: 'EditProductsController',
            controllerAs: 'vm',
            templateUrl: 'products/edit.html',
            resolve: {
              categories: [
                'api',
                function (api) {
                  return api.call({
                    method: 'GET',
                    url: '/categories',
                    params: {
                      fields: 'title',
                      locale: 'fr_FR',
                    },
                  }).then((res) => res.data);
                },
              ],
              product: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/products/${$stateParams.id}`,
                    params: {
                      fields: 'synonyms,available_options,creads_product_gid,icon,illustration,weight,interface,title,category,is_cumulative,description,service_details,additional_meta,custom_data,gallery_illustrations,locale,brief_template,days_to_delivery,days_to_first_delivery',
                      locale: 'fr_FR',
                    },
                  }).then((res) => res.data);
                },
              ],
              creadsProducts: [
                'productHelper',
                function (productHelper) {
                  return productHelper.getCreadsProducts();
                },
              ],
            },
          })

          .state('product-confirm', {
            parent: 'root',
            url: '/product/confirm?productId',
            controller: 'ConfirmProductController',
            controllerAs: 'vm',
            templateUrl: 'products/confirm.html',
          })

          .state('products-translate', {
            parent: 'root',
            url: '/products/translate/{id}',
            controller: 'TranslateProductsController',
            controllerAs: 'vm',
            templateUrl: 'products/translate.html',
            resolve: {
              product: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/products/${$stateParams.id}`,
                    params: {
                      fields: 'synonyms,title,description,service_details,brief_template',
                      locale: 'fr_FR',
                    },
                  }).then((res) => res.data);
                },
              ],
              localizedProduct: [
                '$stateParams',
                'api',
                function ($stateParams, api) {
                  return api.call({
                    method: 'GET',
                    url: `/products/${$stateParams.id}`,
                    params: {
                      fields: 'synonyms,title,description,service_details,brief_template,locale',
                      locale: 'en_US',
                    },
                  }).then((res) => res.data);
                },
              ],
            },
          })
        ;

        $urlRouterProvider
          .when('/products', '/products/1')
          .when('/organization/{orgaGid}/products', '/organization/{orgaGid}/products/1')
        ;
      },
    ])

    .controller('ListProductController', [
      'ROLES',
      '$translate',
      'notifications',
      'api',
      'products',
      'currentUser',
      function ListProductController(ROLES, $translate, notifications, api, products, currentUser) {
        const vm = this;

        vm.products = products.items;
        vm.initialProducts = angular.copy(vm.products);
        vm.totalCount = products.total_count;
        vm.editAllowed = currentUser.roles.indexOf(ROLES.PRICING_ADMIN) >= 0;

        vm.cloneProduct = (product) => {
          if (product.gid) {
            api
              .call({
                method: 'POST',
                url: `/products/${product.gid}/clone`,
              })
              .success(() => {
                notifications.showSuccess({
                  message: $translate.instant('products.clone.success'),
                });
              })
              .error(() => {
                notifications.showError({
                  message: $translate.instant('products.clone.error'),
                });
              })
            ;
          }
        };

        vm.saveProductWeight = function saveProductWeight(product) {
          api
            .call({
              method: 'PUT',
              url: `/products/${product.gid}`,
              data: {
                weight: product.weight,
              },
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('products.weight.success'),
              });
              product.editingWeight = false;
            });
        };

        vm.remove = function remove(product) {
          api
            .call({
              method: 'DELETE',
              url: `/products/${product.gid}`,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('products.actions.delete.success'),
              });
              vm.products.splice(vm.products.indexOf(product), 1);
            })
            .error(() => {
              notifications.showError({
                message: $translate.instant('products.actions.delete.error'),
              });
            }
          );
        };
      },
    ])

    .controller('ListScenarioProductsController', [
      'ROLES',
      '$state',
      '$stateParams',
      '$translate',
      'api',
      'products',
      'scenario',
      'notifications',
      'currentUser',
      function ListScenarioProductsController(ROLES, $state, $stateParams, $translate, api, products, scenario, notifications, currentUser) {
        const listProductsVm = this;

        listProductsVm.products = products.items;
        listProductsVm.totalCount = products.total_count;
        listProductsVm.scenario = scenario;
        listProductsVm.editAllowed = currentUser.roles.indexOf(ROLES.PRICING_ADMIN) >= 0;

        listProductsVm.scenarioFilters = [{
            value: 'all',
            label: $translate.instant('products.filters.scenario.all'),
          },
          {
            value: 'associate',
            label: $translate.instant('products.filters.scenario.associate'),
          },
          {
            value: 'dissociate',
            label: $translate.instant('products.filters.scenario.dissociate'),
          }
        ];

        if ($stateParams.scenario) {
          listProductsVm.filterSelected = $stateParams.scenario;
        } else {
          listProductsVm.filterSelected = listProductsVm.scenarioFilters[0].value;
        }

        listProductsVm.selectFilter = () => {
          $state.go('scenario-products', { scenario: listProductsVm.filterSelected });
        };

        listProductsVm.associateScenario = (product) => {
          api
            .call({
              method: 'PUT',
              url: `/products/${product.gid}`,
              data: {
                scenario: listProductsVm.scenario,
              },
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('products.notifications.scenario.associate'),
              });
              $state.go('scenario-products', { scenario: listProductsVm.filterSelected }, { reload: true});
            })
            .error((data) => {
              throw new Error(data);
            })
          ;
        };

        listProductsVm.dissociateScenario = (product) => {
          api
            .call({
              method: 'PUT',
              url: `/products/${product.gid}`,
              data: {
                scenario: null,
              },
            })
            .success(() => {
              $state.go('scenario-products', { scenario: listProductsVm.filterSelected }, { reload: true});
            })
            .error((data) => {
              throw new Error(data);
            })
          ;
        };
      },
    ])

    .controller('EditProductsController', [
      'SERVER',
      'ROLES',
      'PRODUCT',
      '$http',
      '$translate',
      '$filter',
      '$state',
      'api',
      'productHelper',
      'notifications',
      'product',
      'categories',
      'creadsProducts',
      'currentUser',
      function EditProductsController(SERVER, ROLES, PRODUCT, $http, $translate, $filter, $state, api, productHelper, notifications, product, categories, creadsProducts, currentUser) {
        const vm = this;

        vm.categories = categories.items;
        vm.edited = false;
        vm.creadsProducts = creadsProducts;
        vm.search = '';
        vm.canBeDeleted = false;
        vm.currentCreadsProduct = null;
        vm.synonyms = [];
        vm.descriptionMaxLength = 2000;
        vm.categoriesPagination = {
          offset: 10,
          limit: 10,
          max: categories.total_count,
        };
        vm.editAllowed = currentUser.roles.indexOf(ROLES.PRICING_ADMIN) >= 0;

        vm.changeCreadsProduct = function changeCreadsProduct(productGid) {
          vm.currentCreadsProduct = _.findWhere(vm.creadsProducts, { gid: productGid });
        };

        vm.availableIcons = [
          { name: 'affiche-expert-v2', value: 'creads-icon-affiche-expert-v2' },
          { name: 'affiche-start-v2', value: 'creads-icon-affiche-start-v2' },
          { name: 'android', value: 'creads-icon-android' },
          { name: 'animated-banner', value: 'creads-icon-animated-banner' },
          { name: 'animation-de-logo1-v2', value: 'creads-icon-animation-de-logo1-v2' },
          { name: 'appleinc', value: 'creads-icon-appleinc' },
          { name: 'application-mobile-v2', value: 'creads-icon-application-mobile-v2' },
          { name: 'application-mobile2-v2', value: 'creads-icon-application-mobile2-v2' },
          { name: 'arrangeur', value: 'creads-icon-arrangeur' },
          { name: 'article', value: 'creads-icon-article' },
          { name: 'atom', value: 'creads-icon-atom' },
          { name: 'avatar', value: 'creads-icon-avatar' },
          { name: 'Banniere-Animee-Declinaison-v2', value: 'creads-icon-Banniere-Animee-Declinaison-v2' },
          { name: 'Banniere-Animee-Kit1-v2', value: 'creads-icon-Banniere-Animee-Kit1-v2' },
          { name: 'Banniere-Animee-Kit2-v2', value: 'creads-icon-Banniere-Animee-Kit2-v2' },
          { name: 'Banniere-Animee-Master-v2', value: 'creads-icon-Banniere-Animee-Master-v2' },
          { name: 'Banniere-Gif-Declinaison-v2', value: 'creads-icon-Banniere-Gif-Declinaison-v2' },
          { name: 'Banniere-Gif-Kit1-v2', value: 'creads-icon-Banniere-Gif-Kit1-v2' },
          { name: 'Banniere-Gif-Kit2-v2', value: 'creads-icon-Banniere-Gif-Kit2-v2' },
          { name: 'Banniere-Gif-Master-v2', value: 'creads-icon-Banniere-Gif-Master-v2' },
          { name: 'Banniere-Statique-declinaison-v2', value: 'creads-icon-Banniere-Statique-declinaison-v2' },
          { name: 'Banniere-Statique-declinaison2-v2', value: 'creads-icon-Banniere-Statique-declinaison2-v2' },
          { name: 'Banniere-Statique-declinaison3-v2', value: 'creads-icon-Banniere-Statique-declinaison3-v2' },
          { name: 'Banniere-Statique-kit1-v2', value: 'creads-icon-Banniere-Statique-kit1-v2' },
          { name: 'Banniere-Statique-kit2-v2', value: 'creads-icon-Banniere-Statique-kit2-v2' },
          { name: 'Banniere-Statique-kit3-v2', value: 'creads-icon-Banniere-Statique-kit3-v2' },
          { name: 'Banniere-Statique-kit4-v2', value: 'creads-icon-Banniere-Statique-kit4-v2' },
          { name: 'Banniere-Statique-Master-v2', value: 'creads-icon-Banniere-Statique-Master-v2' },
          { name: 'bell', value: 'creads-icon-bell' },
          { name: 'benchmark-marketing-v2', value: 'creads-icon-benchmark-marketing-v2' },
          { name: 'bill', value: 'creads-icon-bill' },
          { name: 'book', value: 'creads-icon-book' },
          { name: 'brief-v2', value: 'creads-icon-brief-v2' },
          { name: 'brochure-catalogue-v2', value: 'creads-icon-brochure-catalogue-v2' },
          { name: 'bulb', value: 'creads-icon-bulb' },
          { name: 'bullhorn', value: 'creads-icon-bullhorn' },
          { name: 'cadena', value: 'creads-icon-cadena' },
          { name: 'calendar', value: 'creads-icon-calendar' },
          { name: 'candidate', value: 'creads-icon-candidate' },
          { name: 'candidate2', value: 'creads-icon-candidate2' },
          { name: 'captation-video1-v2', value: 'creads-icon-captation-video1-v2' },
          { name: 'captation-video2-v2', value: 'creads-icon-captation-video2-v2' },
          { name: 'captation-video3-v2', value: 'creads-icon-captation-video3-v2' },
          { name: 'card-pen', value: 'creads-icon-card-pen' },
          { name: 'card', value: 'creads-icon-card' },
          { name: 'carte-de-correspondance-v2', value: 'creads-icon-carte-de-correspondance-v2' },
          { name: 'carte-de-visite-v2', value: 'creads-icon-carte-de-visite-v2' },
          { name: 'carte-de-voeux-digitale-v2', value: 'creads-icon-carte-de-voeux-digitale-v2' },
          { name: 'carte-de-voeux-expert1-v2', value: 'creads-icon-carte-de-voeux-expert1-v2' },
          { name: 'carte-de-voeux-start1-v2', value: 'creads-icon-carte-de-voeux-start1-v2' },
          { name: 'carte-de-voeux-start2-v2', value: 'creads-icon-carte-de-voeux-start2-v2' },
          { name: 'charte-video-habillage1-v2', value: 'creads-icon-charte-video-habillage1-v2' },
          { name: 'charte-video-habillage2-v2', value: 'creads-icon-charte-video-habillage2-v2' },
          { name: 'chat', value: 'creads-icon-chat' },
          { name: 'check-list', value: 'creads-icon-check-list' },
          { name: 'client', value: 'creads-icon-client' },
          { name: 'clipboard', value: 'creads-icon-clipboard' },
          { name: 'clock', value: 'creads-icon-clock' },
          { name: 'coin-euro', value: 'creads-icon-coin-euro' },
          { name: 'color-chart', value: 'creads-icon-color-chart' },
          { name: 'communique-de-presse-v2', value: 'creads-icon-communique-de-presse-v2' },
          { name: 'community', value: 'creads-icon-community' },
          { name: 'computer-screen', value: 'creads-icon-computer-screen' },
          { name: 'concept1-v2', value: 'creads-icon-concept1-v2' },
          { name: 'concept2-v2', value: 'creads-icon-concept2-v2' },
          { name: 'concept3-v2', value: 'creads-icon-concept3-v2' },
          { name: 'concept4-v2', value: 'creads-icon-concept4-v2' },
          { name: 'concept5-v2', value: 'creads-icon-concept5-v2' },
          { name: 'creads', value: 'creads-icon-creads' },
          { name: 'cube', value: 'creads-icon-cube' },
          { name: 'cup', value: 'creads-icon-cup' },
          { name: 'relief-3D-v2', value: 'creads-icon-relief-3D-v2' },
          { name: 'declinaison-v2', value: 'creads-icon-declinaison-v2' },
          { name: 'divers1-v2', value: 'creads-icon-divers1-v2' },
          { name: 'divers2-v2', value: 'creads-icon-divers2-v2' },
          { name: 'dotation', value: 'creads-icon-dotation' },
          { name: 'double-picture', value: 'creads-icon-double-picture' },
          { name: 'duplicate', value: 'creads-icon-duplicate' },
          { name: 'ecommerce-reserve-v2', value: 'creads-icon-ecommerce-reserve-v2' },
          { name: 'enveloppe', value: 'creads-icon-enveloppe' },
          { name: 'evaluation', value: 'creads-icon-evaluation' },
          { name: 'facebook', value: 'creads-icon-facebook' },
          { name: 'facebook2', value: 'creads-icon-facebook2' },
          { name: 'facebook3', value: 'creads-icon-facebook3' },
          { name: 'feather-pen', value: 'creads-icon-feather-pen' },
          { name: 'fiche-produit-v2', value: 'creads-icon-fiche-produit-v2' },
          { name: 'file-pdf', value: 'creads-icon-file-pdf' },
          { name: 'film', value: 'creads-icon-film' },
          { name: 'flag', value: 'creads-icon-flag' },
          { name: 'flyer-adaptation-v2', value: 'creads-icon-flyer-adaptation-v2' },
          { name: 'flyer-expert-v2', value: 'creads-icon-flyer-expert-v2' },
          { name: 'folder', value: 'creads-icon-folder' },
          { name: 'freelance1-v2', value: 'creads-icon-freelance1-v2' },
          { name: 'freelance2-v2', value: 'creads-icon-freelance2-v2' },
          { name: 'freelance3-v2', value: 'creads-icon-freelance3-v2' },
          { name: 'freelance4-v2', value: 'creads-icon-freelance4-v2' },
          { name: 'freelance5-v2', value: 'creads-icon-freelance5-v2' },
          { name: 'freelance6-v2', value: 'creads-icon-freelance6-v2' },
          { name: 'freelance7-v2', value: 'creads-icon-freelance7-v2' },
          { name: 'gallery-app', value: 'creads-icon-gallery-app' },
          { name: 'gif-anime1-v2', value: 'creads-icon-gif-anime1-v2' },
          { name: 'gif-anime2-v2', value: 'creads-icon-gif-anime2-v2' },
          { name: 'gif-anime3-v2', value: 'creads-icon-gif-anime3-v2' },
          { name: 'google-drive', value: 'creads-icon-google-drive' },
          { name: 'google', value: 'creads-icon-google' },
          { name: 'hat-moustache', value: 'creads-icon-hat-moustache' },
          { name: 'home', value: 'creads-icon-home' },
          { name: 'html-v2', value: 'creads-icon-html-v2' },
          { name: 'illustration1-v2', value: 'creads-icon-illustration1-v2' },
          { name: 'illustration2-v2', value: 'creads-icon-illustration2-v2' },
          { name: 'illustration3-v2', value: 'creads-icon-illustration3-v2' },
          { name: 'incognito', value: 'creads-icon-incognito' },
          { name: 'infographics', value: 'creads-icon-infographics' },
          { name: 'infographie-declinaison-v2', value: 'creads-icon-infographie-declinaison-v2' },
          { name: 'infographie-declinaison2-v2', value: 'creads-icon-infographie-declinaison2-v2' },
          { name: 'infographie-expert-v2', value: 'creads-icon-infographie-expert-v2' },
          { name: 'infographie-expert2-v2', value: 'creads-icon-infographie-expert2-v2' },
          { name: 'infographie-expert3-v2', value: 'creads-icon-infographie-expert3-v2' },
          { name: 'infographie-start-v2', value: 'creads-icon-infographie-start-v2' },
          { name: 'infographie-start2-v2', value: 'creads-icon-infographie-start2-v2' },
          { name: 'instagram-v2', value: 'creads-icon-instagram-v2' },
          { name: 'instagram', value: 'creads-icon-instagram' },
          { name: 'kakemono', value: 'creads-icon-kakemono' },
          { name: 'key-visual-de-marque1-v2', value: 'creads-icon-key-visual-de-marque1-v2' },
          { name: 'key-visual-de-marque2-v2', value: 'creads-icon-key-visual-de-marque2-v2' },
          { name: 'key-visual-de-marque3-v2', value: 'creads-icon-key-visual-de-marque3-v2' },
          { name: 'kit-discount-v2', value: 'creads-icon-kit-discount-v2' },
          { name: 'labelling', value: 'creads-icon-labelling' },
          { name: 'landscape', value: 'creads-icon-landscape' },
          { name: 'leaflet', value: 'creads-icon-leaflet' },
          { name: 'like', value: 'creads-icon-like' },
          { name: 'logo-branding1-v2', value: 'creads-icon-logo-branding1-v2' },
          { name: 'logo-branding2-v2', value: 'creads-icon-logo-branding2-v2' },
          { name: 'logo-start-et-carte-de-visite1-v2', value: 'creads-icon-logo-start-et-carte-de-visite1-v2' },
          { name: 'logo-start-et-carte-de-visite2-v2', value: 'creads-icon-logo-start-et-carte-de-visite2-v2' },
          { name: 'logo-start-et-papeterie1-v2', value: 'creads-icon-logo-start-et-papeterie1-v2' },
          { name: 'logo-start-et-reseaux-sociaux-v2', value: 'creads-icon-logo-start-et-reseaux-sociaux-v2' },
          { name: 'logo-start-et-reseaux-sociaux1-v2', value: 'creads-icon-logo-start-et-reseaux-sociaux1-v2' },
          { name: 'logo-start1-v2', value: 'creads-icon-logo-start1-v2' },
          { name: 'logo-start2-v2', value: 'creads-icon-logo-start2-v2' },
          { name: 'mail-app', value: 'creads-icon-mail-app' },
          { name: 'mailchimp-template-1-bloc-v2', value: 'creads-icon-mailchimp-template-1-bloc-v2' },
          { name: 'mailchimp-template-plusieurs-blocs-v2', value: 'creads-icon-mailchimp-template-plusieurs-blocs-v2' },
          { name: 'maintenance-v2', value: 'creads-icon-maintenance-v2' },
          { name: 'man', value: 'creads-icon-man' },
          { name: 'mascotte-3D1-v2', value: 'creads-icon-mascotte-3D1-v2' },
          { name: 'mascotte-degradee1-v2', value: 'creads-icon-mascotte-degradee1-v2' },
          { name: 'mascotte-et-logo-v2', value: 'creads-icon-mascotte-et-logo-v2' },
          { name: 'mascotte-reserve1-v2', value: 'creads-icon-mascotte-reserve1-v2' },
          { name: 'mascotte-reserve2-v2', value: 'creads-icon-mascotte-reserve2-v2' },
          { name: 'mascotte-reserve3-v2', value: 'creads-icon-mascotte-reserve3-v2' },
          { name: 'mascotte-reserve4', value: 'creads-icon-mascotte-reserve4' },
          { name: 'mascotte-start1-v2', value: 'creads-icon-mascotte-start1-v2' },
          { name: 'mascotte-start2-v2', value: 'creads-icon-mascotte-start2-v2' },
          { name: 'medal', value: 'creads-icon-medal' },
          { name: 'montage-video-v2', value: 'creads-icon-montage-video-v2' },
          { name: 'montage-video1-v2', value: 'creads-icon-montage-video1-v2' },
          { name: 'moodboard-v2', value: 'creads-icon-moodboard-v2' },
          { name: 'motion-design-3D-v2', value: 'creads-icon-motion-design-3D-v2' },
          { name: 'motion-design1-v2', value: 'creads-icon-motion-design1-v2' },
          { name: 'naming-branding-1-concepteur-redacteur-v2', value: 'creads-icon-naming-branding-1-concepteur-redacteur-v2' },
          { name: 'naming-branding-3-concepteurs-redacteurs-v2', value: 'creads-icon-naming-branding-3-concepteurs-redacteurs-v2' },
          { name: 'naming-et-logo-v2', value: 'creads-icon-naming-et-logo-v2' },
          { name: 'naming-logo-et-papeterie-v2', value: 'creads-icon-naming-logo-et-papeterie-v2' },
          { name: 'newsletter-1-bloc-v2', value: 'creads-icon-newsletter-1-bloc-v2' },
          { name: 'newsletter-1-bloc2-v2', value: 'creads-icon-newsletter-1-bloc2-v2' },
          { name: 'newsletter-declinaison-plusieurs-blocs-v2', value: 'creads-icon-newsletter-declinaison-plusieurs-blocs-v2' },
          { name: 'newsletter-design-et-integration-1-bloc-v2', value: 'creads-icon-newsletter-design-et-integration-1-bloc-v2' },
          { name: 'newsletter-design-et-integration-1-bloc2-v2', value: 'creads-icon-newsletter-design-et-integration-1-bloc2-v2' },
          { name: 'newsletter-design-et-integration-plusieurs-blocs-v2', value: 'creads-icon-newsletter-design-et-integration-plusieurs-blocs-v2' },
          { name: 'newsletter-plusieurs-blocs-v2', value: 'creads-icon-newsletter-plusieurs-blocs-v2' },
          { name: 'newspaper', value: 'creads-icon-newspaper' },
          { name: 'onedrive', value: 'creads-icon-onedrive' },
          { name: 'packaging-1-support-et-key-visual-v2', value: 'creads-icon-packaging-1-support-et-key-visual-v2' },
          { name: 'packaging-1-support-et-key-visual2-v2', value: 'creads-icon-packaging-1-support-et-key-visual2-v2' },
          { name: 'packaging-1-support1-v2', value: 'creads-icon-packaging-1-support1-v2' },
          { name: 'packaging-1-support2-v2', value: 'creads-icon-packaging-1-support2-v2' },
          { name: 'packaging-1-support3-v2', value: 'creads-icon-packaging-1-support3-v2' },
          { name: 'packaging-1-support4-v2', value: 'creads-icon-packaging-1-support4-v2' },
          { name: 'packaging-2-supports-et-key-visual-v2', value: 'creads-icon-packaging-2-supports-et-key-visual-v2' },
          { name: 'packaging-2-supports1-v2', value: 'creads-icon-packaging-2-supports1-v2' },
          { name: 'packaging', value: 'creads-icon-packaging' },
          { name: 'palette', value: 'creads-icon-palette' },
          { name: 'paper-header', value: 'creads-icon-paper-header' },
          { name: 'papier-entete-v2', value: 'creads-icon-papier-entete-v2' },
          { name: 'pencil-ruler', value: 'creads-icon-pencil-ruler' },
          { name: 'pencil', value: 'creads-icon-pencil' },
          { name: 'phone', value: 'creads-icon-phone' },
          { name: 'photo1-v2', value: 'creads-icon-photo1-v2' },
          { name: 'photo2-v2', value: 'creads-icon-photo2-v2' },
          { name: 'photo3-v2', value: 'creads-icon-photo3-v2' },
          { name: 'picto-pack-de-10-v2', value: 'creads-icon-picto-pack-de-10-v2' },
          { name: 'picto-pack-de-20-v2', value: 'creads-icon-picto-pack-de-20-v2' },
          { name: 'pinterest2', value: 'creads-icon-pinterest2' },
          { name: 'plaquette-4-pages-expert1-v2', value: 'creads-icon-plaquette-4-pages-expert1-v2' },
          { name: 'plaquette-4-pages-expert2-v2', value: 'creads-icon-plaquette-4-pages-expert2-v2' },
          { name: 'plaquette-4-pages-start1-v2', value: 'creads-icon-plaquette-4-pages-start1-v2' },
          { name: 'play', value: 'creads-icon-play' },
          { name: 'poster2', value: 'creads-icon-poster2' },
          { name: 'power', value: 'creads-icon-power' },
          { name: 'powerpoint-manuel-d_utilisation-v2', value: 'creads-icon-powerpoint-manuel-d_utilisation-v2' },
          { name: 'powerpoint-slide-animee1-v2', value: 'creads-icon-powerpoint-slide-animee1-v2' },
          { name: 'powerpoint-slide-schema-infographie1-v2', value: 'creads-icon-powerpoint-slide-schema-infographie1-v2' },
          { name: 'powerpoint-slide-schema-infographie2-v2', value: 'creads-icon-powerpoint-slide-schema-infographie2-v2' },
          { name: 'powerpoint-slide-statique1-v2', value: 'creads-icon-powerpoint-slide-statique1-v2' },
          { name: 'powerpoint-slide-statique2-v2', value: 'creads-icon-powerpoint-slide-statique2-v2' },
          { name: 'powerpoint-template-expert-10-slides1-v2', value: 'creads-icon-powerpoint-template-expert-10-slides1-v2' },
          { name: 'powerpoint-template-expert-5-slides1-v2', value: 'creads-icon-powerpoint-template-expert-5-slides1-v2' },
          { name: 'powerpoint-template-start-v2', value: 'creads-icon-powerpoint-template-start-v2' },
          { name: 'printer', value: 'creads-icon-printer' },
          { name: 'project-finish2', value: 'creads-icon-project-finish2' },
          { name: 'project', value: 'creads-icon-project' },
          { name: 'question', value: 'creads-icon-question' },
          { name: 'redaction-de-contenu1-v2', value: 'creads-icon-redaction-de-contenu1-v2' },
          { name: 'redaction-de-contenu2-v2', value: 'creads-icon-redaction-de-contenu2-v2' },
          { name: 'redaction-de-contenu3-v2', value: 'creads-icon-redaction-de-contenu3-v2' },
          { name: 'reseaux-sociaux-canvas-v2', value: 'creads-icon-reseaux-sociaux-canvas-v2' },
          { name: 'reseaux-sociaux-carrousel-mise-au-format-v2', value: 'creads-icon-reseaux-sociaux-carrousel-mise-au-format-v2' },
          { name: 'reseaux-sociaux-carrousel-v2', value: 'creads-icon-reseaux-sociaux-carrousel-v2' },
          { name: 'reseaux-sociaux-carrousel2-v2', value: 'creads-icon-reseaux-sociaux-carrousel2-v2' },
          { name: 'Reseaux-sociaux-mise-au-format-v2', value: 'creads-icon-Reseaux-sociaux-mise-au-format-v2' },
          { name: 'reseaux-sociaux-redimensionnement-v2', value: 'creads-icon-reseaux-sociaux-redimensionnement-v2' },
          { name: 'reseaux-sociaux-redimensionnement2-v2', value: 'creads-icon-reseaux-sociaux-redimensionnement2-v2' },
          { name: 'reseaux-sociaux-story-30', value: 'creads-icon-reseaux-sociaux-story-30' },
          { name: 'reseaux-sociaux-story-v2', value: 'creads-icon-reseaux-sociaux-story-v2' },
          { name: 'reseaux-sociaux-story2-v2', value: 'creads-icon-reseaux-sociaux-story2-v2' },
          { name: 'Reseaux-sociaux-v2', value: 'creads-icon-Reseaux-sociaux-v2' },
          { name: 'retouch', value: 'creads-icon-retouch' },
          { name: 'ribbon-star', value: 'creads-icon-ribbon-star' },
          { name: 'rocket', value: 'creads-icon-rocket' },
          { name: 'rose-window', value: 'creads-icon-rose-window' },
          { name: 'ruler-pencil', value: 'creads-icon-ruler-pencil' },
          { name: 'schema', value: 'creads-icon-schema' },
          { name: 'scissors', value: 'creads-icon-scissors' },
          { name: 'screen', value: 'creads-icon-screen' },
          { name: 'shield', value: 'creads-icon-shield' },
          { name: 'simple-picture', value: 'creads-icon-simple-picture' },
          { name: 'slideshow', value: 'creads-icon-slideshow' },
          { name: 'slogan-branding-1-concepteur-redacteur-v2', value: 'creads-icon-slogan-branding-1-concepteur-redacteur-v2' },
          { name: 'slogan-branding-3-concepteurs-redacteurs', value: 'creads-icon-slogan-branding-3-concepteurs-redacteurs' },
          { name: 'snapchat1-v2', value: 'creads-icon-snapchat1-v2' },
          { name: 'snapchat2-v2', value: 'creads-icon-snapchat2-v2' },
          { name: 'social-network', value: 'creads-icon-social-network' },
          { name: 'stand-kit-v2', value: 'creads-icon-stand-kit-v2' },
          { name: 'stand-reserve1-v2', value: 'creads-icon-stand-reserve1-v2' },
          { name: 'stand-reserve2-v2', value: 'creads-icon-stand-reserve2-v2' },
          { name: 'stand-reserve3-v2', value: 'creads-icon-stand-reserve3-v2' },
          { name: 'stand-reserve4-v2', value: 'creads-icon-stand-reserve4-v2' },
          { name: 'storyboard-v2', value: 'creads-icon-storyboard-v2' },
          { name: 'style-de-marque-et-key-visual1-v2', value: 'creads-icon-style-de-marque-et-key-visual1-v2' },
          { name: 'style-de-marque-et-logo1-v2', value: 'creads-icon-style-de-marque-et-logo1-v2' },
          { name: 'target', value: 'creads-icon-target' },
          { name: 'thumbs-up', value: 'creads-icon-thumbs-up' },
          { name: 'ton-de-marque-charte-editoriale1-v2', value: 'creads-icon-ton-de-marque-charte-editoriale1-v2' },
          { name: 'ton-de-marque-charte-editoriale2-v2', value: 'creads-icon-ton-de-marque-charte-editoriale2-v2' },
          { name: 'tooltip', value: 'creads-icon-tooltip' },
          { name: 'traduction-de-document1-v2', value: 'creads-icon-traduction-de-document1-v2' },
          { name: 'traduction-de-document2-v2', value: 'creads-icon-traduction-de-document2-v2' },
          { name: 'transform', value: 'creads-icon-transform' },
          { name: 'truck', value: 'creads-icon-truck' },
          { name: 'twitch', value: 'creads-icon-twitch' },
          { name: 'twitter', value: 'creads-icon-twitter' },
          { name: 'twitter2', value: 'creads-icon-twitter2' },
          { name: 'ui-declinaison-format-v2', value: 'creads-icon-ui-declinaison-format-v2' },
          { name: 'ui-declinaison-v2', value: 'creads-icon-ui-declinaison-v2' },
          { name: 'ui-master-v2', value: 'creads-icon-ui-master-v2' },
          { name: 'ui-page-type-v2', value: 'creads-icon-ui-page-type-v2' },
          { name: 'user-bubble', value: 'creads-icon-user-bubble' },
          { name: 'user-talking', value: 'creads-icon-user-talking' },
          { name: 'UX-design-analyse-v2', value: 'creads-icon-UX-design-analyse-v2' },
          { name: 'UX-design-analyse2-v2', value: 'creads-icon-UX-design-analyse2-v2' },
          { name: 'UX-design-reserve1-v2', value: 'creads-icon-UX-design-reserve1-v2' },
          { name: 'UX-design-zoning-declinaison1-v2', value: 'creads-icon-UX-design-zoning-declinaison1-v2' },
          { name: 'UX-design-zoning-master1-v2', value: 'creads-icon-UX-design-zoning-master1-v2' },
          { name: 'UX-designer-Junior-v2', value: 'creads-icon-UX-designer-Junior-v2' },
          { name: 'UX-designer-Senior-v2', value: 'creads-icon-UX-designer-Senior-v2' },
          { name: 'vectorisation1-v2', value: 'creads-icon-vectorisation1-v2' },
          { name: 'vectorisation2-v2', value: 'creads-icon-vectorisation2-v2' },
          { name: 'video-interview1-v2', value: 'creads-icon-video-interview1-v2' },
          { name: 'video-interview2-v2', value: 'creads-icon-video-interview2-v2' },
          { name: 'video-interview3-v2', value: 'creads-icon-video-interview3-v2' },
          { name: 'video-presentation', value: 'creads-icon-video-presentation' },
          { name: 'video-reserve-2-v2', value: 'creads-icon-video-reserve-2-v2' },
          { name: 'video-reserve-v2', value: 'creads-icon-video-reserve-v2' },
          { name: 'video-reserve1-v2', value: 'creads-icon-video-reserve1-v2' },
          { name: 'video-reserve3-v2', value: 'creads-icon-video-reserve3-v2' },
          { name: 'video-reserve4-v2', value: 'creads-icon-video-reserve4-v2' },
          { name: 'video-reserve5-v2', value: 'creads-icon-video-reserve5-v2' },
          { name: 'vimeo', value: 'creads-icon-vimeo' },
          { name: 'visuel-de-campagne-v2', value: 'creads-icon-visuel-de-campagne-v2' },
          { name: 'visuel-de-campagne2-v2', value: 'creads-icon-visuel-de-campagne2-v2' },
          { name: 'voeux-conception-redaction-v2', value: 'creads-icon-voeux-conception-redaction-v2' },
          { name: 'voeux-gif1', value: 'creads-icon-voeux-gif1' },
          { name: 'voeux-gif2', value: 'creads-icon-voeux-gif2' },
          { name: 'windows8', value: 'creads-icon-windows8' },
          { name: 'wish-card', value: 'creads-icon-wish-card' },
          { name: 'wordpress-site-5-pages-v2', value: 'creads-icon-wordpress-site-5-pages-v2' },
          { name: 'wordpress-site-one-page-v2', value: 'creads-icon-wordpress-site-one-page-v2' },
          { name: 'youtube', value: 'creads-icon-youtube' },
        ];

        vm.uploaderValidate = {
          width: { max: 2000, min: 360 },
          height: { max: 2000, min: 300 },
          pattern: '.jpg,.gif,.jpeg,.png,.svg',
          accept: 'image/jpg,image/jpeg,image/png,image/gif,image/svg',
          size: { max: '8MB' },
        };

        vm.uploaderSliderValidate = {
          pattern: '.jpg,.gif,.jpeg,.png,.svg,.avi,.mp4,.mov,.webm',
          accept: 'image/jpg,image/jpeg,image/png,image/gif,image/svg,video/avi,video/mp4,video/mov,video/webm',
          size: { max: '4GB' },
        };
        vm.uploadError = {};
        vm.uploadSliderError = {};

        if (product.gid && product.gid.length) {
          vm.product = product;
          vm.synonyms = vm.product.synonyms;
          // test if product can be deleted
          api
            .call({
              method: 'GET',
              url: '/projects/total_count',
              params: {
                query: JSON.stringify([['product.gid', '==', vm.product.gid]]),
              },
            })
            .success((data) => {
              if (data.total_count <= 0) {
                vm.canBeDeleted = true;
              }
            });
          vm.mode = {
            solo: (product.available_options.mode.enum.indexOf('solo') >= 0),
            multi: (product.available_options.mode.enum.indexOf('multi') >= 0),
          };
          vm.skill = {
            conception: (product.available_options.skill.enum.indexOf('conception') >= 0),
            execution: (product.available_options.skill.enum.indexOf('execution') >= 0),
          };
          vm.changeCreadsProduct(vm.product.creads_product_gid);
          vm.product.icon = vm.product.icon || 'creads-icon-shield';
          if (0 === vm.product.custom_data.length) {
            vm.product.custom_data = {
              decreasing_prices: [],
            };
          }
          vm.productIcon = _.findWhere(vm.availableIcons, { value: vm.product.icon });
          vm.productIcon = vm.productIcon.name;

          vm.productIllustration = [];
          if (vm.product.illustration) {
            vm.productIllustration.push(vm.product.illustration);
          }
          vm.galleryIllustrations = [];
          if (vm.product.gallery_illustrations.length) {
            vm.galleryIllustrations = vm.product.gallery_illustrations;
          }
        } else {
          vm.product = {
            available_options: {
              mode: {
                type: 'string',
                default: 'solo',
                required: true,
                enum: ['solo'],
              },
              skill: {
                type: 'string',
                default: 'execution',
                required: true,
                enum: ['execution'],
              },
            },
            additional_meta: {},
            custom_data: {
              decreasing_prices: [],
            },
            is_cumulative: false,
            is_enabled: true,
            interface: PRODUCT.INTERFACE.DEFAULT,
            creads_product_gid: creadsProducts[0].gid,
            icon: 'creads-icon-shield',
            illustration: null,
            gallery_illustrations: [],
            locale: 'fr_FR',
            brief_template: null,
            days_to_delivery: 16,
            days_to_first_delivery: 8,
          };
          vm.mode = {
            solo: true,
            multi: false,
          };
          vm.skill = {
            conception: false,
            execution: true,
          };
          vm.productIllustration = [];
          vm.galleryIllustrations = [];
          api
            .call({
              url: '/products?show_unpriced=1',
              method: 'GET',
              params: {
                orderBy: 'weight:desc',
                limit: 1,
                fields: 'weight',
              },
            })
            .success((data) => {
              if (data.items[0]) {
                vm.product.weight = data.items[0].weight + PRODUCT.WEIGHT_INTERVAL;
              } else {
                vm.product.weight = 0;
              }
            });
        }

        vm.available_options = {
          mode: {
            type: 'string',
            default: 'solo',
            required: true,
            enum: ['solo', 'multi'],
          },
          skill: {
            type: 'string',
            default: 'execution',
            required: true,
            enum: ['execution', 'conception'],
          },
        };

        vm.onAddIllustration = function onAddIllustration() {
          return function (file) {
            vm.product.illustration = file;
          };
        };

        vm.onRemoveIllustration = function onRemoveIllustration() {
          return function (fileIgnored, callback) {
            vm.product.illustration = null;
            callback();
          };
        };

        /**
         * On mode change update
         * the product object
         * @return {void}
         */
        vm.onChangeMode = function onChangeMode() {
          vm.product = productHelper.changeMode(vm.mode, vm.product);
          vm.product = productHelper.changeDefaultMode(vm.product);
        };

        vm.updatePristine = function updatePristine($field) {
          $field.$pristine = false;
        }

        /**
         * [submit]
         * @return {void}
         */
        vm.submit = function submit() {
          if (vm.form.$valid && vm.form.$submitted) {
            vm.product.creads_product_gid = vm.currentCreadsProduct.gid;

            vm.product.interface = productHelper.getPartnersTypeFromCreadsMediaType(vm.currentCreadsProduct.media_type);

            vm.product.available_options.mode.enum = [];
            if (vm.mode.solo) {
              vm.product.available_options.mode.enum.push('solo');
            }
            if (vm.mode.multi) {
              vm.product.available_options.mode.enum.push('multi');
            }
            vm.product.synonyms = [];
            angular.forEach(vm.synonyms, (element) => {
              vm.product.synonyms.push(element.text);
            });
            vm.product.icon = `creads-icon-${vm.productIcon}`;

            vm.product.gallery_illustrations = [];

            vm.galleryIllustrations.forEach(element => {
              const fileUrl = { url: element.url }
              vm.product.gallery_illustrations.push(fileUrl);
            });

            let productToSend: Product = _.pick(vm.product,
              'additional_meta',
              'available_options',
              'category',
              'creads_product_gid',
              'custom_data',
              'icon',
              'interface',
              'is_cumulative',
              'locale',
              'title',
              'weight',
              'illustration',
              'gallery_illustrations',
            );

            // If form fields are updated to limit data send to api
            // @see https://creads.atlassian.net/jira/servicedesk/projects/SUP/queues/custom/1/SUP-906
            if (!vm.form.$pristine) {
              if (!vm.form.brief_template.$pristine) {
                productToSend.brief_template = vm.product.brief_template;
              }
              if (!vm.form.service_details.$pristine) {
                productToSend.service_details = vm.product.service_details;
              }
              if (!vm.form.description.$pristine) {
                productToSend.description = vm.product.description;
              }
              if (!vm.form.synonyms.$pristine) {
                productToSend.synonyms = vm.product.synonyms;
              }
              if (!vm.form.days_to_delivery.$pristine) {
                productToSend.days_to_delivery = vm.product.days_to_delivery;
              }
              if (!vm.form.days_to_first_delivery.$pristine) {
                productToSend.days_to_first_delivery = vm.product.days_to_first_delivery;
              }
            }

            $http({
              method: vm.product.gid ? 'PUT' : 'POST',
              url: `${SERVER.API_ENDPOINT}/products${vm.product.gid ? (`/${vm.product.gid}`) : ''}`,
              data: productToSend,
            }).success(() => {
                if (!vm.product.gid) {
                  notifications.showSuccess({
                    message: $translate.instant('Le produit a bien été créé.'),
                  });
                  $state.go('products', { index: 1 });
                } else {
                  notifications.showSuccess({
                    message: $translate.instant('Le produit a bien été modifié.'),
                  });
                  $state.go('products', { index: 1 });
                }
              }).error(() => {})
            ;
          }
        };

        vm.searchUpdate = function searchUpdate() {
          const results = $filter('filter')(vm.creadsProducts, vm.search);
          if (results.length > 0) {
            vm.product.creads_product_gid = results[0].gid;
            vm.changeCreadsProduct(vm.product.creads_product_gid);
          }
        };

        vm.toggleBusiness = function toggleBusiness(skill) {
          if (!vm.skill.conception && !vm.skill.execution) {
            if ('conception' === skill) { vm.skill.execution = true; }
            if ('execution' === skill) { vm.skill.conception = true; }
          }
          const skills = [];
          if (vm.skill.conception) { skills.push('conception'); }
          if (vm.skill.execution) { skills.push('execution'); }
          vm.product.available_options.skill.enum = skills;
          if (vm.product.available_options.skill.enum.indexOf(vm.product.available_options.skill.default) < 0) {
            vm.product.available_options.skill.default = vm.product.available_options.skill.enum[0];
          }
        };

        vm.deleteProduct = function deleteProduct() {
          api
            .call({
              method: 'DELETE',
              url: `/products/${vm.product.gid}`,
            })
            .success(() => {
              notifications.showSuccess({
                message: $translate.instant('Le produit a bien été supprimé.'),
              });
              $state.go('products', { index: 1 });
            });
        };

        vm.fetchCategories = () => {
          const params = {
            fields: 'title',
            offset: vm.categoriesPagination.offset,
            limit: vm.categoriesPagination.limit,
          };

          if (params.offset >= vm.categoriesPagination.max) {
            return;
          }

          api.call({
            method: 'GET',
            url: '/categories',
            params,
          }).then((res) => {
            vm.categories = vm.categories.concat(res.data.items);
            vm.categoriesPagination.offset += vm.categoriesPagination.limit;
          });
        };
      },
    ])

    .controller('ConfirmProductController', [
      '$stateParams',
      function ConfirmProductController($stateParams) {
        const vm = this;
        vm.gid = $stateParams.productId;
      },
    ])

    .controller('TranslateProductsController', [
      'ROLES',
      '$translate',
      'api',
      'localizedProduct',
      'notifications',
      'product',
      'translationAvailableLocales',
      'currentUser',
      function TranslateProductsController(ROLES, $translate, api, localizedProduct, notifications, product, translationAvailableLocales, currentUser) {
        const vm = this;

        vm.translationAvailableLocales = translationAvailableLocales;
        vm.descriptionMaxLength = 2000;
        vm.editAllowed = currentUser.roles.indexOf(ROLES.PRICING_ADMIN) >= 0;
        vm.localizedProduct = localizedProduct;
        vm.localizedSynonyms = localizedProduct.synonyms;
        vm.product = product;
        vm.synonyms = product.synonyms.join(', ');

        vm.changeLocale = async () => {
          vm.localizedProduct = (await api
            .call({
              method: 'GET',
              url: `/products/${vm.localizedProduct.gid}`,
              params: {
                fields: 'synonyms,title,description,service_details,brief_template,locale',
                locale: vm.localizedProduct.locale,
              },
            })
            .then((response) => response.data)
          );
          vm.localizedSynonyms = vm.localizedProduct.synonyms;
        };

        /**
         * [submit]
         * @return {void}
         */
        vm.submit = function submit() {
          if (vm.form.$valid && vm.form.$submitted) {
            vm.localizedProduct.synonyms = [];
            angular.forEach(vm.localizedSynonyms, (element) => {
              vm.localizedProduct.synonyms.push(element.text);
            });

            const productToSend = _.pick(vm.localizedProduct,
              'brief_template',
              'description',
              'locale',
              'service_details',
              'synonyms',
              'title',
            );

            api
              .call({
                method: 'PUT',
                url: `/products/${vm.localizedProduct.gid}`,
                data: productToSend,
              })
              .success(() => {
                notifications.showSuccess({
                  message: $translate.instant('products.translate.success'),
                });
              })
              .error((data) => {
                throw new Error(`Error${data}`);
              });
          }
        };
      },
    ])
  ;
}());
